export interface FormMetaData {
  fieldKey: string;
  fieldType: string;
  section?: string;
  subsection?: string;
  title?: string;
  subText?: string;
  toolTip?: string;
  requiredMessage?: string;
  minimumMessage?: string;
  maximumMessage?: string;
  invalidMessage?: string;
  patternMessage?: string;
  minLengthMessage?: string;
  maxLengthMessage?: string;
  defaultValue?: number | string | undefined;
  placeholder?: string;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  disabled?: string;
  hide?: string;
  required?: string;
  expanded?: boolean;
  decimalPlaces?: number;
  pattern?: string;
  readonly?: string;
}

export interface MetaLabels {
  [key: string]: string | undefined;
}

export enum MetaArea {
  SolicitorInstruction = 'Omni_SolicitorInstruction',
}
