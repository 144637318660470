import { CommonModule, TitleCasePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { SimpologyComponentModule } from '@simpology/client-components';
import { BlockUiTemplateComponent } from './block-ui/block-ui-template.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ActivityTrackingComponent } from './component/activity-tracking/activity-tracking.component';
import { FileUploaderComponent } from './component/file-uploader/file-uploader.component';
import { NotFoundComponent } from './component/notfound/notfound.component';
import { UnauthorizedComponent } from './component/unauthorized/unauthorized.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { SortableHeaderDirective } from './directive/sortable-header.directive';
import { JwtInterceptor } from './interceptor/jwt.interceptor';
import { EnumDesc } from './pipes/enum-desc.pipe';
import { NumberFormat } from './pipes/number-format.pipe';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    SimpologyComponentModule,
  ],
  exports: [
    BreadcrumbComponent,
    BlockUiTemplateComponent,
    SortableHeaderDirective,
    UnauthorizedComponent,
    NotFoundComponent,
    EnumDesc,
    NumberFormat,
    ActivityTrackingComponent,
  ],
  declarations: [
    BreadcrumbComponent,
    BlockUiTemplateComponent,
    SortableHeaderDirective,
    ConfirmationDialogComponent,
    UnauthorizedComponent,
    NotFoundComponent,
    EnumDesc,
    NumberFormat,
    ActivityTrackingComponent,
    FileUploaderComponent,
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }, TitleCasePipe, NumberFormat],
})
export class SharedModule {}
