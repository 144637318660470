import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BRAND_CONFIG } from '@shared/constant';
import { Constant } from '@shared/helper/constant';
import { ApplicationModeOption } from '@shared/model/application-mode.model';
import { CurrentUser } from '@shared/model/current-user.model';
import { LoanApplicationStatus } from '@shared/model/enum.model';
import { ApplicationHelperService } from '@shared/service/application-helper.service';
import { ApplicationNotificationService } from '@shared/service/application-notification.service';
import { MetadataService } from '@shared/service/metadata.service';
import { PostLoginService } from '@shared/service/post-login.service';
import { ChannelSettingQuery } from '@shared/store/channel-setting/channel-setting.query';
import { AuthService, BrandingService } from '@simpology/authentication';
import { SimpHeaderComponent, SimpHeaderConfig } from '@simpology/client-components/public-api';
import { OmniApplicationApiService } from '@solution/api/omni-application-api.service';
import { LoanApplication } from '@solution/model/loan-application.model';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, skip, takeUntil } from 'rxjs/operators';
import { ENV_CONFIG } from 'src/env-config';

@Component({
  selector: 'app-navigation',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('labelTemplate', { static: false }) public labelTemplate: TemplateRef<HTMLElement>;
  @ViewChild('optionTemplate', { static: false }) public optionTemplate: TemplateRef<HTMLElement>;
  @ViewChild('simpHeader', { static: false }) public simpHeader: SimpHeaderComponent;

  public navigationForm: UntypedFormGroup;
  public LoanApplicationStatus = LoanApplicationStatus;
  public showChannelSelection = false;
  public headerConfig: SimpHeaderConfig<LoanApplication>;
  public currentUser: CurrentUser;

  private appStatusUpdatedSubscription: Subscription;
  private appSelectedSubscription: Subscription;
  private appModeChangeSubscription: Subscription;
  private appChannelChangeSubscription: Subscription;
  private postLoginSubscription: Subscription;

  private loanAppsInput$ = new Subject<string>();
  private initialApps: LoanApplication[] = [];
  private searchedApps: LoanApplication[] = [];
  private selectedChannelId: number;
  private currentAppModeId = this.applicationHelperService.DefaultAppModeId;
  private hideLogoutButton = false;

  private allAppsOption: LoanApplication = {
    id: Constant.selectAllOptionId,
    title: 'Select all',
  } as LoanApplication;

  private defaultChannelLogo = './../assets/images/logo/default_application_logo.png';
  private readonly defaultUserLogo = './../assets/images/portrait/small/default.png';
  private destroy$: Subject<void> = new Subject();

  private get currentUserLogo(): string {
    if (this.currentUser?.photoUrl?.length > 1) {
      return this.currentUser.photoUrl;
    }
    return this.defaultUserLogo;
  }

  private get currentChannelLogo(): string {
    return this.defaultChannelLogo;
  }

  private get loanApplications(): LoanApplication[] {
    return this.headerConfig.dropDownConfig?.items ?? [];
  }

  private set loanApplications(value: LoanApplication[]) {
    if (this.headerConfig.dropDownConfig) {
      this.headerConfig.dropDownConfig.items = value;
    }
  }

  constructor(
    private ref: ChangeDetectorRef,
    private authService: AuthService,
    private omniApplicationApiService: OmniApplicationApiService,
    private applicationHelperService: ApplicationHelperService,
    private applicationNotificationService: ApplicationNotificationService,
    private postLoginService: PostLoginService,
    private brandingService: BrandingService,
    private metadataService: MetadataService,
    private channelSettingQuery: ChannelSettingQuery
  ) {}

  public ngOnInit(): void {
    this.setupHeaderConfig();
    this.postLoginSubscription = this.postLoginService.loginCompleted$.subscribe((result) => {
      if (result) {
        this.currentUser = result;
        this.metadataService.loadInitialSetup();
        this.hideLogoutButton = this.channelSettingQuery.getSetting()?.hideLogoutButtonInOmni ?? false;
        if (this.hideLogoutButton) {
          this.headerConfig.buttons = [];
          if (this.headerConfig?.user?.profileOptions) {
            this.headerConfig.user.profileOptions = [];
          }
          this.ref.markForCheck();
        }
        if (this.headerConfig?.user) {
          this.headerConfig.user.profileImageUrl = this.currentUserLogo;
          this.headerConfig.user.name = this.currentUser.displayName;
          this.headerConfig.brandLogoUrl = this.currentChannelLogo;
          this.ref.markForCheck();
          this.setBranding();
        }
      }
    });

    this.appStatusUpdatedSubscription = this.applicationNotificationService.applicationStatusUpdated$.subscribe(
      (app) => {
        if (app.id === Constant.newId) {
          return;
        }

        const foundItem = this.loanApplications.find((x) => x.id === app.id);
        if (foundItem && foundItem.statusEnum !== app.statusEnum) {
          foundItem.statusEnum = app.statusEnum;
          this.ref.markForCheck();
        }
      }
    );

    this.appSelectedSubscription = this.applicationNotificationService.applicationSelected$.subscribe((app) => {
      if (!app || app.id < 0) {
        return;
      }
      let appId = app.id;
      if (app.applicationLinkId > 0) {
        appId = this.loanApplications.find((i) => i.applicationLinkId === app.applicationLinkId)?.id ?? 0;
      }
      this.simpHeader.selectItem(appId);
    });

    this.appModeChangeSubscription = this.applicationNotificationService.applicationModeChanged$.subscribe(
      (modeChange) => {
        this.onAppModeChange(modeChange.mode, modeChange.channelId);
      }
    );

    this.appChannelChangeSubscription = this.applicationNotificationService.channelChanged$
      .pipe(skip(1))
      .subscribe(({ channelId, initialApp }) => {
        this.onChannelChange(channelId, initialApp);
      });

    this.loanAppsInput$
      .pipe(takeUntil(this.destroy$), distinctUntilChanged(), debounceTime(500))
      .subscribe((term: string) => {
        if (term) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          if (this.headerConfig.dropDownConfig) {
            this.headerConfig.dropDownConfig.searchLoading = true;
          }
          if (this.selectedChannelId > 0) {
            this.omniApplicationApiService
              .getApplicationsForChannel(this.selectedChannelId, term)
              .subscribe((results) => {
                const combineResults = [...this.initialApps];
                results.forEach((r) => {
                  if (this.initialApps.findIndex((a) => a.id === r.id) < 0) {
                    combineResults.push(r);
                  }
                });

                this.searchedApps = combineResults;
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                if (this.headerConfig.dropDownConfig) {
                  this.headerConfig.dropDownConfig.searchLoading = false;
                }
                this.loanApplications = results;
              });
          } else {
            this.omniApplicationApiService.getUserApplicationsBySearchTerm(term).subscribe((results) => {
              const combineResults = [...this.initialApps];
              results.forEach((r) => {
                if (this.initialApps.findIndex((a) => a.id === r.id) < 0) {
                  combineResults.push(r);
                }
              });

              this.searchedApps = combineResults;
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              if (this.headerConfig.dropDownConfig) {
                this.headerConfig.dropDownConfig.searchLoading = false;
              }
              this.loanApplications = results;
            });
          }
        } else {
          this.loanApplications = this.searchedApps;
        }
      });
  }
  private setBranding() {
    this.brandingService.getBranding(ENV_CONFIG).subscribe((branding) => {
      const brandCssVars = JSON.parse(branding.cssVars);
      let brandVarsToSet: { [index: string]: string } = BRAND_CONFIG.brandVars;
      const brandHeaderVars: { [index: string]: string } = {};
      [
        '--brand-header-primary',
        '--brand-header-secondary',
        '--brand-header-primary-alt',
        '--brand-header-text-primary',
        '--brand-header-text-secondary',
        '--brand-button-header',
        '--brand-header-dropdown-arrow-background',
        '--brand-button-header-active',
        '--brand-button-header-alt',
        '--brand-button-header-alt-active',
        '--brand-button-text-header',
        '--brand-button-text-header-active',
        '--brand-button-text-header-alt',
        '--brand-button-text-header-alt-active',
        '--brand-select-header-outline-rg',
      ]
        .filter((key) => key in brandCssVars)
        .forEach((key) => (brandHeaderVars[key] = brandCssVars[key]));
      brandVarsToSet = { ...brandVarsToSet, ...brandHeaderVars };

      const root = document.documentElement;
      Object.keys(brandVarsToSet).forEach((cssVar) => {
        root.style.setProperty(cssVar, brandVarsToSet[cssVar]);
      });
      this.defaultChannelLogo = branding.brandUrl;
      this.setupHeaderConfig();
    });
  }

  public ngAfterViewInit(): void {
    this.brandingService.brandData.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data) {
        this.defaultChannelLogo = data.brandUrl;
        this.setupHeaderConfig();
      }
    });
  }
  public ngOnDestroy(): void {
    this.appStatusUpdatedSubscription.unsubscribe();
    this.appSelectedSubscription.unsubscribe();
    this.appModeChangeSubscription.unsubscribe();
    this.postLoginSubscription.unsubscribe();
    this.appChannelChangeSubscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public onAppModeChange(modeId: ApplicationModeOption, channelId?: number): void {
    if (this.currentAppModeId !== modeId) {
      this.currentAppModeId = modeId;
      this.showChannelSelection = modeId === ApplicationModeOption.ViewByChannel;
      if (this.showChannelSelection) {
        this.applicationNotificationService.notifyChannelChanged(channelId, undefined);
      } else {
        this.applicationNotificationService.notifyChannelChanged(undefined, undefined);
      }
    }
  }

  public onChannelChange(channelId?: number, initialApp?: LoanApplication): void {
    if (!channelId) {
      this.selectedChannelId = 0;

      this.getApplications(initialApp?.id).subscribe((result) => {
        this.refreshApplicationDropdown(result, initialApp);
      });
      return;
    }

    this.selectedChannelId = channelId;
    this.getApplicationsForChannel(channelId, '').subscribe((result) => {
      this.refreshApplicationDropdown(result, initialApp);
    });
  }

  private setupHeaderConfig() {
    this.headerConfig = {
      brandLogoUrl: this.currentUser ? this.currentChannelLogo : '',
      icons: [
        {
          iconClass: 'fas fa-comments-alt',
          label: 'Messages',
          selected: true,
          onClick: () => this.onClick(),
        },
      ],
      dropDownConfig: {
        items: [],
        placeholder: 'Please select an application',
        labelTemplate: this.labelTemplate,
        optionTemplate: this.optionTemplate,
        searchable: true,
        searchInput$: this.loanAppsInput$,
        bindLabel: 'title',
        bindValue: 'id',
        onBlur: () => this.onBlur(),
        onChange: (value: LoanApplication) => this.onApplicationChanged(value),
        onFocus: () => this.onFocus(),
        labelForId: 'app-select',
      },
      buttons: this.hideLogoutButton
        ? []
        : [
            { label: 'Exit', onClick: () => this.logout() },
            { icon: 'fas fa-ellipsis-h', onClick: () => this.onMoreClick() },
          ],
      backButton: { iconClass: '', onClick: () => {} },
      user: {
        name: this.currentUser?.displayName,
        profileImageUrl: this.currentUserLogo,
        profileOptions: this.hideLogoutButton
          ? []
          : [
              {
                label: 'Logout',
                onClick: () => this.logout(),
              },
            ],
      },
    };
    this.ref.markForCheck();
  }

  private getApplications(initialAppId?: number): Observable<LoanApplication[]> {
    return this.omniApplicationApiService.getApplications(initialAppId).pipe(
      map((result) => {
        this.buildExtraInfo(result);
        this.addSelectAllOption(result);
        return result;
      })
    );
  }

  private getApplicationsForChannel(channelId: number, searchTerm?: string): Observable<LoanApplication[]> {
    return this.omniApplicationApiService.getApplicationsForChannel(channelId, searchTerm).pipe(
      map((result) => {
        this.buildExtraInfo(result);
        this.addSelectAllOption(result);
        return result;
      })
    );
  }

  private buildExtraInfo(loanApps: LoanApplication[]): void {
    loanApps.forEach((app) => {
      if (!app.title.includes(app.applicationNumber)) {
        app.title = `${app.applicationNumber} - ${app.title}`;
      }
    });
  }

  private addSelectAllOption(loanApps: LoanApplication[]): void {
    loanApps.unshift(this.allAppsOption);
  }

  private refreshApplicationDropdown(apps: LoanApplication[], initialApp?: LoanApplication): void {
    this.initialApps = apps;
    this.searchedApps = apps;
    this.loanApplications = apps;
    if (initialApp) {
      this.applicationNotificationService.notifyApplicationSelected(initialApp);
    } else {
      this.applicationNotificationService.notifyChange(this.allAppsOption);
      this.applicationNotificationService.notifyApplicationSelected(this.allAppsOption);
    }

    this.ref.markForCheck();
  }

  private logout(): void {
    this.authService.signout();
  }

  public getStatusBadge(status: LoanApplicationStatus): string {
    return this.applicationHelperService.getStatusBadge(status);
  }

  private onApplicationChanged(app: LoanApplication): void {
    this.applicationNotificationService.notifyChange(app);
  }

  private onMoreClick(): void {}
  private onClick(): void {}
  private onBlur(): void {}
  private onFocus(): void {}
}
