import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FormMetaData, MetaArea } from '@shared/model/metadata.model';
import { BaseApiService } from '@simpology/authentication';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ENV_CONFIG } from 'src/env-config';
import { ReferenceData } from '../../model/reference-data.model';

@Injectable({ providedIn: 'root' })
export class MetadataApiService extends BaseApiService<ReferenceData> {
  constructor(http: HttpClient, toastr: ToastrService, router: Router) {
    super(http, toastr, router, ENV_CONFIG);
    this.setMetaRoute('Metadata');
  }

  public fetchMetaData(area: MetaArea): Observable<FormMetaData[]> {
    return this.getCustom(`Omni/${area}`);
  }
}
