import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthCallbackComponent } from './authentication/auth-callback/auth-callback.component';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  { path: 'auth-callback', component: AuthCallbackComponent },
  {
    path: '',
    loadChildren: (): any => import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
  },
  {
    path: 'solutions',
    loadChildren: (): any => import('./solutions/solutions.module').then((m) => m.SolutionsModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
