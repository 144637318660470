import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@simpology/authentication';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public submitted = false;

  constructor(private route: ActivatedRoute, private authService: AuthService) {}

  public ngOnInit(): void {
    this.authService.returnUrl = this.route.snapshot.queryParams.returnUrl || '/solutions/omni-communication';
    this.authService.login();
  }
}
