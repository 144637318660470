import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from '@shared/store/application/application.service';
import { AuthService } from '@simpology/authentication';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss'],
})
export class AuthCallbackComponent implements OnInit {
  public error: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private applicationStoreService: ApplicationService
  ) {}

  public async ngOnInit(): Promise<void> {
    // check for error
    if (this.route.snapshot.fragment && this.route.snapshot.fragment.indexOf('error') >= 0) {
      this.error = true;
      return;
    }

    this.applicationStoreService.resetActiveApp();
    await this.authService.completeAuthentication();
    this.router.navigate([this.authService.returnUrl]);
  }
}
