import { Injectable } from '@angular/core';
import { createStore, withProps } from '@ngneat/elf';

import { ChannelSetting } from './typings/channel-setting';

@Injectable({ providedIn: 'root' })
export class ChannelSettingStore {
  store = createStore(
    { name: 'channel-setting' },
    withProps<ChannelSetting>({
      enableMfa: false,
      enforceConsent: false,
      sendIdVerificationRequestOnConsent: false,
      sendValidationRequestOnDocumentUploaded: false,
      prequalificationUrl: undefined,
      loanappUrl: undefined,
      landingPageUrlId: undefined,
      allowUserCreatedFromApiToLogin: false,
      allowProductDocument: false,
      enableMultiEntityPersons: false,
      enableIPAllowList: false,
      showCondensedDashboard: false,
      serviceabilityJourneyUrl: '',
      allowViewingDeletedApplications: false,
      showBasicOmniFilterOnly: false,
      hideLogoutButtonInOmni: false,
    })
  );

  updateStore(newState: ChannelSetting) {
    this.store.update((state: ChannelSetting) => newState);
  }

  getStoreValue(): ChannelSetting {
    return this.store.getValue();
  }
}
