/* eslint-disable @typescript-eslint/naming-convention */
export const BRAND_CONFIG = {
  id: 1,
  brandName: 'Simpology',
  brandVars: {
    '--brand-primary': '#E60F53',
    '--brand-secondary': '#8B1A83',
    '--brand-text-primary': '#E60F53',
    '--brand-text-secondary': '#8B1A83',
    '--brand-text-tertiary': '#D30D4C',
    '--brand-header-primary': '#E60F53',
    '--brand-header-secondary': '#8B1A83',
    '--brand-header-primary-alt': '#E60F53',
    '--brand-header-text-primary': '#FFFFFF',
    '--brand-header-text-secondary': '#FFFFFF',
    '--brand-header-top-background': '#FFFFFF',
    '--brand-link': '#0975E0',
    '--brand-link-hover': '#4C1169',
    '--brand-button-primary': '#8B1A83',
    '--brand-button-primary-active': '#b622ab',
    '--brand-button-secondary': '#767676',
    '--brand-button-secondary-active': '#5D5D5D',
    '--brand-button-tertiary': '#F3E8F3',
    '--brand-button-tertiary-active': '#A9A1A9',
    '--brand-button-nav': '#FFFFFF',
    '--brand-button-nav-active': '#E6E6E6',
    '--brand-button-header': '#E60F53',
    '--brand-button-header-active': '#CE0D4A',
    '--brand-button-header-alt': '#8B1A83',
    '--brand-button-header-alt-active': '#b622ab',
    '--brand-button-text-primary': '#FFFFFF',
    '--brand-button-text-primary-active': '#FFFFFF',
    '--brand-button-text-secondary': '#FFFFFF',
    '--brand-button-text-secondary-active': '#FFFFFF',
    '--brand-button-text-tertiary': '#8B1A83',
    '--brand-button-text-tertiary-active': '#8B1A83',
    '--brand-button-text-nav': '#767676',
    '--brand-button-text-nav-active': '#767676',
    '--brand-button-text-header': '#FFFFFF',
    '--brand-button-text-header-active': '#FFFFFF',
    '--brand-button-text-header-alt': '#ffffff',
    '--brand-button-text-header-alt-active': '#ffffff',
    '--brand-button-text-nav-item': '#767676',
    '--brand-button-radius-sm': '16px',
    '--brand-button-radius-md': '16px',
    '--brand-button-radius-lg': '48px',
    '--brand-button-group-selected': '#F3E8F3',
    '--brand-button-group-text-selected': '#333333',
    '--brand-form-group-margin-bottom': '4px',
    '--brand-select-header-outline-rgb': '221, 221, 221',
    '--brand-bg': '#e7eaf1',
    '--brand-arrow': '#FFFFFF',
    '--brand-arrow-bg': '#8B1A83',
    '--brand-badge-selected': '#f3e8f3',
    '--brand-badge-selected-text': '#8b1a83',
    '--brand-card-background': '#f3e8f3',
    '--brand-background-outer': '#EEEEEE',
    '--brand-background-inner': '#F8F8F8',
    '--brand-footer': '#dddddd',
    '--brand-footer-text': '#767676',
    '--brand-highlighted-content': '#f3e8f3',
    '--brand-highlighted-content-text': '#8b1a83',
    '--brand-section-total-box': '#f3e8f3',
    '--brand-section-total-box-text': '#8b1a83',
    '--brand-toggle-switch-on-background': '#8b1a83',
    '--brand-nav-icon': '#767676',
    '--brand-nav-item-bg': '#767676',
    '--brand-link-decoration': 'none',
    '--brand-header-dropdown-arrow-background': 'rgba(255, 255, 255, 0.5)',
  },
};

export const DEFAULTS = {
  dateFormat: 'dd MMM y',
  timeFormat: 'h:mm a',
  dateTimeFormat: 'dd MMM y h:mm a',
};
