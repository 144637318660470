import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared/shared.module';
import { SimpAuthenticationModule } from '@simpology/authentication';
import { ToastrModule } from 'ngx-toastr';
import { ENV_CONFIG } from 'src/env-config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { LayoutComponent } from './layout/layout.component';
import { NavigationModule } from './navigation/navigation.module';

@NgModule({
  declarations: [AppComponent, LayoutComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    SharedModule,
    NavigationModule,
    AuthenticationModule,
    ToastrModule.forRoot({
      closeButton: true,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    SimpAuthenticationModule.forRoot({
      environment: ENV_CONFIG,
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
