export class NavigationMenu {
  public title: string;
  public route: string;
  public isExternalLink?: boolean;
  public icon: string;
  public access?: number[];
  public submenus?: NavigationMenu[];
}

export class MenuItem {
  public title: string;
  public icon: string;
  public page: string;
  public submenuItems: MenuItem[];
  public isSelected: boolean;
  public isExternalLink: boolean;
  public isOpen: boolean;
  public event: string;
  public section: string;
}

// Can be moved to another file
export class NavigationSetup {
  public static Navigations: NavigationMenu[] = [];
}
