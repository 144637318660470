/**
 * Filled in main.ts by config fetch
 * This is dynamically generated at runtime.
 * For local, it fetches from projects\tama-omni\src\assets\configs\env.config.json
 * For remote, it fetched from assets\configs\env.config.json (filled with jenkins based on super(http, toastr, router, ENV_CONFIG); and channel)
 *
 */
export const ENV_CONFIG: EnvConfig = {} as EnvConfig;

interface EnvConfig {
  clientId: string;
  identityUrl: string;
  apiUrl: string;
  ladmApiUrl: string;
  adminUrl: string;
  syncFusionLicenseKey: string;
  metaApiUrl: string;
  logOutRedirectUri?: string;
  dynamicIdentityUrl?: Object;
  featureFlags?: Object;
}
