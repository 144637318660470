import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { OmniApplicationApiService } from '@solution/api/omni-application-api.service';
import { ChannelSettingStore } from './channel-setting.store';
import { ChannelSetting } from './typings/channel-setting';

@Injectable({ providedIn: 'root' })
export class ChannelSettingService {
  constructor(
    private channelSettingStore: ChannelSettingStore,
    private omniApplicationApiService: OmniApplicationApiService
  ) {}

  fetchChannelSetting(): Observable<ChannelSetting> {
    return this.omniApplicationApiService.getChannelSettings().pipe(
      tap((channelSetting) => {
        this.updateChannelSetting(channelSetting);
      })
    );
  }

  private updateChannelSetting(channelSetting: ChannelSetting): void {
    this.channelSettingStore.updateStore({
      ...channelSetting,
    });
  }
}
