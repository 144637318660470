import { Injectable } from '@angular/core';
import { ChannelSettingStore } from './channel-setting.store';
import { ChannelSetting } from './typings/channel-setting';

@Injectable({ providedIn: 'root' })
export class ChannelSettingQuery {
  constructor(protected store: ChannelSettingStore) {}

  getSetting(): ChannelSetting {
    return this.store.getStoreValue();
  }
}
