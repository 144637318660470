import { EnumLabel } from './enum.model';

export class EnumDescription {
  public static getDescription<T>(enumeration: T, enumValue: any): string | undefined {
    const mapper = EnumLabel.find((e) => e.enumType === enumeration);
    if (mapper) {
      return mapper.mapping ? mapper.mapping.get(enumValue) : undefined;
    }
    return undefined;
  }
}
