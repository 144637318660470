import { Injectable } from '@angular/core';
import { OmniApplicationApiService } from '@solution/api/omni-application-api.service';
import { LoanApplicationDetail } from '@solution/model/loan-application.model';
import { Observable, map, of } from 'rxjs';
import { SessionKeyName } from '../store.constant';
import { ApplicationStore } from './application.store';
import { ApplicationStoreModel } from './typings/application';

@Injectable({ providedIn: 'root' })
export class ApplicationService {
  constructor(private applicationStore: ApplicationStore, private applicationApi: OmniApplicationApiService) {}

  get(id: number): Observable<ApplicationStoreModel> {
    const appData = this.applicationStore.getById(id);
    if (appData) {
      return of(appData);
    }

    return this.applicationApi.getApplication(id).pipe(
      map((application: LoanApplicationDetail) => {
        const newData = this.mapStoreModel(application);

        this.applicationStore.upsertStore(newData);

        return newData;
      })
    );
  }

  set(application: LoanApplicationDetail, setAsActive: boolean): ApplicationStoreModel {
    const newData = this.mapStoreModel(application);

    this.applicationStore.upsertStore(newData);

    if (setAsActive) {
      sessionStorage.setItem(SessionKeyName.currentApplicationId, application.id.toString());
    }

    return newData;
  }

  resetActiveApp(): void {
    sessionStorage.setItem(SessionKeyName.currentApplicationId, '');
  }

  getCurrentApplicationId(): number | undefined {
    const id = sessionStorage.getItem(SessionKeyName.currentApplicationId);
    return id ? +id : undefined;
  }

  private mapStoreModel(application: LoanApplicationDetail): ApplicationStoreModel {
    return {
      id: application.id,
      ladmApplicationId: application.ladmApplicationId,
      applicationKey: application.applicationKey,
      applicationNumber: application.applicationNumber,
      title: application.title,
    };
  }
}
