import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ChannelRedirectUrls } from '@shared/model/channel-redirect-urls.model';
import { CurrentUser } from '@shared/model/current-user.model';
import { ChannelReferenceData, ReferenceData } from '@shared/model/reference-data.model';
import { ChannelSetting } from '@shared/store/channel-setting/typings/channel-setting';
import { BaseApiService } from '@simpology/authentication';
import { OmniPerson } from '@solution/model/application-action-process.model';
import { ApplicationChannel } from '@solution/model/application-channel.model';
import { ApplicationProcessFlow } from '@solution/model/application-processFlow.model';
import { LadmApplication, LoanApplication, LoanApplicationDetail } from '@solution/model/loan-application.model';
import { OmniParticipant } from '@solution/model/omni-participant.model';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ENV_CONFIG } from 'src/env-config';

@Injectable({ providedIn: 'root' })
export class OmniApplicationApiService extends BaseApiService<LoanApplication> {
  constructor(http: HttpClient, toastr: ToastrService, router: Router) {
    super(http, toastr, router, ENV_CONFIG);
    this.setRoute('OmniApplication');
  }

  public getApplications(initialAppId?: number): Observable<LoanApplication[]> {
    return this.getUserApplicationsBySearchTerm('', initialAppId);
  }

  public getUserApplicationsBySearchTerm(searchTerm: string, includingAppId?: number): Observable<LoanApplication[]> {
    const appIdQueryString = includingAppId ?? 0 > 0 ? `includingApplicationId=${includingAppId}` : '';
    const searchQueryString = searchTerm ? `searchTerm=${searchTerm}` : '';
    const queryString = this.buildQueryString(appIdQueryString, searchQueryString);
    if (queryString) {
      return this.getCustom(`GetUserApplications/?${queryString}`);
    }

    return this.getAll();
  }

  public getApplicationsForChannel(channelId: number, searchTerm?: string): Observable<LoanApplication[]> {
    const queryString = searchTerm ? `searchTerm=${searchTerm}` : '';
    if (queryString) {
      return this.getCustom(`GetApplicationsForChannel/${channelId}?${queryString}`);
    }

    return this.getAllByParameter(`GetApplicationsForChannel/${channelId}`);
  }

  public getParticipants(appId: number): Observable<OmniParticipant[]> {
    return this.getCustom(`GetParticipantsForApplication/${appId}`);
  }

  public getCurrentUser(): Observable<CurrentUser> {
    return this.getCustom(`GetCurrentUser`);
  }

  public getChannelSettings(): Observable<ChannelSetting> {
    return this.getCustom(`ChannelSetting`);
  }

  public getChannelRedirectUrls(): Observable<ChannelRedirectUrls> {
    return this.getCustom(`GetChannelRedirectUrls`);
  }

  public getUserChannels(): Observable<ReferenceData[]> {
    return this.getCustom(`GetUserChannels`);
  }

  public getPersons(): Observable<OmniPerson[]> {
    return this.getCustom(`GetAllPersons`);
  }

  public getRelatedChannels(appLinkId: number): Observable<ApplicationChannel[]> {
    return this.getCustom(`GetApplicationRelatedChannels/${appLinkId}`);
  }

  public getApplication(appId: number): Observable<LoanApplicationDetail> {
    return this.getCustom(`${appId}`);
  }

  public getLadmApplication(appId: number): Observable<LadmApplication> {
    return this.getCustom(`GetLadmApplication/${appId}`);
  }

  public getApplicationForCurrentChannel(applicationLinkId: number): Observable<LoanApplicationDetail> {
    return this.getCustom(`GetApplicationForCurrentChannel/${applicationLinkId}`);
  }

  public getProcessFlow(appId: number): Observable<ApplicationProcessFlow> {
    return this.getCustom(`ProcessFlow/${appId}`);
  }

  public getRolesForCurrentChannel(): Observable<ChannelReferenceData[]> {
    return this.getCustom(`GetRolesForCurrentChannel`);
  }

  public getRolesForChannel(channelId: number): Observable<ChannelReferenceData[]> {
    return this.getCustom(`GetRolesForChannel/${channelId}`);
  }

  public getTeamsForChannel(channelId: number): Observable<ChannelReferenceData[]> {
    return this.getCustom(`GetTeamsForChannel/${channelId}`);
  }

  public getRolesForApplication(appId: number): Observable<ChannelReferenceData[]> {
    return this.getCustom(`GetRolesForApplication/${appId}`);
  }

  public getTeamsForApplication(appId: number): Observable<ChannelReferenceData[]> {
    return this.getCustom(`GetTeamsForApplication/${appId}`);
  }

  public getPersonsForApplication(appId: number): Observable<ReferenceData[]> {
    return this.getCustom(`GetPersonsForApplication/${appId}`);
  }

  public getPersonsByRole(channelId: number, roleId: number): Observable<OmniPerson[]> {
    return this.getCustom(`GetPersonsByRole/${channelId}/${roleId}`);
  }

  public getPersonsByTeam(channelId: number, teamId: number): Observable<OmniPerson[]> {
    return this.getCustom(`GetPersonsByTeam/${channelId}/${teamId}`);
  }

  public getUserPersonForChannel(channelId: number): Observable<OmniPerson> {
    return this.getCustom(`GetUserPersonForChannel/${channelId}`);
  }

  public getUserVisibilityLevelFlag(): Observable<number> {
    return this.getCustom(`GetUserVisibilityLevelFlag`);
  }

  public checkDelegatedActionItemCrossChannelExist(): Observable<boolean> {
    return this.getCustom(`CheckDelegatedActionItemCrossChannelExist`);
  }

  public reassignPerson(fromPersonId: number, toPersonId: number): Observable<void> {
    return this.postCustom('ReassignPerson', { fromPersonId, toPersonId });
  }

  public submitApplication(applicationId: number): Observable<void> {
    return this.patch(`SubmitApplication/${applicationId}`, {});
  }

  public getProcessors(appId: number): Observable<OmniParticipant[]> {
    return this.getCustom(`GetProcessorsForApplication/${appId}`);
  }

  private buildQueryString(...params: string[]) {
    return params.filter((p) => p).join('&');
  }
}
