export const isNullOrUndefined = (prop: unknown): boolean => {
  return prop === null || prop === undefined;
};

export const camelCaseToWords = (s: string) => {
  const result = s.replace(/([a-z])([A-Z])/g, '$1 $2').trim();
  return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
};

export const formatDecimals = (value: number, decimals: number): number => {
  const precision = Math.pow(10, decimals);
  return Math.trunc(value * precision) / precision;
};
