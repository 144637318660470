import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LoginComponent } from './login/login.component';
// import { SocialSigninComponent } from './social-signin/social-signin.component';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { ExpiredComponent } from './expired/expired.component';

@NgModule({
  declarations: [
    LoginComponent,
    // SocialSigninComponent,
    AuthCallbackComponent,
    ExpiredComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, AuthenticationRoutingModule, NgbModule],
})
export class AuthenticationModule {}
