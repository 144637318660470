import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AccessService } from '../shared/service/access.service';
import { MenuItem, NavigationMenu, NavigationSetup } from './navigation.model';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  public verticalMenuToggler$: Observable<boolean>;
  public horizontalMenuToggler$: Observable<boolean>;
  public resetSelectedMenuByRoute$: Observable<void>;
  private verticalMenuTogglerSource = new BehaviorSubject<boolean>(false);
  private horizontalMenuTogglerSource = new BehaviorSubject<boolean>(false);
  private resetSelectedMenuByRouteSource = new BehaviorSubject<void>(void 0);
  private isVerticalMenuOpen = false;
  private isHorizontalMenuCollapsed = false;

  constructor(private router: Router, private accessService: AccessService) {
    this.verticalMenuToggler$ = this.verticalMenuTogglerSource.asObservable();
    this.horizontalMenuToggler$ = this.horizontalMenuTogglerSource.asObservable();
    this.resetSelectedMenuByRoute$ = this.resetSelectedMenuByRouteSource.asObservable();
  }

  public toggleVerticalMenu(): void {
    this.isVerticalMenuOpen = !this.isVerticalMenuOpen;
    this.verticalMenuTogglerSource.next(this.isVerticalMenuOpen);
  }

  public toggleHorizontalMenu(): void {
    this.isHorizontalMenuCollapsed = !this.isHorizontalMenuCollapsed;
    this.horizontalMenuTogglerSource.next(this.isHorizontalMenuCollapsed);
  }

  public getMenuItems(): MenuItem[] {
    return this.convertToMenuItems(NavigationSetup.Navigations);
  }

  public toggleMenu(child: MenuItem, isSubmenuOfSubmenu: boolean, currentMenuItems: MenuItem[]): void {
    this.resetOtherActiveMenu(child, isSubmenuOfSubmenu, currentMenuItems);
    child.isOpen = !child.isOpen;
    child.isSelected = !child.isSelected;
  }

  public setSelectedMenuItemInNavbar(currentMenuItems: MenuItem[], currentUrl: string): void {
    currentMenuItems.forEach((item) => {
      this.resetMenuItemAndChild(item);
      if (!item.submenuItems && item.page === currentUrl) {
        item.isOpen = true;
        item.isSelected = true;
      } else if (item.submenuItems) {
        this.setSelectedSubmenu(item, item.submenuItems, currentUrl);
        if (item.submenuItems.some((submenu) => submenu.isOpen)) {
          item.isOpen = true;
          item.isSelected = true;
        }
      }
    });
  }

  public resetSelectedByRoute(): void {
    this.resetSelectedMenuByRouteSource.next(void 0);
  }

  private resetMenuItemAndChild(currentMenuItem: MenuItem): void {
    currentMenuItem.isOpen = false;
    currentMenuItem.isSelected = false;
    if (currentMenuItem.submenuItems) {
      currentMenuItem.submenuItems.forEach((submenu) => {
        this.resetMenuItemAndChild(submenu);
      });
    }
  }

  private setSelectedSubmenu(topParent: MenuItem, submenuItems: MenuItem[], currentUrl: string): void {
    submenuItems.forEach((submenu) => {
      if (submenu.page === currentUrl) {
        topParent.isOpen = true;
        topParent.isSelected = true;
        submenu.isOpen = true;
        submenu.isSelected = true;
      } else if (submenu.submenuItems) {
        this.setSelectedSubmenu(submenu, submenu.submenuItems, currentUrl);
      }
    });
  }

  private resetOtherActiveMenu(
    selectedChild: MenuItem,
    isSubmenuOfSubmenu: boolean,
    currentMenuItems: MenuItem[]
  ): void {
    currentMenuItems.forEach((item) => {
      item.isSelected = false;
      this.handleSubmenuItems(item, selectedChild, isSubmenuOfSubmenu);
    });
  }

  private handleSubmenuItems(parentItem: MenuItem, selectedChild: MenuItem, isSubmenuOfSubmenu: boolean): void {
    if (parentItem.submenuItems && parentItem.submenuItems.length > 0) {
      if (parentItem.title !== selectedChild.title && parentItem.isOpen && !isSubmenuOfSubmenu) {
        parentItem.isOpen = false;
      }
      parentItem.submenuItems.forEach((item) => {
        if (selectedChild.page) {
          item.isSelected = false;
        }
        this.handleSubmenuItems(item, selectedChild, isSubmenuOfSubmenu);
      });
    } else if (parentItem.title !== selectedChild.title && !selectedChild.submenuItems && parentItem.isOpen) {
      parentItem.isOpen = false;
    }
  }

  private convertToMenuItems(navigations: NavigationMenu[]): MenuItem[] {
    const menuItems: MenuItem[] = [];
    navigations
      .filter((item) => this.hasAccess(item.access || []))
      .forEach((menu) => {
        const menuItem = this.convertToMenuItem(menu);
        if (menuItem) {
          menuItems.push(menuItem);
        }
      });
    return menuItems;
  }

  private convertToMenuItem(navigation: NavigationMenu): MenuItem | null {
    const menuItem = {
      title: navigation.title,
      icon: navigation.icon,
      page: navigation.route,
      isSelected: false,
      isExternalLink: navigation.isExternalLink ? navigation.isExternalLink : false,
      isOpen: false,
      submenuItems: [] as MenuItem[],
    };
    if (navigation.submenus) {
      const accessibleSubmenus = navigation.submenus.filter((item) => this.hasAccess(item.access || []));
      if (accessibleSubmenus.length > 0) {
        const subMenus: MenuItem[] = [];
        accessibleSubmenus.forEach((sub) => {
          const subMenu = this.convertToMenuItem(sub);
          if (subMenu) {
            subMenus.push(subMenu);
          }
        });
        menuItem.submenuItems = subMenus.length > 0 ? subMenus : [];
      }
    }
    return (navigation.access && navigation.access.length > 0 && this.hasAccess(navigation.access)) ||
      menuItem.submenuItems
      ? (menuItem as MenuItem)
      : null;
  }

  private hasAccess(accessIds?: number[]): boolean {
    if (accessIds && accessIds.length > 0) {
      return this.accessService.canAccessMenu(accessIds);
    }
    return true;
  }
}
