/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
import { EnumLabelClient, EnumMapper } from './client-enum.model';

// The code blow is pasted from C:\Dev\tama-extra\Simpology.Ts\TsEnums\enum.model.ts. Do not modify anything below this line

export enum ActionItemLogEventType {
  Unknown = 0,
  TaskCreated = 1,
  TaskAssigned = 2,
  AssignedPersonChanged = 3,
  TaskSkipped = 4,
  TaskStarted = 5,
  TaskDelegated = 6,
  DocumentUploaded = 7,
  DelegatedTaskCompleted = 8,
  TaskCompleted = 9,
  TaskTransferredDueToReassignment = 10,
  DelegatedTaskCommented = 11,
  LoanInstructionDocumentUploaded = 12,
  LoanInstructionDocumentGenerated = 13,
  LoanInstructionDocumentSentToSolicitor = 14,
  LoanInstructionDocumentRevised = 15,
  ApplicationSettlementUpdated = 16,
}

export const ActionItemLogEventTypeLabel = new Map<number, string>([
  [ActionItemLogEventType.Unknown, 'Unknown'],
  [ActionItemLogEventType.TaskCreated, 'TaskCreated'],
  [ActionItemLogEventType.TaskAssigned, 'TaskAssigned'],
  [ActionItemLogEventType.AssignedPersonChanged, 'AssignedPersonChanged'],
  [ActionItemLogEventType.TaskSkipped, 'TaskSkipped'],
  [ActionItemLogEventType.TaskStarted, 'TaskStarted'],
  [ActionItemLogEventType.TaskDelegated, 'TaskDelegated'],
  [ActionItemLogEventType.DocumentUploaded, 'DocumentUploaded'],
  [ActionItemLogEventType.DelegatedTaskCompleted, 'DelegatedTaskCompleted'],
  [ActionItemLogEventType.TaskCompleted, 'TaskCompleted'],
  [ActionItemLogEventType.TaskTransferredDueToReassignment, 'TaskTransferredDueToReassignment'],
  [ActionItemLogEventType.DelegatedTaskCommented, 'DelegatedTaskCommented'],
  [ActionItemLogEventType.LoanInstructionDocumentUploaded, 'LoanInstructionDocumentUploaded'],
  [ActionItemLogEventType.LoanInstructionDocumentGenerated, 'LoanInstructionDocumentGenerated'],
  [ActionItemLogEventType.LoanInstructionDocumentSentToSolicitor, 'LoanInstructionDocumentSentToSolicitor'],
  [ActionItemLogEventType.LoanInstructionDocumentRevised, 'LoanInstructionDocumentRevised'],
  [ActionItemLogEventType.ApplicationSettlementUpdated, 'ApplicationSettlementUpdated'],
]);

export enum ActionItemTemplateType {
  Task = 1,
  Notification = 2,
}

export const ActionItemTemplateTypeLabel = new Map<number, string>([
  [ActionItemTemplateType.Task, 'Task'],
  [ActionItemTemplateType.Notification, 'Notification'],
]);

export enum ActionItemTemplateTargetPersonSelection {
  Coordinator = 1,
  PrimaryApplicant = 2,
  CoApplicant = 3,
  AllApplicants = 4,
  Team = 5,
  Role = 6,
  ApplicationBroker = 7,
}

export const ActionItemTemplateTargetPersonSelectionLabel = new Map<number, string>([
  [ActionItemTemplateTargetPersonSelection.Coordinator, 'Co-ordinator'],
  [ActionItemTemplateTargetPersonSelection.PrimaryApplicant, 'Primary applicant'],
  [ActionItemTemplateTargetPersonSelection.CoApplicant, 'Co-applicant'],
  [ActionItemTemplateTargetPersonSelection.AllApplicants, 'All applicants'],
  [ActionItemTemplateTargetPersonSelection.Team, 'Team'],
  [ActionItemTemplateTargetPersonSelection.Role, 'Role'],
  [ActionItemTemplateTargetPersonSelection.ApplicationBroker, 'Application broker'],
]);

export enum ActionItemTemplatePriority {
  Low = 1,
  Medium = 2,
  High = 3,
  Urgent = 4,
}

export const ActionItemTemplatePriorityLabel = new Map<number, string>([
  [ActionItemTemplatePriority.Low, 'Low'],
  [ActionItemTemplatePriority.Medium, 'Medium'],
  [ActionItemTemplatePriority.High, 'High'],
  [ActionItemTemplatePriority.Urgent, 'Urgent'],
]);

export enum ActionItemTemplateActionPage {
  InfoRequest = 1,
  DecisionSupport = 2,
  SupportingDocs = 3,
  Application = 4,
}

export const ActionItemTemplateActionPageLabel = new Map<number, string>([
  [ActionItemTemplateActionPage.InfoRequest, 'Info Request'],
  [ActionItemTemplateActionPage.DecisionSupport, 'Document Verfication'],
  [ActionItemTemplateActionPage.SupportingDocs, 'Supporting Docs'],
  [ActionItemTemplateActionPage.Application, 'Application'],
]);

export enum ActionItemTemplateRuleOption {
  NoRule = 1,
  Activates = 2,
  Blocks = 3,
}

export const ActionItemTemplateRuleOptionLabel = new Map<number, string>([
  [ActionItemTemplateRuleOption.NoRule, 'No Rule'],
  [ActionItemTemplateRuleOption.Activates, 'Activates'],
  [ActionItemTemplateRuleOption.Blocks, 'Blocks'],
]);

export enum ActionProcessTemplatePassRequirement {
  AllPass = 1,
  AnyPass = 2,
  AllMandatoryPass = 3,
}

export const ActionProcessTemplatePassRequirementLabel = new Map<number, string>([
  [ActionProcessTemplatePassRequirement.AllPass, 'All pass'],
  [ActionProcessTemplatePassRequirement.AnyPass, 'Any pass'],
  [ActionProcessTemplatePassRequirement.AllMandatoryPass, 'All mandatory pass'],
]);

export enum ActionProcessTemplateFailRequirement {
  AnyFail = 1,
  AnyMandatoryFail = 2,
}

export const ActionProcessTemplateFailRequirementLabel = new Map<number, string>([
  [ActionProcessTemplateFailRequirement.AnyFail, 'Any Fail'],
  [ActionProcessTemplateFailRequirement.AnyMandatoryFail, 'All mandatory fail'],
]);

export enum ActionProcessTemplateRuleOption {
  NoRule = 1,
  Activates = 2,
  Blocks = 3,
}

export const ActionProcessTemplateRuleOptionLabel = new Map<number, string>([
  [ActionProcessTemplateRuleOption.NoRule, 'No Rule'],
  [ActionProcessTemplateRuleOption.Activates, 'Activates'],
  [ActionProcessTemplateRuleOption.Blocks, 'Blocks'],
]);

export enum ApplicationActionItemStatus {
  Open = 2,
  Inprogress = 3,
  Complete = 4,
  Fail = 5,
  Skipped = 6,
}

export const ApplicationActionItemStatusLabel = new Map<number, string>([
  [ApplicationActionItemStatus.Open, 'Open'],
  [ApplicationActionItemStatus.Inprogress, 'In progress'],
  [ApplicationActionItemStatus.Complete, 'Complete'],
  [ApplicationActionItemStatus.Fail, 'Fail'],
  [ApplicationActionItemStatus.Skipped, 'Skipped'],
]);

export enum ApplicationActionItemTargetPersonOption {
  Person = 1,
  Role = 2,
  Team = 3,
}

export const ApplicationActionItemTargetPersonOptionLabel = new Map<number, string>([
  [ApplicationActionItemTargetPersonOption.Person, 'Person'],
  [ApplicationActionItemTargetPersonOption.Role, 'Role'],
  [ApplicationActionItemTargetPersonOption.Team, 'Team'],
]);

export enum ApplicationActionItemAttachmentStatus {
  Active = 1,
}

export const ApplicationActionItemAttachmentStatusLabel = new Map<number, string>([
  [ApplicationActionItemAttachmentStatus.Active, 'Active'],
]);

export enum ApplicationActionItemChecklistStatus {
  Open = 1,
  Complete = 2,
}

export const ApplicationActionItemChecklistStatusLabel = new Map<number, string>([
  [ApplicationActionItemChecklistStatus.Open, 'Open'],
  [ApplicationActionItemChecklistStatus.Complete, 'Complete'],
]);

export enum ApplicationActionProcessStatus {
  Open = 2,
  Inprogress = 3,
  Complete = 4,
  Fail = 5,
}

export const ApplicationActionProcessStatusLabel = new Map<number, string>([
  [ApplicationActionProcessStatus.Open, 'Open'],
  [ApplicationActionProcessStatus.Inprogress, 'In progress'],
  [ApplicationActionProcessStatus.Complete, 'Complete'],
  [ApplicationActionProcessStatus.Fail, 'Fail'],
]);

export enum ApplicationActivityStatusStatus {
  Active = 1,
}

export const ApplicationActivityStatusStatusLabel = new Map<number, string>([
  [ApplicationActivityStatusStatus.Active, 'Active'],
]);

export enum ApplicationAllocationPropertyType {
  NeedFullReview = 1,
  IsSmsfApplication = 2,
  HasForeignIncome = 3,
  HasSelfEmployedIncome = 4,
  HasTrustIncome = 5,
  HasCompanyIncome = 6,
  LVR = 7,
  AutoAllocated = 8,
  IsLoDoc = 9,
}

export const ApplicationAllocationPropertyTypeLabel = new Map<number, string>([
  [ApplicationAllocationPropertyType.NeedFullReview, 'NeedFullReview'],
  [ApplicationAllocationPropertyType.IsSmsfApplication, 'IsSmsfApplication'],
  [ApplicationAllocationPropertyType.HasForeignIncome, 'HasForeignIncome'],
  [ApplicationAllocationPropertyType.HasSelfEmployedIncome, 'HasSelfEmployedIncome'],
  [ApplicationAllocationPropertyType.HasTrustIncome, 'HasTrustIncome'],
  [ApplicationAllocationPropertyType.HasCompanyIncome, 'HasCompanyIncome'],
  [ApplicationAllocationPropertyType.LVR, 'LVR'],
  [ApplicationAllocationPropertyType.AutoAllocated, 'AutoAllocated'],
  [ApplicationAllocationPropertyType.IsLoDoc, 'IsLoDoc'],
]);

export enum ApplicationApplicantStatus {
  Active = 1,
}

export const ApplicationApplicantStatusLabel = new Map<number, string>([[ApplicationApplicantStatus.Active, 'Active']]);

export enum ApplicationApplicantType {
  PrimaryApplicant = 1,
  CoApplicant = 2,
}

export const ApplicationApplicantTypeLabel = new Map<number, string>([
  [ApplicationApplicantType.PrimaryApplicant, 'Primary applicant'],
  [ApplicationApplicantType.CoApplicant, 'Co-applicant'],
]);

export enum ApplicationChecklistCategoryStatus {
  Active = 1,
}

export const ApplicationChecklistCategoryStatusLabel = new Map<number, string>([
  [ApplicationChecklistCategoryStatus.Active, 'Active'],
]);

export enum ApplicationChecklistItemStatus {
  Active = 1,
}

export const ApplicationChecklistItemStatusLabel = new Map<number, string>([
  [ApplicationChecklistItemStatus.Active, 'Active'],
]);

export enum ApplicationChecklistItemVerificationStatus {
  Pass = 1,
  Fail = 2,
  Reject = 3,
  AllowException = 4,
}

export const ApplicationChecklistItemVerificationStatusLabel = new Map<number, string>([
  [ApplicationChecklistItemVerificationStatus.Pass, 'Pass'],
  [ApplicationChecklistItemVerificationStatus.Fail, 'Fail'],
  [ApplicationChecklistItemVerificationStatus.Reject, 'Reject'],
  [ApplicationChecklistItemVerificationStatus.AllowException, 'Allow exception'],
]);

export enum ApplicationChecklistItemVerificationTaskStatus {
  Unchecked = 1,
  Checked = 2,
}

export const ApplicationChecklistItemVerificationTaskStatusLabel = new Map<number, string>([
  [ApplicationChecklistItemVerificationTaskStatus.Unchecked, 'Unchecked'],
  [ApplicationChecklistItemVerificationTaskStatus.Checked, 'Checked'],
]);

export enum ApplicationDocumentStatus {
  Active = 1,
  Categorized = 2,
  Archived = 3,
  Rejected = 4,
}

export const ApplicationDocumentStatusLabel = new Map<number, string>([
  [ApplicationDocumentStatus.Active, 'Active'],
  [ApplicationDocumentStatus.Categorized, 'Categorized'],
  [ApplicationDocumentStatus.Archived, 'Archived'],
  [ApplicationDocumentStatus.Rejected, 'Rejected'],
]);

export enum ApplicationInfoRequestStatus {
  New = 1,
  Open = 2,
}

export const ApplicationInfoRequestStatusLabel = new Map<number, string>([
  [ApplicationInfoRequestStatus.New, 'New'],
  [ApplicationInfoRequestStatus.Open, 'Open'],
]);

export enum ApplicationNoteStatus {
  Active = 1,
}

export const ApplicationNoteStatusLabel = new Map<number, string>([[ApplicationNoteStatus.Active, 'Active']]);

export enum ApplicationNoteType {
  Compliance = 1,
}

export const ApplicationNoteTypeLabel = new Map<number, string>([[ApplicationNoteType.Compliance, 'Compliance']]);

export enum ApplicationParticipantStatus {
  Active = 1,
  Inactive = 2,
}

export const ApplicationParticipantStatusLabel = new Map<number, string>([
  [ApplicationParticipantStatus.Active, 'Active'],
  [ApplicationParticipantStatus.Inactive, 'Inactive'],
]);

export enum ApplicationParticipantType {
  Management = 1,
  Applicant = 2,
}

export const ApplicationParticipantTypeLabel = new Map<number, string>([
  [ApplicationParticipantType.Management, 'Management'],
  [ApplicationParticipantType.Applicant, 'Applicant'],
]);

export enum ApplicationRoleStatus {
  Active = 1,
}

export const ApplicationRoleStatusLabel = new Map<number, string>([[ApplicationRoleStatus.Active, 'Active']]);

export enum ApplicationSummarySettlementStatus {
  Target = 1,
  Booked = 2,
}

export const ApplicationSummarySettlementStatusLabel = new Map<number, string>([
  [ApplicationSummarySettlementStatus.Target, 'Target'],
  [ApplicationSummarySettlementStatus.Booked, 'Booked'],
]);

export enum ApplicationTemplateStatus {
  Active = 1,
}

export const ApplicationTemplateStatusLabel = new Map<number, string>([[ApplicationTemplateStatus.Active, 'Active']]);

export enum ApplicationVerificationPrimaryDataStatus {
  Active = 1,
}

export const ApplicationVerificationPrimaryDataStatusLabel = new Map<number, string>([
  [ApplicationVerificationPrimaryDataStatus.Active, 'Active'],
]);

export enum ApplicationVerificationPrimaryDataSourceType {
  DefaultOption = 0,
  ChecklistTemplate = 1,
  UploadDoc = 2,
  LoanApp = 3,
}

export const ApplicationVerificationPrimaryDataSourceTypeLabel = new Map<number, string>([
  [ApplicationVerificationPrimaryDataSourceType.DefaultOption, 'UseDefaultOption'],
  [ApplicationVerificationPrimaryDataSourceType.ChecklistTemplate, 'CHNL checklist template'],
  [ApplicationVerificationPrimaryDataSourceType.UploadDoc, 'Upload form (PDF)'],
  [ApplicationVerificationPrimaryDataSourceType.LoanApp, 'LoanApp data'],
]);

export enum AssessAuthorisationLevelStatus {
  Active = 1,
  Inactive = 2,
}

export const AssessAuthorisationLevelStatusLabel = new Map<number, string>([
  [AssessAuthorisationLevelStatus.Active, 'Active'],
  [AssessAuthorisationLevelStatus.Inactive, 'Inactive'],
]);

export enum AssessmentStatus {
  Open = 2,
  Complete = 4,
  Fail = 5,
  Waiting = 8,
}

export const AssessmentStatusLabel = new Map<number, string>([
  [AssessmentStatus.Open, 'Open'],
  [AssessmentStatus.Complete, 'Complete'],
  [AssessmentStatus.Fail, 'Fail'],
  [AssessmentStatus.Waiting, 'Waiting'],
]);

export enum AssessmentType {
  Process = 7,
  Area = 8,
  ConditionOfApproval = 9,
  Summary = 10,
  Item = 11,
}

export const AssessmentTypeLabel = new Map<number, string>([
  [AssessmentType.Process, 'Process'],
  [AssessmentType.Area, 'Area'],
  [AssessmentType.ConditionOfApproval, 'Condition Of Approval'],
  [AssessmentType.Summary, 'Summary'],
  [AssessmentType.Item, 'Item'],
]);

export enum AssessmentConditionOfApprovalDecision {
  ConditionMet = 1,
  ConditionUnmet = 2,
}

export const AssessmentConditionOfApprovalDecisionLabel = new Map<number, string>([
  [AssessmentConditionOfApprovalDecision.ConditionMet, 'Condition has been met'],
  [AssessmentConditionOfApprovalDecision.ConditionUnmet, 'Condition unmet'],
]);

export enum AssessmentDocumentStatus {
  Active = 1,
}

export const AssessmentDocumentStatusLabel = new Map<number, string>([[AssessmentDocumentStatus.Active, 'Active']]);

export enum AssessmentItemRuleRuleResult {
  Accept = 1,
  Decline = 2,
  Refer = 3,
  Override = 4,
}

export const AssessmentItemRuleRuleResultLabel = new Map<number, string>([
  [AssessmentItemRuleRuleResult.Accept, 'Accept'],
  [AssessmentItemRuleRuleResult.Decline, 'Decline'],
  [AssessmentItemRuleRuleResult.Refer, 'Refer'],
  [AssessmentItemRuleRuleResult.Override, 'Override'],
]);

export enum AssessmentItemRuleDecision {
  Accept = 1,
  Decline = 2,
  Refer = 3,
}

export const AssessmentItemRuleDecisionLabel = new Map<number, string>([
  [AssessmentItemRuleDecision.Accept, 'Accept'],
  [AssessmentItemRuleDecision.Decline, 'Decline'],
  [AssessmentItemRuleDecision.Refer, 'Refer'],
]);

export enum AssessmentSummaryNoteStatus {
  Active = 1,
}

export const AssessmentSummaryNoteStatusLabel = new Map<number, string>([
  [AssessmentSummaryNoteStatus.Active, 'Active'],
]);

export enum AuditLogCategory {
  Login = 1,
  Security = 2,
  Channel = 3,
  Entity = 4,
  People = 5,
  Product = 6,
  Application = 7,
}

export const AuditLogCategoryLabel = new Map<number, string>([
  [AuditLogCategory.Login, 'Login'],
  [AuditLogCategory.Security, 'Security'],
  [AuditLogCategory.Channel, 'Channel'],
  [AuditLogCategory.Entity, 'Entity'],
  [AuditLogCategory.People, 'People'],
  [AuditLogCategory.Product, 'Product'],
  [AuditLogCategory.Application, 'Application'],
]);

export enum AuthorisationLevelStatus {
  Active = 1,
  Inactive = 2,
}

export const AuthorisationLevelStatusLabel = new Map<number, string>([
  [AuthorisationLevelStatus.Active, 'Active'],
  [AuthorisationLevelStatus.Inactive, 'Inactive'],
]);

export enum BackChannelMessageMessageType {
  Status = 1,
  Event = 2,
}

export const BackChannelMessageMessageTypeLabel = new Map<number, string>([
  [BackChannelMessageMessageType.Status, 'Status'],
  [BackChannelMessageMessageType.Event, 'Event'],
]);

export enum BrokerStatus {
  Current = 1,
  Suspended = 2,
  UnderReview = 3,
}

export const BrokerStatusLabel = new Map<number, string>([
  [BrokerStatus.Current, 'Current'],
  [BrokerStatus.Suspended, 'Suspended'],
  [BrokerStatus.UnderReview, 'Under review'],
]);

export enum BrokerBrokerRanking {
  None = 0,
  Bronze = 1,
  Silver = 2,
  Gold = 3,
  Platinum = 4,
}

export const BrokerBrokerRankingLabel = new Map<number, string>([
  [BrokerBrokerRanking.None, 'None'],
  [BrokerBrokerRanking.Bronze, 'Bronze'],
  [BrokerBrokerRanking.Silver, 'Silver'],
  [BrokerBrokerRanking.Gold, 'Gold'],
  [BrokerBrokerRanking.Platinum, 'Platinum'],
]);

export enum BrokerAppointmentStatus {
  Active = 1,
  Inactive = 2,
}

export const BrokerAppointmentStatusLabel = new Map<number, string>([
  [BrokerAppointmentStatus.Active, 'Active'],
  [BrokerAppointmentStatus.Inactive, 'Inactive'],
]);

export enum BrokerAwardStatus {
  Active = 1,
  Inactive = 2,
}

export const BrokerAwardStatusLabel = new Map<number, string>([
  [BrokerAwardStatus.Active, 'Active'],
  [BrokerAwardStatus.Inactive, 'Inactive'],
]);

export enum BrokerEventStatus {
  Active = 1,
  Inactive = 2,
}

export const BrokerEventStatusLabel = new Map<number, string>([
  [BrokerEventStatus.Active, 'Active'],
  [BrokerEventStatus.Inactive, 'Inactive'],
]);

export enum BrokerEventType {
  BrokerStatusApproval = 1,
  Recognition = 2,
  Promotion = 3,
  DisciplinaryAction = 4,
  Suspension = 5,
  Reinstatement = 6,
  Reprimand = 7,
  BrokerStatusUpdate = 8,
  FraudAlert = 9,
  SetBronzeRanking = 10,
  SetSilverRanking = 11,
  SetGoldRanking = 12,
  SetPlatinumRanking = 13,
}

export const BrokerEventTypeLabel = new Map<number, string>([
  [BrokerEventType.BrokerStatusApproval, 'Broker status approval'],
  [BrokerEventType.Recognition, 'Recognition'],
  [BrokerEventType.Promotion, 'Promotion'],
  [BrokerEventType.DisciplinaryAction, 'Disciplinary action'],
  [BrokerEventType.Suspension, 'Suspension'],
  [BrokerEventType.Reinstatement, 'Reinstatement'],
  [BrokerEventType.Reprimand, 'Reprimand'],
  [BrokerEventType.BrokerStatusUpdate, 'Broker status update'],
  [BrokerEventType.FraudAlert, 'Fraud alert'],
  [BrokerEventType.SetBronzeRanking, 'Set broker status to Bronze'],
  [BrokerEventType.SetSilverRanking, 'Set broker status to Silver'],
  [BrokerEventType.SetGoldRanking, 'Set broker status to Gold'],
  [BrokerEventType.SetPlatinumRanking, 'Set broker status to Platinum'],
]);

export enum BrokerInsuranceStatus {
  Active = 1,
  Inactive = 2,
}

export const BrokerInsuranceStatusLabel = new Map<number, string>([
  [BrokerInsuranceStatus.Active, 'Active'],
  [BrokerInsuranceStatus.Inactive, 'Inactive'],
]);

export enum BrokerInsuranceType {
  ProfessionalIndemnity = 1,
  PublicLiability = 2,
  ManagementLiability = 3,
  CyberLiability = 4,
}

export const BrokerInsuranceTypeLabel = new Map<number, string>([
  [BrokerInsuranceType.ProfessionalIndemnity, 'Professional indemnity'],
  [BrokerInsuranceType.PublicLiability, 'Public liability'],
  [BrokerInsuranceType.ManagementLiability, 'Management liability'],
  [BrokerInsuranceType.CyberLiability, 'Cyber liability'],
]);

export enum BrokerInsuranceProvider {
  AIG = 1,
  BizCover = 2,
  Vero = 3,
  Dual = 4,
  Berkly = 5,
  QBE = 6,
  RelyOn = 7,
  Point = 8,
}

export const BrokerInsuranceProviderLabel = new Map<number, string>([
  [BrokerInsuranceProvider.AIG, 'AIG'],
  [BrokerInsuranceProvider.BizCover, 'Biz cover'],
  [BrokerInsuranceProvider.Vero, 'Vero'],
  [BrokerInsuranceProvider.Dual, 'Dual'],
  [BrokerInsuranceProvider.Berkly, 'Berkly'],
  [BrokerInsuranceProvider.QBE, 'QBE'],
  [BrokerInsuranceProvider.RelyOn, 'RelyOn'],
  [BrokerInsuranceProvider.Point, 'Point'],
]);

export enum BrokerMembershipStatus {
  Active = 1,
  Inactive = 2,
}

export const BrokerMembershipStatusLabel = new Map<number, string>([
  [BrokerMembershipStatus.Active, 'Active'],
  [BrokerMembershipStatus.Inactive, 'Inactive'],
]);

export enum CalculatorStatus {
  Active = 1,
  Inactive = 2,
}

export const CalculatorStatusLabel = new Map<number, string>([
  [CalculatorStatus.Active, 'Active'],
  [CalculatorStatus.Inactive, 'Inactive'],
]);

export enum CalculatorCalculatorType {
  ComparisonRate = 1,
  LMI = 2,
}

export const CalculatorCalculatorTypeLabel = new Map<number, string>([
  [CalculatorCalculatorType.ComparisonRate, 'Comparison rate'],
  [CalculatorCalculatorType.LMI, 'LMI/LDP'],
]);

export enum CalculatorConfigurationConfigurationOption {
  UseBuiltIn = 1,
  CustomRule = 2,
}

export const CalculatorConfigurationConfigurationOptionLabel = new Map<number, string>([
  [CalculatorConfigurationConfigurationOption.UseBuiltIn, 'Use built in calculation'],
  [CalculatorConfigurationConfigurationOption.CustomRule, 'Custom rule'],
]);

export enum CalculatorVersionStatus {
  Draft = 1,
  Current = 2,
  Obsolete = 3,
}

export const CalculatorVersionStatusLabel = new Map<number, string>([
  [CalculatorVersionStatus.Draft, 'Draft'],
  [CalculatorVersionStatus.Current, 'Current'],
  [CalculatorVersionStatus.Obsolete, 'Obsolete'],
]);

export enum ChannelStatus {
  Active = 1,
  Inactive = 2,
  PreRegistered = 3,
}

export const ChannelStatusLabel = new Map<number, string>([
  [ChannelStatus.Active, 'Active'],
  [ChannelStatus.Inactive, 'Inactive'],
  [ChannelStatus.PreRegistered, 'PreRegistered'],
]);

export enum ChannelCompanyNumberType {
  ABN = 1,
  NZBN = 2,
  EIN = 3,
  USCC = 4,
}

export const ChannelCompanyNumberTypeLabel = new Map<number, string>([
  [ChannelCompanyNumberType.ABN, 'ABN'],
  [ChannelCompanyNumberType.NZBN, 'NZBN'],
  [ChannelCompanyNumberType.EIN, 'EIN'],
  [ChannelCompanyNumberType.USCC, 'USCC'],
]);

export enum ChannelBrandImageBrandType {
  Security = 1,
  Application = 2,
  EmailHeader = 3,
  EmailFooter = 4,
  InfoRequest = 5,
}

export const ChannelBrandImageBrandTypeLabel = new Map<number, string>([
  [ChannelBrandImageBrandType.Security, 'Security functions'],
  [ChannelBrandImageBrandType.Application, 'Application page'],
  [ChannelBrandImageBrandType.EmailHeader, 'Email header'],
  [ChannelBrandImageBrandType.EmailFooter, 'Email footer'],
  [ChannelBrandImageBrandType.InfoRequest, 'Info request'],
]);

export enum ChannelDataStatus {
  Active = 1,
  Inactive = 2,
}

export const ChannelDataStatusLabel = new Map<number, string>([
  [ChannelDataStatus.Active, 'Active'],
  [ChannelDataStatus.Inactive, 'Inactive'],
]);

export enum ChannelHierarchyStatus {
  Active = 1,
  Inactive = 2,
}

export const ChannelHierarchyStatusLabel = new Map<number, string>([
  [ChannelHierarchyStatus.Active, 'Active'],
  [ChannelHierarchyStatus.Inactive, 'Inactive'],
]);

export enum ChannelHierarchyWithEntityEntityType {
  Lender = 1,
  Aggregator = 2,
  Brokerage = 3,
  LoanFunder = 4,
  LoanOriginator = 5,
}

export const ChannelHierarchyWithEntityEntityTypeLabel = new Map<number, string>([
  [ChannelHierarchyWithEntityEntityType.Lender, 'Lender'],
  [ChannelHierarchyWithEntityEntityType.Aggregator, 'Aggregator'],
  [ChannelHierarchyWithEntityEntityType.Brokerage, 'Brokerage'],
  [ChannelHierarchyWithEntityEntityType.LoanFunder, 'Loan funder'],
  [ChannelHierarchyWithEntityEntityType.LoanOriginator, 'Loan originator'],
]);

export enum ChannelHierarchyWithEntityStatus {
  Active = 1,
  Inactive = 2,
}

export const ChannelHierarchyWithEntityStatusLabel = new Map<number, string>([
  [ChannelHierarchyWithEntityStatus.Active, 'Active'],
  [ChannelHierarchyWithEntityStatus.Inactive, 'Inactive'],
]);

export enum ChannelSolicitorStatus {
  Active = 1,
}

export const ChannelSolicitorStatusLabel = new Map<number, string>([[ChannelSolicitorStatus.Active, 'Active']]);

export enum ChannelSubscriptionStatus {
  Active = 1,
  Inactive = 2,
}

export const ChannelSubscriptionStatusLabel = new Map<number, string>([
  [ChannelSubscriptionStatus.Active, 'Active'],
  [ChannelSubscriptionStatus.Inactive, 'Inactive'],
]);

export enum ChannelSubscriptionWithTemplateType {
  Subscription = 1,
  Usage = 2,
}

export const ChannelSubscriptionWithTemplateTypeLabel = new Map<number, string>([
  [ChannelSubscriptionWithTemplateType.Subscription, 'Subscription'],
  [ChannelSubscriptionWithTemplateType.Usage, 'Usage'],
]);

export enum ChannelSubscriptionWithTemplateStatus {
  Active = 1,
  Inactive = 2,
}

export const ChannelSubscriptionWithTemplateStatusLabel = new Map<number, string>([
  [ChannelSubscriptionWithTemplateStatus.Active, 'Active'],
  [ChannelSubscriptionWithTemplateStatus.Inactive, 'Inactive'],
]);

export enum ChecklistItemVerificationPrimaryDataStatus {
  Active = 1,
}

export const ChecklistItemVerificationPrimaryDataStatusLabel = new Map<number, string>([
  [ChecklistItemVerificationPrimaryDataStatus.Active, 'Active'],
]);

export enum ComparisonRateCalculatorStatus {
  Active = 1,
  Inactive = 2,
}

export const ComparisonRateCalculatorStatusLabel = new Map<number, string>([
  [ComparisonRateCalculatorStatus.Active, 'Active'],
  [ComparisonRateCalculatorStatus.Inactive, 'Inactive'],
]);

export enum ConsentPageTemplateType {
  InfoRequestConsent = 1,
}

export const ConsentPageTemplateTypeLabel = new Map<number, string>([
  [ConsentPageTemplateType.InfoRequestConsent, 'InfoRequestConsent'],
]);

export enum ConsentPageTemplateSecurityCodeType {
  Email = 1,
  SMS = 2,
}

export const ConsentPageTemplateSecurityCodeTypeLabel = new Map<number, string>([
  [ConsentPageTemplateSecurityCodeType.Email, 'Email'],
  [ConsentPageTemplateSecurityCodeType.SMS, 'SMS'],
]);

export enum DataCollectionStatus {
  Active = 1,
  Inactive = 2,
}

export const DataCollectionStatusLabel = new Map<number, string>([
  [DataCollectionStatus.Active, 'Active'],
  [DataCollectionStatus.Inactive, 'Inactive'],
]);

export enum DataSubscriptionStatus {
  Pending = 1,
  Approved = 2,
  Rejected = 3,
  Cancelled = 4,
  Withdrawn = 5,
}

export const DataSubscriptionStatusLabel = new Map<number, string>([
  [DataSubscriptionStatus.Pending, 'Pending'],
  [DataSubscriptionStatus.Approved, 'Approved'],
  [DataSubscriptionStatus.Rejected, 'Rejected'],
  [DataSubscriptionStatus.Cancelled, 'Cancelled'],
  [DataSubscriptionStatus.Withdrawn, 'Withdrawn'],
]);

export enum DigitalServiceDataFileDataType {
  Transformed = 1,
  Result = 2,
  Report = 3,
  BankStatement = 4,
}

export const DigitalServiceDataFileDataTypeLabel = new Map<number, string>([
  [DigitalServiceDataFileDataType.Transformed, 'Transformed'],
  [DigitalServiceDataFileDataType.Result, 'Result'],
  [DigitalServiceDataFileDataType.Report, 'Report'],
  [DigitalServiceDataFileDataType.BankStatement, 'BankStatement'],
]);

export enum DiscountPackageStatus {
  Draft = 1,
  Current = 2,
  Obsolete = 3,
}

export const DiscountPackageStatusLabel = new Map<number, string>([
  [DiscountPackageStatus.Draft, 'Draft'],
  [DiscountPackageStatus.Current, 'Current'],
  [DiscountPackageStatus.Obsolete, 'Obsolete'],
]);

export enum DiscountPackageBenefitStatus {
  Active = 1,
  Inactive = 2,
}

export const DiscountPackageBenefitStatusLabel = new Map<number, string>([
  [DiscountPackageBenefitStatus.Active, 'Active'],
  [DiscountPackageBenefitStatus.Inactive, 'Inactive'],
]);

export enum DiscountPackageFeeStatus {
  Active = 1,
  Inactive = 2,
}

export const DiscountPackageFeeStatusLabel = new Map<number, string>([
  [DiscountPackageFeeStatus.Active, 'Active'],
  [DiscountPackageFeeStatus.Inactive, 'Inactive'],
]);

export enum DiscountPackageRateStatus {
  Active = 1,
  Inactive = 2,
}

export const DiscountPackageRateStatusLabel = new Map<number, string>([
  [DiscountPackageRateStatus.Active, 'Active'],
  [DiscountPackageRateStatus.Inactive, 'Inactive'],
]);

export enum DocumentStatus {
  Active = 1,
  Obsolete = 2,
}

export const DocumentStatusLabel = new Map<number, string>([
  [DocumentStatus.Active, 'Active'],
  [DocumentStatus.Obsolete, 'Obsolete'],
]);

export enum DocumentScope {
  Lender = 1,
  Product = 2,
  ProductVersion = 3,
}

export const DocumentScopeLabel = new Map<number, string>([
  [DocumentScope.Lender, 'Lender'],
  [DocumentScope.Product, 'Product'],
  [DocumentScope.ProductVersion, 'Version'],
]);

export enum DocumentType {
  Static = 1,
  Editable = 2,
  DataMerge = 3,
}

export const DocumentTypeLabel = new Map<number, string>([
  [DocumentType.Static, 'Static'],
  [DocumentType.Editable, 'Editable'],
  [DocumentType.DataMerge, 'Data merge'],
]);

export enum DocumentStandardUse {
  TermsAndConditions = 1,
  ProductDisclosureStatement = 2,
  FinancialServicesGuide = 3,
  ConsumerLendingTermsAndConditions = 4,
  LoanMortgageInsurance = 5,
  DiscountPackages = 6,
  ProductDetail = 7,
  SpecialOffers = 8,
  RateCard = 9,
  MarketingBrochure = 10,
  MarketingFlyer = 11,
  PersonalLoans = 12,
  BusinessLoans = 13,
  InvestmentLoans = 14,
  Banking = 15,
  BankingTermsAndConditions = 16,
  Policy = 17,
  CreditPolicy = 18,
  Insurance = 19,
  InsuranceTermsAndConditions = 20,
  HomeLoanFeesAndCharges = 25,
  TargetMarketDetermination = 26,
  OtherLending = 21,
  OtherBanking = 22,
  OtherInsurance = 23,
  Other = 24,
}

export const DocumentStandardUseLabel = new Map<number, string>([
  [DocumentStandardUse.TermsAndConditions, 'Terms and conditions'],
  [DocumentStandardUse.ProductDisclosureStatement, 'Product disclosure statement (PDS)'],
  [DocumentStandardUse.FinancialServicesGuide, 'Financial services guide (FSG)'],
  [DocumentStandardUse.ConsumerLendingTermsAndConditions, 'Consumer lending terms and conditions'],
  [DocumentStandardUse.LoanMortgageInsurance, 'Loan mortgage insurance (LMI)'],
  [DocumentStandardUse.DiscountPackages, 'Discount packages'],
  [DocumentStandardUse.ProductDetail, 'Product detail'],
  [DocumentStandardUse.SpecialOffers, 'Special offers'],
  [DocumentStandardUse.RateCard, 'Rate card'],
  [DocumentStandardUse.MarketingBrochure, 'Marketing brochure'],
  [DocumentStandardUse.MarketingFlyer, 'Marketing flyer'],
  [DocumentStandardUse.PersonalLoans, 'Personal loans'],
  [DocumentStandardUse.BusinessLoans, 'Business loans'],
  [DocumentStandardUse.InvestmentLoans, 'Investment loans'],
  [DocumentStandardUse.Banking, 'Banking'],
  [DocumentStandardUse.BankingTermsAndConditions, 'Banking terms and conditions'],
  [DocumentStandardUse.Policy, 'Policy'],
  [DocumentStandardUse.CreditPolicy, 'Credit policy'],
  [DocumentStandardUse.Insurance, 'Insurance'],
  [DocumentStandardUse.InsuranceTermsAndConditions, 'Insurance terms and conditions'],
  [DocumentStandardUse.HomeLoanFeesAndCharges, 'Home loan fees and charges'],
  [DocumentStandardUse.TargetMarketDetermination, 'Target market determination (TMD)'],
  [DocumentStandardUse.OtherLending, 'Other-lending'],
  [DocumentStandardUse.OtherBanking, 'Other-banking'],
  [DocumentStandardUse.OtherInsurance, 'Other-insurance'],
  [DocumentStandardUse.Other, 'Other'],
]);

export enum DocumentChecklistTemplateStatus {
  Active = 1,
}

export const DocumentChecklistTemplateStatusLabel = new Map<number, string>([
  [DocumentChecklistTemplateStatus.Active, 'Active'],
]);

export enum DocumentChecklistTemplateCategoryStatus {
  Active = 1,
}

export const DocumentChecklistTemplateCategoryStatusLabel = new Map<number, string>([
  [DocumentChecklistTemplateCategoryStatus.Active, 'Active'],
]);

export enum DocumentChecklistTemplateItemStatus {
  Active = 1,
}

export const DocumentChecklistTemplateItemStatusLabel = new Map<number, string>([
  [DocumentChecklistTemplateItemStatus.Active, 'Active'],
]);

export enum DocumentStandardUseStatus {
  Active = 1,
  Inactive = 2,
}

export const DocumentStandardUseStatusLabel = new Map<number, string>([
  [DocumentStandardUseStatus.Active, 'Active'],
  [DocumentStandardUseStatus.Inactive, 'Inactive'],
]);

export enum DocumentVerificationResultConcernStatus {
  Active = 1,
  Dismissed = 2,
  Confirmed = 3,
  Resubmitted = 4,
}

export const DocumentVerificationResultConcernStatusLabel = new Map<number, string>([
  [DocumentVerificationResultConcernStatus.Active, 'Active'],
  [DocumentVerificationResultConcernStatus.Dismissed, 'Dismissed'],
  [DocumentVerificationResultConcernStatus.Confirmed, 'Confirmed'],
  [DocumentVerificationResultConcernStatus.Resubmitted, 'Resubmitted'],
]);

export enum DocumentVersionStatus {
  Active = 1,
  Inactive = 2,
}

export const DocumentVersionStatusLabel = new Map<number, string>([
  [DocumentVersionStatus.Active, 'Active'],
  [DocumentVersionStatus.Inactive, 'Inactive'],
]);

export enum DynamicQueryStatus {
  Active = 1,
  Inactive = 2,
}

export const DynamicQueryStatusLabel = new Map<number, string>([
  [DynamicQueryStatus.Active, 'Active'],
  [DynamicQueryStatus.Inactive, 'Inactive'],
]);

export enum DynamicQueryFieldCategory {
  Product = 1,
  AppData = 2,
}

export const DynamicQueryFieldCategoryLabel = new Map<number, string>([
  [DynamicQueryFieldCategory.Product, 'Product'],
  [DynamicQueryFieldCategory.AppData, 'AppData'],
]);

export enum DynamicQueryFieldStatus {
  Active = 1,
  Inactive = 2,
}

export const DynamicQueryFieldStatusLabel = new Map<number, string>([
  [DynamicQueryFieldStatus.Active, 'Active'],
  [DynamicQueryFieldStatus.Inactive, 'Inactive'],
]);

export enum DynamicQueryFieldType {
  Number = 1,
  String = 2,
  Date = 3,
  Boolean = 4,
  Enum = 5,
  List = 6,
  Postcode = 7,
  Condition = 8,
}

export const DynamicQueryFieldTypeLabel = new Map<number, string>([
  [DynamicQueryFieldType.Number, 'Number'],
  [DynamicQueryFieldType.String, 'String'],
  [DynamicQueryFieldType.Date, 'Date'],
  [DynamicQueryFieldType.Boolean, 'Boolean'],
  [DynamicQueryFieldType.Enum, 'Enum'],
  [DynamicQueryFieldType.List, 'List'],
  [DynamicQueryFieldType.Postcode, 'Postcode'],
  [DynamicQueryFieldType.Condition, 'Condition'],
]);

export enum DynamicRuleCategory {}

export const DynamicRuleCategoryLabel = new Map<number, string>([]);

export enum DynamicRuleStatus {
  Active = 1,
  Inactive = 2,
}

export const DynamicRuleStatusLabel = new Map<number, string>([
  [DynamicRuleStatus.Active, 'Active'],
  [DynamicRuleStatus.Inactive, 'Inactive'],
]);

export enum DynamicRuleFieldCategory {
  Product = 1,
}

export const DynamicRuleFieldCategoryLabel = new Map<number, string>([[DynamicRuleFieldCategory.Product, 'Product']]);

export enum DynamicRuleFieldStatus {
  Active = 1,
  Inactive = 2,
}

export const DynamicRuleFieldStatusLabel = new Map<number, string>([
  [DynamicRuleFieldStatus.Active, 'Active'],
  [DynamicRuleFieldStatus.Inactive, 'Inactive'],
]);

export enum DynamicRuleFieldType {
  Number = 1,
  String = 2,
  Date = 3,
  Boolean = 4,
  Enum = 5,
}

export const DynamicRuleFieldTypeLabel = new Map<number, string>([
  [DynamicRuleFieldType.Number, 'Number'],
  [DynamicRuleFieldType.String, 'String'],
  [DynamicRuleFieldType.Date, 'Date'],
  [DynamicRuleFieldType.Boolean, 'Boolean'],
  [DynamicRuleFieldType.Enum, 'Enum'],
]);

export enum EntityType {
  Lender = 1,
  Aggregator = 2,
  Brokerage = 3,
  LoanFunder = 4,
  LoanOriginator = 5,
  MortgageManager = 6,
}

export const EntityTypeLabel = new Map<number, string>([
  [EntityType.Lender, 'Lender'],
  [EntityType.Aggregator, 'Aggregator'],
  [EntityType.Brokerage, 'Brokerage'],
  [EntityType.LoanFunder, 'Loan funder'],
  [EntityType.LoanOriginator, 'Loan originator'],
  [EntityType.MortgageManager, 'Mortgage manager'],
]);

export enum EntityStatus {
  Active = 1,
  Inactive = 2,
}

export const EntityStatusLabel = new Map<number, string>([
  [EntityStatus.Active, 'Active'],
  [EntityStatus.Inactive, 'Inactive'],
]);

export enum EntityCompanyNumberType {
  ABN = 1,
  NZBN = 2,
  EIN = 3,
  USCC = 4,
}

export const EntityCompanyNumberTypeLabel = new Map<number, string>([
  [EntityCompanyNumberType.ABN, 'ABN'],
  [EntityCompanyNumberType.NZBN, 'NZBN'],
  [EntityCompanyNumberType.EIN, 'EIN'],
  [EntityCompanyNumberType.USCC, 'USCC'],
]);

export enum EntityBrandImageBrandType {
  EmailFooter = 4,
  InfoRequest = 5,
}

export const EntityBrandImageBrandTypeLabel = new Map<number, string>([
  [EntityBrandImageBrandType.EmailFooter, 'Email footer'],
  [EntityBrandImageBrandType.InfoRequest, 'Info request'],
]);

export enum FeatureOptionStatus {
  Active = 1,
  Inactive = 2,
}

export const FeatureOptionStatusLabel = new Map<number, string>([
  [FeatureOptionStatus.Active, 'Active'],
  [FeatureOptionStatus.Inactive, 'Inactive'],
]);

export enum FeatureOptionFeatureOptionCode {
  Personal = 3001,
  OwnerOccupied = 3002,
  InvestmentResidential = 3003,
  InvestmentNonResidential = 3004,
  Business = 3005,
  Investment = 3006,
  ProfessionalInvestor = 3007,
  ABS125 = 4001,
  ABS127 = 4002,
  ABS129 = 4003,
  ABS131 = 4004,
  ABS231 = 4005,
  ABS329 = 4006,
  ABS330 = 4007,
  ABS331 = 4008,
  ABS332 = 4009,
  ABS333 = 4010,
  ABS335 = 4011,
  ABS336 = 4012,
  ABS337 = 4013,
  ABS121 = 4014,
  ABS123 = 4015,
  ABS143 = 4016,
  ABS320 = 4017,
  ABS321 = 4018,
  ABS322 = 4019,
  ABS323 = 4020,
  ABS324 = 4021,
  ABS326 = 4022,
  ABS327 = 4023,
  ABS221 = 4024,
  ABS223 = 4025,
  ABS225 = 4026,
  ABS227 = 4027,
  ABS229 = 4028,
  ABS237 = 4029,
  ABS241 = 4030,
  ABS340 = 4031,
  ABS341 = 4032,
  ABS342 = 4033,
  ABS343 = 4034,
  ABS133 = 4035,
  ABS239 = 4036,
  ABS243 = 4037,
  ABS248 = 4038,
  ABS348 = 4039,
  ABS245 = 4040,
  ABS247 = 4041,
  ABS249 = 4042,
  ABS349 = 4043,
  VariableRate = 5001,
  FixedRate = 5002,
  BasicVariableRate = 5003,
  PrincipalAndInterest = 6001,
  InterestOnly = 6002,
  PrincipalAndInterestFees = 6003,
  InterestCapitalised = 6004,
  InterestInAdvance = 6005,
  FirstMortgage = 7001,
  RegisteredSecurity = 7002,
  SecondAfterDSH = 7003,
  SecondMortgage = 7004,
  ThirdMortgage = 7005,
  UnregisteredMortgage = 7006,
  Monthly = 9001,
  Fortnightly = 9002,
  Weekly = 9003,
  Quarterly = 9004,
  HalfYearly = 9005,
  Yearly = 9006,
  HalfMonthly = 9007,
  Seasonal = 9008,
  Years_1 = 10001,
  Years_2 = 10002,
  Years_3 = 10003,
  Years_4 = 10004,
  Years_5 = 10005,
  Years_7 = 10006,
  Years_10 = 10007,
  Years_15 = 10008,
  Years_20 = 10009,
  Years_25 = 10010,
  Years_30 = 10011,
  Years_35 = 10012,
  ChequeBook = 11001,
  ProgressiveDraw = 11002,
  Portability = 11003,
  RepaymentHoliday = 11004,
  ParentalLeave = 11005,
  SplitLoan = 11006,
  Offset = 11007,
  PartialOffset = 11008,
  RateLock = 11009,
  CreditCard = 11010,
  DebitCard = 11011,
  DepositBook = 11012,
  DepositAccount = 11013,
  Redraw = 11014,
  BridgingFinance = 11015,
  AdditionalRepayments = 11016,
  LMIRequired = 11017,
  LMICapitalised = 11018,
  LMIAvailable = 11019,
  LMICapitalisationGreaterThanMaxLVR = 11020,
  ApplyToIncreaseLoanAmount = 11021,
  FamilyAsGuarantor = 11022,
  SF_InterestInAdvance = 11023,
  InternetBanking = 11024,
  ProfessionalPack = 11025,
  TelephoneBanking = 11026,
  NoGenuineSavingsRequired = 11027,
  MaximumLVR = 12001,
  MaximumLoanAmount = 12002,
  MinimumLoanAmount = 12003,
  MaximumSplits = 12004,
  MinimumSavings = 12005,
  MinimumSplitAmount = 12006,
  MinimumLoanTerm = 12007,
  MaximumLoanTerm = 12008,
  MaxPaidDefaults = 12009,
  MaxPaidDefaultValue = 12010,
  MaxUnpaidDefaults = 12011,
  MaxUnpaidDefaultValue = 12012,
  UnpaidDefaultValueIgnored = 12013,
  BankruptcyMonths = 12014,
  BankruptcyMonthsDischarged = 12015,
  NonMortgageArrearsPeriod = 12016,
  MortgageArrearsPeriod = 12017,
  MaximumYearlyAdditionalRepayment = 12018,
  MinimimDeposit = 12019,
  MaximumInterestOnlyPeriod = 12021,
  InterestOnlyTerm = 12022,
  MaximumRedrawAmountPerAnnum = 12023,
  MinimumRedraw = 12024,
  NumberOfFreeTransactionsPerMonth = 12025,
  NSW = 13001,
  Vic = 13002,
  Qld = 13003,
  SA = 13004,
  WA = 13005,
  Tas = 13006,
  ACT = 13007,
  NT = 13008,
  OneYear = 15001,
  TwoYears = 15002,
  ThreeYears = 15003,
  FourYears = 15004,
  FiveYears = 15005,
  SixYears = 15006,
  SevenYears = 15007,
  EightYears = 15008,
  NineYears = 15009,
  TenYears = 15010,
  SixMonths = 15011,
  EighteenMonths = 15012,
  Purchase = 16001,
  Refinance = 16002,
  Variation = 16003,
  DebtConsolidation = 16004,
  CashOut = 16005,
  LoanReason_Construction = 16006,
  Introductory = 17001,
  LineOfCredit = 17003,
  MortgageLoan = 17004,
  Bridging = 17006,
  Construction = 17007,
  LoanType_VacantLand = 17009,
  Equity = 17010,
  SMSF = 17012,
  ReverseMortgage = 17013,
  PersonalBorrower = 18001,
  Company = 18002,
  Trust = 18003,
  FirstHomeOwner = 18004,
  Individual = 18005,
  LowDoc = 19001,
  FullDoc = 19002,
  NoDoc = 19003,
  AltDoc = 19004,
  Fixed_PrincipalAndInterest = 20001,
  Fixed_InterestOnly = 20002,
  Fixed_PrincipalAndInterestFees = 20003,
  Fixed_InterestCapitalised = 20004,
  Fixed_InterestInAdvance = 20005,
  ResidentialProperty = 21001,
  CommercialProperty = 21002,
  VacantLand = 21003,
  RuralLand = 21004,
  IndustrialProperty = 21005,
  InnerCityApartment = 21006,
  Fixed_VariableRate = 22001,
  Fixed_FixedRate = 22002,
  Resident = 23001,
  NonResident = 23002,
  Expatriate = 23003,
  Clean = 24001,
  PaidDefaults = 24002,
  UnpaidDefaults = 24003,
  CurrentBankrupt = 24004,
  DischargedBankrupt = 24005,
  NonMortgageArrears = 24006,
  MortgageArrears = 24007,
  AustraliaPost = 25001,
  BPay = 25002,
  Repayment_CreditCard = 25003,
  DirectDebitExistingAccount = 25004,
  DirectDebitNewAccount = 25005,
  DirectSalaryCredit = 25006,
  FundsTransfer = 25007,
  LenderBranch = 25008,
  StaffPay = 25009,
  ConstructionOfDwelling = 26001,
  DebtConsolidationNZ = 26002,
  EECAEnergyEfficiencyFunding = 26003,
  FixLeakyHome = 26004,
  FixNaturalDisasterAffectedHome = 26005,
  HolidayTravel = 26006,
  OtherPurpose = 26007,
  PurchaseVehicle = 26008,
  PurchaseOfDwelling = 26009,
  RefinanceOfLiabilitiesWithCurrentLender = 26010,
  RefinanceOfLiabilitiesWithOtherLenders = 26011,
  RenovationsAndExtensions = 26012,
  Preliminary_VariableRate = 27001,
  Preliminary_FixedRate = 27002,
  Preliminary_BasicVariableRate = 27003,
  Preliminary_OneYear = 28001,
  Preliminary_TwoYears = 28002,
  Preliminary_ThreeYears = 28003,
  Preliminary_FourYears = 28004,
  Preliminary_FiveYears = 28005,
  Preliminary_SixYears = 28006,
  Preliminary_SevenYears = 28007,
  Preliminary_EightYears = 28008,
  Preliminary_NineYears = 28009,
  Preliminary_TenYears = 28010,
  Preliminary_SixMonths = 28011,
  Preliminary_EighteenMonths = 28012,
  PreliminaryFixed_PrincipalAndInterest = 29001,
  PreliminaryFixed_InterestOnly = 29002,
  PreliminaryFixed_PrincipalAndInterestFees = 29003,
  PreliminaryFixed_InterestCapitalised = 29004,
  PreliminaryFixed_InterestInAdvance = 29005,
  Other = 0,
}

export const FeatureOptionFeatureOptionCodeLabel = new Map<number, string>([
  [FeatureOptionFeatureOptionCode.Personal, 'Personal'],
  [FeatureOptionFeatureOptionCode.OwnerOccupied, 'Owner occupied'],
  [FeatureOptionFeatureOptionCode.InvestmentResidential, 'Investment residential'],
  [FeatureOptionFeatureOptionCode.InvestmentNonResidential, 'Investment non-residential'],
  [FeatureOptionFeatureOptionCode.Business, 'Business'],
  [FeatureOptionFeatureOptionCode.Investment, 'Investment'],
  [FeatureOptionFeatureOptionCode.ProfessionalInvestor, 'Professional investor'],
  [FeatureOptionFeatureOptionCode.ABS125, 'ABS-125 Purchase of newly erected dwelling - House'],
  [FeatureOptionFeatureOptionCode.ABS127, 'ABS-127 Purchase of newly erected dwelling - Other dwelling'],
  [FeatureOptionFeatureOptionCode.ABS129, 'ABS-129 Purchase of established dwelling - House'],
  [FeatureOptionFeatureOptionCode.ABS131, 'ABS-131 Purchase of established dwelling - Other dwelling'],
  [FeatureOptionFeatureOptionCode.ABS231, 'ABS-231 Purchase of individual residential blocks of land'],
  [FeatureOptionFeatureOptionCode.ABS329, 'ABS-329 Purchase of land and buildings - Dwellings for rent/resale'],
  [FeatureOptionFeatureOptionCode.ABS330, 'ABS-330 Purchase of land and buildings - Shops'],
  [FeatureOptionFeatureOptionCode.ABS331, 'ABS-331 Purchase of land and buildings - Offices'],
  [
    FeatureOptionFeatureOptionCode.ABS332,
    'ABS-332 Purchase of land and buildings - Industrial buildings (including factories, automotive repair facilities, etc.)',
  ],
  [
    FeatureOptionFeatureOptionCode.ABS333,
    'ABS-333 Purchase of land and buildings - Other non-residential buildings (including hotels, buildings for educational, religious, health, entertainment and recreational purposes)',
  ],
  [FeatureOptionFeatureOptionCode.ABS335, 'ABS-335 Purchase of land and buildings - Rural property'],
  [
    FeatureOptionFeatureOptionCode.ABS336,
    'ABS-336 Purchase of land and buildings - Land for residential block development',
  ],
  [FeatureOptionFeatureOptionCode.ABS337, 'ABS-337 Purchase of land and buildings - Other land purchases'],
  [FeatureOptionFeatureOptionCode.ABS121, 'ABS-121 Construction of dwelling - House'],
  [FeatureOptionFeatureOptionCode.ABS123, 'ABS-123 Construction of dwelling - Other dwelling'],
  [
    FeatureOptionFeatureOptionCode.ABS143,
    'ABS-143 Structural and non-structural alterations and/or additions to dwellings (excluding swimming pools, maintenance, repairs and other home improvements)',
  ],
  [FeatureOptionFeatureOptionCode.ABS320, 'ABS-320 Construction of dwellings for rent/resale'],
  [FeatureOptionFeatureOptionCode.ABS321, 'ABS-321 Construction of non-residential buildings - Shop'],
  [FeatureOptionFeatureOptionCode.ABS322, 'ABS-322 Construction of non-residential buildings - Offices'],
  [FeatureOptionFeatureOptionCode.ABS323, 'ABS-323 Construction of non-residential buildings - Industrial buildings'],
  [FeatureOptionFeatureOptionCode.ABS324, 'ABS-324 Construction of non-residential buildings - Other'],
  [FeatureOptionFeatureOptionCode.ABS326, 'ABS-326 Construction of non-building structures'],
  [
    FeatureOptionFeatureOptionCode.ABS327,
    "ABS-327 Structural and non-structural alterations and/or additions to buildings (excluding maintenance, repairs and other improvements that don't include building work)",
  ],
  [FeatureOptionFeatureOptionCode.ABS221, 'ABS-221 Purchase of motor cars and station wagons - New'],
  [FeatureOptionFeatureOptionCode.ABS223, 'ABS-223 Purchase of motor cars and station wagons - Used'],
  [FeatureOptionFeatureOptionCode.ABS225, 'ABS-225 Purchase of other motor vehicles'],
  [FeatureOptionFeatureOptionCode.ABS227, 'ABS-227 Purchase of motor cycles, scooters'],
  [FeatureOptionFeatureOptionCode.ABS229, 'ABS-229 Purchase of boats, caravans and trailers'],
  [FeatureOptionFeatureOptionCode.ABS237, 'ABS-237 Purchase of household and personal goods'],
  [FeatureOptionFeatureOptionCode.ABS241, 'ABS-241 Travel and holidays'],
  [
    FeatureOptionFeatureOptionCode.ABS340,
    'ABS-340 Wholesale Finance (including purchase of goods by wholesalers or retailers, traders’ stocks held under bailment or floorplan schemes or working capital)',
  ],
  [
    FeatureOptionFeatureOptionCode.ABS341,
    'ABS-341 Purchase of plant and equipment - Motor vehicles (excluding motorcycles, trailers, caravans, trains, boats and planes)',
  ],
  [
    FeatureOptionFeatureOptionCode.ABS342,
    'ABS-342 Purchase of plant and equipment - Other transport equipment (including motorcycles, trailers, caravans, trains, boats and planes)',
  ],
  [
    FeatureOptionFeatureOptionCode.ABS343,
    'ABS-343 Purchase of plant and equipment - Other plant equipment (including construction and earthmoving equipment, agricultural machinery and office equipment)',
  ],
  [
    FeatureOptionFeatureOptionCode.ABS133,
    'ABS-133 Refinance existing home loans - Loans held with other financial institutions',
  ],
  [FeatureOptionFeatureOptionCode.ABS239, 'ABS-239 Debt consolidation (excluding refinance of personal loans)'],
  [FeatureOptionFeatureOptionCode.ABS243, 'ABS-243 Refinance of personal loans (excluding investment loans)'],
  [
    FeatureOptionFeatureOptionCode.ABS248,
    'ABS-248 Refinancing of investment loans (including purchase of dwellings for rent/resale, purchase of shares and other investment assets)',
  ],
  [FeatureOptionFeatureOptionCode.ABS348, 'ABS-348 Refinance of existing commercial loans'],
  [FeatureOptionFeatureOptionCode.ABS245, 'ABS-245 Personal investment in dwellings for rent/resale'],
  [
    FeatureOptionFeatureOptionCode.ABS247,
    'ABS-247 Other personal investment (including shares and other investment assets)',
  ],
  [
    FeatureOptionFeatureOptionCode.ABS249,
    'ABS-249 Other purposes (including swimming pools and home improvements - other than alterations and additions, motor accessories and any other purpose)',
  ],
  [
    FeatureOptionFeatureOptionCode.ABS349,
    'ABS-349 Other purpose (including factoring - whether secured by trade debts or to purchase trade debts, maintenance, repairs and other improvements to property not involving building work)',
  ],
  [FeatureOptionFeatureOptionCode.VariableRate, 'Variable rate'],
  [FeatureOptionFeatureOptionCode.FixedRate, 'Fixed rate'],
  [FeatureOptionFeatureOptionCode.BasicVariableRate, 'Basic variable rate'],
  [FeatureOptionFeatureOptionCode.PrincipalAndInterest, 'Principal and interest'],
  [FeatureOptionFeatureOptionCode.InterestOnly, 'Interest only'],
  [FeatureOptionFeatureOptionCode.PrincipalAndInterestFees, 'Principal and interest fees'],
  [FeatureOptionFeatureOptionCode.InterestCapitalised, 'Interest capitalised'],
  [FeatureOptionFeatureOptionCode.InterestInAdvance, 'Interest in advance'],
  [FeatureOptionFeatureOptionCode.FirstMortgage, 'First mortgage'],
  [FeatureOptionFeatureOptionCode.RegisteredSecurity, 'Registered security'],
  [FeatureOptionFeatureOptionCode.SecondAfterDSH, 'Second after DSH'],
  [FeatureOptionFeatureOptionCode.SecondMortgage, 'Second mortgage'],
  [FeatureOptionFeatureOptionCode.ThirdMortgage, 'Third mortgage'],
  [FeatureOptionFeatureOptionCode.UnregisteredMortgage, 'Unregistered mortgage'],
  [FeatureOptionFeatureOptionCode.Monthly, 'Monthly'],
  [FeatureOptionFeatureOptionCode.Fortnightly, 'Fortnightly'],
  [FeatureOptionFeatureOptionCode.Weekly, 'Weekly'],
  [FeatureOptionFeatureOptionCode.Quarterly, 'Quarterly'],
  [FeatureOptionFeatureOptionCode.HalfYearly, 'Half yearly'],
  [FeatureOptionFeatureOptionCode.Yearly, 'Yearly'],
  [FeatureOptionFeatureOptionCode.HalfMonthly, 'Half monthly'],
  [FeatureOptionFeatureOptionCode.Seasonal, 'Seasonal'],
  [FeatureOptionFeatureOptionCode.Years_1, '1'],
  [FeatureOptionFeatureOptionCode.Years_2, '2'],
  [FeatureOptionFeatureOptionCode.Years_3, '3'],
  [FeatureOptionFeatureOptionCode.Years_4, '4'],
  [FeatureOptionFeatureOptionCode.Years_5, '5'],
  [FeatureOptionFeatureOptionCode.Years_7, '7'],
  [FeatureOptionFeatureOptionCode.Years_10, '10'],
  [FeatureOptionFeatureOptionCode.Years_15, '15'],
  [FeatureOptionFeatureOptionCode.Years_20, '20'],
  [FeatureOptionFeatureOptionCode.Years_25, '25'],
  [FeatureOptionFeatureOptionCode.Years_30, '30'],
  [FeatureOptionFeatureOptionCode.Years_35, '35'],
  [FeatureOptionFeatureOptionCode.ChequeBook, 'Cheque book'],
  [FeatureOptionFeatureOptionCode.ProgressiveDraw, 'Progressive draw'],
  [FeatureOptionFeatureOptionCode.Portability, 'Portability'],
  [FeatureOptionFeatureOptionCode.RepaymentHoliday, 'Repayment holiday'],
  [FeatureOptionFeatureOptionCode.ParentalLeave, 'Parental leave'],
  [FeatureOptionFeatureOptionCode.SplitLoan, 'Split loan'],
  [FeatureOptionFeatureOptionCode.Offset, 'Offset'],
  [FeatureOptionFeatureOptionCode.PartialOffset, 'Partial offset'],
  [FeatureOptionFeatureOptionCode.RateLock, 'Rate lock'],
  [FeatureOptionFeatureOptionCode.CreditCard, 'Credit card'],
  [FeatureOptionFeatureOptionCode.DebitCard, 'Debit card'],
  [FeatureOptionFeatureOptionCode.DepositBook, 'Deposit book'],
  [FeatureOptionFeatureOptionCode.DepositAccount, 'Deposit account'],
  [FeatureOptionFeatureOptionCode.Redraw, 'Redraw'],
  [FeatureOptionFeatureOptionCode.BridgingFinance, 'Bridging finance'],
  [FeatureOptionFeatureOptionCode.AdditionalRepayments, 'Additional repayments'],
  [FeatureOptionFeatureOptionCode.LMIRequired, 'LMI required'],
  [FeatureOptionFeatureOptionCode.LMICapitalised, 'LMI capitalised'],
  [FeatureOptionFeatureOptionCode.LMIAvailable, 'LMI available'],
  [FeatureOptionFeatureOptionCode.LMICapitalisationGreaterThanMaxLVR, 'LMI capitalisation > max LVR'],
  [FeatureOptionFeatureOptionCode.ApplyToIncreaseLoanAmount, 'Apply to increase loan amount'],
  [FeatureOptionFeatureOptionCode.FamilyAsGuarantor, 'Family as guarantor'],
  [FeatureOptionFeatureOptionCode.SF_InterestInAdvance, 'Interest in advance'],
  [FeatureOptionFeatureOptionCode.InternetBanking, 'Internet banking'],
  [FeatureOptionFeatureOptionCode.ProfessionalPack, 'Professional pack'],
  [FeatureOptionFeatureOptionCode.TelephoneBanking, 'Telephone banking'],
  [FeatureOptionFeatureOptionCode.NoGenuineSavingsRequired, 'No genuine savings required'],
  [FeatureOptionFeatureOptionCode.MaximumLVR, 'Maximum LVR'],
  [FeatureOptionFeatureOptionCode.MaximumLoanAmount, 'Maximum loan amount'],
  [FeatureOptionFeatureOptionCode.MinimumLoanAmount, 'Minimum loan amount'],
  [FeatureOptionFeatureOptionCode.MaximumSplits, 'Maximum number of splits'],
  [FeatureOptionFeatureOptionCode.MinimumSavings, 'Minimum genuine savings'],
  [FeatureOptionFeatureOptionCode.MinimumSplitAmount, 'Minimum split amount'],
  [FeatureOptionFeatureOptionCode.MinimumLoanTerm, 'Minimum loan term'],
  [FeatureOptionFeatureOptionCode.MaximumLoanTerm, 'Maximum loan term'],
  [FeatureOptionFeatureOptionCode.MaxPaidDefaults, 'Maximum number of paid defaults'],
  [FeatureOptionFeatureOptionCode.MaxPaidDefaultValue, 'Maximum value of paid defaults'],
  [FeatureOptionFeatureOptionCode.MaxUnpaidDefaults, 'Maximum number of unpaid defaults'],
  [FeatureOptionFeatureOptionCode.MaxUnpaidDefaultValue, 'Maximum value of unpaid defaults'],
  [FeatureOptionFeatureOptionCode.UnpaidDefaultValueIgnored, 'Value of unpaid defaults ignored'],
  [FeatureOptionFeatureOptionCode.BankruptcyMonths, 'Number of months in bankruptcy'],
  [FeatureOptionFeatureOptionCode.BankruptcyMonthsDischarged, 'Number of months bankruptcy discharged'],
  [FeatureOptionFeatureOptionCode.NonMortgageArrearsPeriod, 'Period of non-mortgage arrears (months)'],
  [FeatureOptionFeatureOptionCode.MortgageArrearsPeriod, 'Period of mortgage arrears (months)'],
  [FeatureOptionFeatureOptionCode.MaximumYearlyAdditionalRepayment, 'Maximum yearly additional repayment'],
  [FeatureOptionFeatureOptionCode.MinimimDeposit, 'Minimum deposit (%)'],
  [FeatureOptionFeatureOptionCode.MaximumInterestOnlyPeriod, 'Maximum interest only period (months)'],
  [FeatureOptionFeatureOptionCode.InterestOnlyTerm, 'Interest only term (months)'],
  [FeatureOptionFeatureOptionCode.MaximumRedrawAmountPerAnnum, 'Maximum redraw amount per annum'],
  [FeatureOptionFeatureOptionCode.MinimumRedraw, 'Minimum redraw amount'],
  [FeatureOptionFeatureOptionCode.NumberOfFreeTransactionsPerMonth, 'Number of free transactions per month'],
  [FeatureOptionFeatureOptionCode.NSW, 'New South Wales'],
  [FeatureOptionFeatureOptionCode.Vic, 'Victoria'],
  [FeatureOptionFeatureOptionCode.Qld, 'Queensland'],
  [FeatureOptionFeatureOptionCode.SA, 'South Australia'],
  [FeatureOptionFeatureOptionCode.WA, 'Western Australia'],
  [FeatureOptionFeatureOptionCode.Tas, 'Tasmania'],
  [FeatureOptionFeatureOptionCode.ACT, 'Australian Capital Territory'],
  [FeatureOptionFeatureOptionCode.NT, 'Northern Territory'],
  [FeatureOptionFeatureOptionCode.OneYear, 'One year'],
  [FeatureOptionFeatureOptionCode.TwoYears, 'Two years'],
  [FeatureOptionFeatureOptionCode.ThreeYears, 'Three years'],
  [FeatureOptionFeatureOptionCode.FourYears, 'Four years'],
  [FeatureOptionFeatureOptionCode.FiveYears, 'Five years'],
  [FeatureOptionFeatureOptionCode.SixYears, 'Six years'],
  [FeatureOptionFeatureOptionCode.SevenYears, 'Seven years'],
  [FeatureOptionFeatureOptionCode.EightYears, 'Eight years'],
  [FeatureOptionFeatureOptionCode.NineYears, 'Nine years'],
  [FeatureOptionFeatureOptionCode.TenYears, 'Ten years'],
  [FeatureOptionFeatureOptionCode.SixMonths, 'Six months'],
  [FeatureOptionFeatureOptionCode.EighteenMonths, 'Eighteen months'],
  [FeatureOptionFeatureOptionCode.Purchase, 'Purchase'],
  [FeatureOptionFeatureOptionCode.Refinance, 'Refinance'],
  [FeatureOptionFeatureOptionCode.Variation, 'Variation'],
  [FeatureOptionFeatureOptionCode.DebtConsolidation, 'Debt consolidation'],
  [FeatureOptionFeatureOptionCode.CashOut, 'Cash out'],
  [FeatureOptionFeatureOptionCode.LoanReason_Construction, 'Construction'],
  [FeatureOptionFeatureOptionCode.Introductory, 'Introductory / honeymoon'],
  [FeatureOptionFeatureOptionCode.LineOfCredit, 'Line of credit'],
  [FeatureOptionFeatureOptionCode.MortgageLoan, 'Mortgage loan'],
  [FeatureOptionFeatureOptionCode.Bridging, 'Bridging'],
  [FeatureOptionFeatureOptionCode.Construction, 'Construction'],
  [FeatureOptionFeatureOptionCode.LoanType_VacantLand, 'Vacant land'],
  [FeatureOptionFeatureOptionCode.Equity, 'Equity'],
  [FeatureOptionFeatureOptionCode.SMSF, 'Self managed super fund (SMSF)'],
  [FeatureOptionFeatureOptionCode.ReverseMortgage, 'Reverse mortgage'],
  [FeatureOptionFeatureOptionCode.PersonalBorrower, 'Personal'],
  [FeatureOptionFeatureOptionCode.Company, 'Company'],
  [FeatureOptionFeatureOptionCode.Trust, 'Trust'],
  [FeatureOptionFeatureOptionCode.FirstHomeOwner, 'First home owner'],
  [FeatureOptionFeatureOptionCode.Individual, 'Individual'],
  [FeatureOptionFeatureOptionCode.LowDoc, 'Low doc'],
  [FeatureOptionFeatureOptionCode.FullDoc, 'Full doc'],
  [FeatureOptionFeatureOptionCode.NoDoc, 'No doc'],
  [FeatureOptionFeatureOptionCode.AltDoc, 'Alt doc'],
  [FeatureOptionFeatureOptionCode.Fixed_PrincipalAndInterest, 'Principal and interest'],
  [FeatureOptionFeatureOptionCode.Fixed_InterestOnly, 'Interest only'],
  [FeatureOptionFeatureOptionCode.Fixed_PrincipalAndInterestFees, 'Principal and interest fees'],
  [FeatureOptionFeatureOptionCode.Fixed_InterestCapitalised, 'Interest capitalised'],
  [FeatureOptionFeatureOptionCode.Fixed_InterestInAdvance, 'Interest in advance'],
  [FeatureOptionFeatureOptionCode.ResidentialProperty, 'Residential property'],
  [FeatureOptionFeatureOptionCode.CommercialProperty, 'Commercial property'],
  [FeatureOptionFeatureOptionCode.VacantLand, 'Vacant land'],
  [FeatureOptionFeatureOptionCode.RuralLand, 'Rural land'],
  [FeatureOptionFeatureOptionCode.IndustrialProperty, 'Industrial property'],
  [FeatureOptionFeatureOptionCode.InnerCityApartment, 'Inner city apartment'],
  [FeatureOptionFeatureOptionCode.Fixed_VariableRate, 'Variable rate'],
  [FeatureOptionFeatureOptionCode.Fixed_FixedRate, 'Fixed rate'],
  [FeatureOptionFeatureOptionCode.Resident, 'Resident'],
  [FeatureOptionFeatureOptionCode.NonResident, 'Non-resident'],
  [FeatureOptionFeatureOptionCode.Expatriate, 'Expatriate'],
  [FeatureOptionFeatureOptionCode.Clean, 'Clean'],
  [FeatureOptionFeatureOptionCode.PaidDefaults, 'Paid defaults'],
  [FeatureOptionFeatureOptionCode.UnpaidDefaults, 'Unpaid defaults'],
  [FeatureOptionFeatureOptionCode.CurrentBankrupt, 'Current bankrupt'],
  [FeatureOptionFeatureOptionCode.DischargedBankrupt, 'Discharged bankrupt'],
  [FeatureOptionFeatureOptionCode.NonMortgageArrears, 'Non-mortgage arrears'],
  [FeatureOptionFeatureOptionCode.MortgageArrears, 'Mortgage arrears'],
  [FeatureOptionFeatureOptionCode.AustraliaPost, 'Australia Post'],
  [FeatureOptionFeatureOptionCode.BPay, 'BPay'],
  [FeatureOptionFeatureOptionCode.Repayment_CreditCard, 'Credit card'],
  [FeatureOptionFeatureOptionCode.DirectDebitExistingAccount, 'Direct debit existing account'],
  [FeatureOptionFeatureOptionCode.DirectDebitNewAccount, 'Direct debit new account'],
  [FeatureOptionFeatureOptionCode.DirectSalaryCredit, 'Direct salary credit'],
  [FeatureOptionFeatureOptionCode.FundsTransfer, 'Funds transfer'],
  [FeatureOptionFeatureOptionCode.LenderBranch, 'Lender branch'],
  [FeatureOptionFeatureOptionCode.StaffPay, 'Staff pay'],
  [FeatureOptionFeatureOptionCode.ConstructionOfDwelling, 'Construction of dwelling'],
  [FeatureOptionFeatureOptionCode.DebtConsolidationNZ, 'Debt consolidation'],
  [FeatureOptionFeatureOptionCode.EECAEnergyEfficiencyFunding, 'EECA Energy efficiency funding'],
  [FeatureOptionFeatureOptionCode.FixLeakyHome, 'Fix leaky home'],
  [FeatureOptionFeatureOptionCode.FixNaturalDisasterAffectedHome, 'Fix natural disaster affected home'],
  [FeatureOptionFeatureOptionCode.HolidayTravel, 'Holiday / Travel'],
  [FeatureOptionFeatureOptionCode.OtherPurpose, 'Other purpose'],
  [FeatureOptionFeatureOptionCode.PurchaseVehicle, 'Purchase vehicle'],
  [FeatureOptionFeatureOptionCode.PurchaseOfDwelling, 'Purchase of dwelling'],
  [
    FeatureOptionFeatureOptionCode.RefinanceOfLiabilitiesWithCurrentLender,
    'Refinance of liabilities with current lender',
  ],
  [
    FeatureOptionFeatureOptionCode.RefinanceOfLiabilitiesWithOtherLenders,
    'Refinance of liabilities with other lenders',
  ],
  [FeatureOptionFeatureOptionCode.RenovationsAndExtensions, 'Renovations and extensions'],
  [FeatureOptionFeatureOptionCode.Preliminary_VariableRate, 'Variable rate'],
  [FeatureOptionFeatureOptionCode.Preliminary_FixedRate, 'Fixed rate'],
  [FeatureOptionFeatureOptionCode.Preliminary_BasicVariableRate, 'Basic variable rate'],
  [FeatureOptionFeatureOptionCode.Preliminary_OneYear, 'One year'],
  [FeatureOptionFeatureOptionCode.Preliminary_TwoYears, 'Two years'],
  [FeatureOptionFeatureOptionCode.Preliminary_ThreeYears, 'Three years'],
  [FeatureOptionFeatureOptionCode.Preliminary_FourYears, 'Four years'],
  [FeatureOptionFeatureOptionCode.Preliminary_FiveYears, 'Five years'],
  [FeatureOptionFeatureOptionCode.Preliminary_SixYears, 'Six years'],
  [FeatureOptionFeatureOptionCode.Preliminary_SevenYears, 'Seven years'],
  [FeatureOptionFeatureOptionCode.Preliminary_EightYears, 'Eight years'],
  [FeatureOptionFeatureOptionCode.Preliminary_NineYears, 'Nine years'],
  [FeatureOptionFeatureOptionCode.Preliminary_TenYears, 'Ten years'],
  [FeatureOptionFeatureOptionCode.Preliminary_SixMonths, 'Six months'],
  [FeatureOptionFeatureOptionCode.Preliminary_EighteenMonths, 'Eighteen months'],
  [FeatureOptionFeatureOptionCode.PreliminaryFixed_PrincipalAndInterest, 'Principal and interest'],
  [FeatureOptionFeatureOptionCode.PreliminaryFixed_InterestOnly, 'Interest only'],
  [FeatureOptionFeatureOptionCode.PreliminaryFixed_PrincipalAndInterestFees, 'Principal and interest fees'],
  [FeatureOptionFeatureOptionCode.PreliminaryFixed_InterestCapitalised, 'Interest capitalised'],
  [FeatureOptionFeatureOptionCode.PreliminaryFixed_InterestInAdvance, 'Interest in advance'],
  [FeatureOptionFeatureOptionCode.Other, 'Other'],
]);

export enum FeatureTypeStatus {
  Active = 1,
  Inactive = 2,
}

export const FeatureTypeStatusLabel = new Map<number, string>([
  [FeatureTypeStatus.Active, 'Active'],
  [FeatureTypeStatus.Inactive, 'Inactive'],
]);

export enum FeatureTypeMode {
  PercentageRange = 1,
  AmountRange = 2,
  SelectionOnly = 3,
  SelectionWithValue = 4,
  SelectionWithoutValue = 5,
  SelectionFromEnum = 6,
  SelectionFromList = 7,
}

export const FeatureTypeModeLabel = new Map<number, string>([
  [FeatureTypeMode.PercentageRange, 'PercentageRange'],
  [FeatureTypeMode.AmountRange, 'AmountRange'],
  [FeatureTypeMode.SelectionOnly, 'SelectionOnly'],
  [FeatureTypeMode.SelectionWithValue, 'SelectionWithValue'],
  [FeatureTypeMode.SelectionWithoutValue, 'SelectionWithoutValue'],
  [FeatureTypeMode.SelectionFromEnum, 'SelectionFromEnum'],
  [FeatureTypeMode.SelectionFromList, 'SelectionFromList'],
]);

export enum FeatureTypeFeatureCode {
  LVR = 1,
  LoanAmount = 2,
  LoanPurposePrimary = 3,
  LoanPurposeAbs = 4,
  InterestType = 5,
  RepaymentOption = 6,
  SecurityPriority = 7,
  RepaymentFrequency = 9,
  LoanTerm = 10,
  StandardFeaturesWithoutValue = 11,
  StandardFeaturesWithValue = 12,
  State = 13,
  Postcode = 14,
  FixedTerm = 15,
  LoanReason = 16,
  LoanType = 17,
  BorrowerType = 18,
  DocumentationType = 19,
  FixedTermRepaymentOption = 20,
  Security = 21,
  FixedTermInterestType = 22,
  Residency = 23,
  CreditHistory = 24,
  RepaymentMethod = 25,
  PurposeOfFunds = 26,
  PreliminaryInterestType = 27,
  PreliminaryFixedTerm = 28,
  PreliminaryFixedTermRepaymentOption = 29,
  Other = 0,
}

export const FeatureTypeFeatureCodeLabel = new Map<number, string>([
  [FeatureTypeFeatureCode.LVR, 'LVR'],
  [FeatureTypeFeatureCode.LoanAmount, 'Loan amount'],
  [FeatureTypeFeatureCode.LoanPurposePrimary, 'Loan purpose - primary'],
  [FeatureTypeFeatureCode.LoanPurposeAbs, 'Loan purpose - ABS'],
  [FeatureTypeFeatureCode.InterestType, 'Interest type'],
  [FeatureTypeFeatureCode.RepaymentOption, 'Repayment option'],
  [FeatureTypeFeatureCode.SecurityPriority, 'Security priority'],
  [FeatureTypeFeatureCode.RepaymentFrequency, 'Repayment frequency'],
  [FeatureTypeFeatureCode.LoanTerm, 'LoanTerm'],
  [FeatureTypeFeatureCode.StandardFeaturesWithoutValue, 'Standard features without value'],
  [FeatureTypeFeatureCode.StandardFeaturesWithValue, 'Standard features with value'],
  [FeatureTypeFeatureCode.State, 'State'],
  [FeatureTypeFeatureCode.Postcode, 'Post code'],
  [FeatureTypeFeatureCode.FixedTerm, 'Fixed term'],
  [FeatureTypeFeatureCode.LoanReason, 'Loan reason'],
  [FeatureTypeFeatureCode.LoanType, 'Loan type'],
  [FeatureTypeFeatureCode.BorrowerType, 'Borrower type'],
  [FeatureTypeFeatureCode.DocumentationType, 'Documentation type'],
  [FeatureTypeFeatureCode.FixedTermRepaymentOption, 'Fixed term repayment option'],
  [FeatureTypeFeatureCode.Security, 'Security'],
  [FeatureTypeFeatureCode.FixedTermInterestType, 'Fixed term interest type'],
  [FeatureTypeFeatureCode.Residency, 'Residency'],
  [FeatureTypeFeatureCode.CreditHistory, 'Credit history'],
  [FeatureTypeFeatureCode.RepaymentMethod, 'Repayment method'],
  [FeatureTypeFeatureCode.PurposeOfFunds, 'Purpose of funds (NZ)'],
  [FeatureTypeFeatureCode.PreliminaryInterestType, 'Preliminary interest type'],
  [FeatureTypeFeatureCode.PreliminaryFixedTerm, 'Preliminary fixed term'],
  [FeatureTypeFeatureCode.PreliminaryFixedTermRepaymentOption, 'Preliminary fixed term repayment option'],
  [FeatureTypeFeatureCode.Other, 'Other'],
]);

export enum FeeStatus {
  Active = 1,
  Inactive = 2,
}

export const FeeStatusLabel = new Map<number, string>([
  [FeeStatus.Active, 'Active'],
  [FeeStatus.Inactive, 'Inactive'],
]);

export enum FeeCalculationType {
  Amount = 1,
  Percentage = 2,
  AtCost = 3,
}

export const FeeCalculationTypeLabel = new Map<number, string>([
  [FeeCalculationType.Amount, 'Amount'],
  [FeeCalculationType.Percentage, 'Percentage'],
  [FeeCalculationType.AtCost, 'At cost'],
]);

export enum FeeTypeStatus {
  Active = 1,
  Inactive = 2,
}

export const FeeTypeStatusLabel = new Map<number, string>([
  [FeeTypeStatus.Active, 'Active'],
  [FeeTypeStatus.Inactive, 'Inactive'],
]);

export enum FeeTypeWhenPaid {
  Establishment = 1,
  Discharge = 2,
  Monthly = 3,
  Yearly = 4,
  Quarterly = 5,
  SemiAnnually = 6,
  Fortnightly = 7,
  Weekly = 11,
  OnceOff = 8,
  PerTransaction = 9,
  PerSplit = 10,
}

export const FeeTypeWhenPaidLabel = new Map<number, string>([
  [FeeTypeWhenPaid.Establishment, 'Establishment'],
  [FeeTypeWhenPaid.Discharge, 'Discharge'],
  [FeeTypeWhenPaid.Monthly, 'Month'],
  [FeeTypeWhenPaid.Yearly, 'Year'],
  [FeeTypeWhenPaid.Quarterly, 'Quarter'],
  [FeeTypeWhenPaid.SemiAnnually, 'Six Months'],
  [FeeTypeWhenPaid.Fortnightly, 'Fortnight'],
  [FeeTypeWhenPaid.Weekly, 'Week'],
  [FeeTypeWhenPaid.OnceOff, 'Once Off'],
  [FeeTypeWhenPaid.PerTransaction, 'Transaction'],
  [FeeTypeWhenPaid.PerSplit, 'Split'],
]);

export enum FeeTypeFeeCode {
  AnnualAdminFee = 1,
  AnnualFee = 2,
  AnnualPackageFee = 3,
  MonthlyAdminFee = 4,
  MonthlyFee = 5,
  MonthlyServiceFee = 6,
  OnceOffApplicationFee = 7,
  OnceOffDischargeFee = 8,
  OnceOffFee = 9,
  OnceOffGuarantorFee = 10,
  OnceOffLegalFees = 11,
  OnceOffSettlementFee = 12,
  OnceOffValuationFee = 13,
  QuartelyFee = 14,
  SemiAnnualFee = 15,
  PrintedStatementFee = 16,
  TopUpFee = 17,
  SwitchingFee = 18,
  BreakFee = 19,
  ExtraValuationFee = 20,
  SettlementFee = 21,
  EarlyRepaymentTerminationFee = 22,
  RateLockFee = 23,
  SplitLoanFee = 24,
  AdditionalSecurityFee = 25,
  DocumentVariationFee = 26,
  ConstructionLoanFee = 27,
  CompanyTrustFee = 28,
  Other = 0,
}

export const FeeTypeFeeCodeLabel = new Map<number, string>([
  [FeeTypeFeeCode.AnnualAdminFee, 'Ongoing annual admin fee'],
  [FeeTypeFeeCode.AnnualFee, 'Ongoing annual fee'],
  [FeeTypeFeeCode.AnnualPackageFee, 'Ongoing annual package fee'],
  [FeeTypeFeeCode.MonthlyAdminFee, 'Ongoing monthly admin fee'],
  [FeeTypeFeeCode.MonthlyFee, 'Ongoing monthly fee'],
  [FeeTypeFeeCode.MonthlyServiceFee, 'Ongoing monthly service fee'],
  [FeeTypeFeeCode.OnceOffApplicationFee, 'Application fee'],
  [FeeTypeFeeCode.OnceOffDischargeFee, 'Discharge fee'],
  [FeeTypeFeeCode.OnceOffFee, 'Once off fee'],
  [FeeTypeFeeCode.OnceOffGuarantorFee, 'Guarantor fee'],
  [FeeTypeFeeCode.OnceOffLegalFees, 'Legal fees'],
  [FeeTypeFeeCode.OnceOffSettlementFee, 'Settlement processing fee - new loan'],
  [FeeTypeFeeCode.OnceOffValuationFee, 'Valuation fee'],
  [FeeTypeFeeCode.QuartelyFee, 'Ongoing quarterly fee'],
  [FeeTypeFeeCode.SemiAnnualFee, 'Ongoing semi-annual fee'],
  [FeeTypeFeeCode.PrintedStatementFee, 'Printed statement fee'],
  [FeeTypeFeeCode.TopUpFee, 'Top up fee'],
  [FeeTypeFeeCode.SwitchingFee, 'Switching fee'],
  [FeeTypeFeeCode.BreakFee, 'Break fee'],
  [FeeTypeFeeCode.ExtraValuationFee, 'Extra valuation fee'],
  [FeeTypeFeeCode.SettlementFee, 'Settlement fee'],
  [FeeTypeFeeCode.EarlyRepaymentTerminationFee, 'Early repayment/termination fee'],
  [FeeTypeFeeCode.RateLockFee, 'Rate lock fee'],
  [FeeTypeFeeCode.SplitLoanFee, 'Split loan fee'],
  [FeeTypeFeeCode.AdditionalSecurityFee, 'Additional security fee'],
  [FeeTypeFeeCode.DocumentVariationFee, 'Document variation fee'],
  [FeeTypeFeeCode.ConstructionLoanFee, 'Construction loan Fee'],
  [FeeTypeFeeCode.CompanyTrustFee, 'Company or trust fee'],
  [FeeTypeFeeCode.Other, 'Other'],
]);

export enum FeeViewStatus {
  Active = 1,
  Inactive = 2,
}

export const FeeViewStatusLabel = new Map<number, string>([
  [FeeViewStatus.Active, 'Active'],
  [FeeViewStatus.Inactive, 'Inactive'],
]);

export enum FullPermissionType {
  YesNo = 1,
  ReadWrite = 2,
}

export const FullPermissionTypeLabel = new Map<number, string>([
  [FullPermissionType.YesNo, 'Yes / No'],
  [FullPermissionType.ReadWrite, 'Read / Write'],
]);

export enum FullPermissionScope {
  Global = 1,
  Hierarchical = 2,
}

export const FullPermissionScopeLabel = new Map<number, string>([
  [FullPermissionScope.Global, 'Global'],
  [FullPermissionScope.Hierarchical, 'Hierarchical'],
]);

export enum GroupStatus {
  Active = 1,
  Inactive = 2,
}

export const GroupStatusLabel = new Map<number, string>([
  [GroupStatus.Active, 'Active'],
  [GroupStatus.Inactive, 'Inactive'],
]);

export enum InfoRequestStatus {
  New = 1,
  Open = 2,
  Replied = 3,
  Declined = 4,
  Complete = 5,
  Cancelled = 6,
  Suspended = 7,
}

export const InfoRequestStatusLabel = new Map<number, string>([
  [InfoRequestStatus.New, 'New'],
  [InfoRequestStatus.Open, 'Open'],
  [InfoRequestStatus.Replied, 'Replied'],
  [InfoRequestStatus.Declined, 'Declined'],
  [InfoRequestStatus.Complete, 'Complete'],
  [InfoRequestStatus.Cancelled, 'Cancelled'],
  [InfoRequestStatus.Suspended, 'Suspended'],
]);

export enum InfoRequestType {
  Information = 1,
  DocumentUpload = 2,
}

export const InfoRequestTypeLabel = new Map<number, string>([
  [InfoRequestType.Information, 'Information'],
  [InfoRequestType.DocumentUpload, 'Document Upload'],
]);

export enum InfoRequestLogRequestAction {
  Create = 1,
  Open = 2,
  Reply = 3,
  Decline = 4,
  Close = 5,
  Cancel = 6,
  Suspend = 7,
  Nominate = 8,
  SendDocsAndClose = 9,
  Resend = 10,
  Reactivate = 11,
  ResendOpenRequest = 12,
}

export const InfoRequestLogRequestActionLabel = new Map<number, string>([
  [InfoRequestLogRequestAction.Create, 'Create'],
  [InfoRequestLogRequestAction.Open, 'Open'],
  [InfoRequestLogRequestAction.Reply, 'Reply'],
  [InfoRequestLogRequestAction.Decline, 'Decline'],
  [InfoRequestLogRequestAction.Close, 'Close'],
  [InfoRequestLogRequestAction.Cancel, 'Cancel'],
  [InfoRequestLogRequestAction.Suspend, 'Suspend'],
  [InfoRequestLogRequestAction.Nominate, 'Nominate'],
  [InfoRequestLogRequestAction.SendDocsAndClose, 'SendDocsAndClose'],
  [InfoRequestLogRequestAction.Resend, 'Resend'],
  [InfoRequestLogRequestAction.Reactivate, 'Reactivate'],
  [InfoRequestLogRequestAction.ResendOpenRequest, 'ResendOpenRequest'],
]);

export enum InfoRequestResponseStatus {
  Inprogress = 1,
  Completed = 2,
}

export const InfoRequestResponseStatusLabel = new Map<number, string>([
  [InfoRequestResponseStatus.Inprogress, 'Inprogress'],
  [InfoRequestResponseStatus.Completed, 'Completed'],
]);

export enum InfoRequestResponseDocumentStatus {
  Active = 1,
}

export const InfoRequestResponseDocumentStatusLabel = new Map<number, string>([
  [InfoRequestResponseDocumentStatus.Active, 'Active'],
]);

export enum InfoRequestTemplateDetailStatus {
  Active = 1,
}

export const InfoRequestTemplateDetailStatusLabel = new Map<number, string>([
  [InfoRequestTemplateDetailStatus.Active, 'Active'],
]);

export enum InfoRequestTemplateDetailProvidedByPersonType {
  Unspecified = 0,
  Administrator = 1,
  Broker = 2,
  TeamLead = 3,
  CreditAssessor = 4,
  LoanOfficer = 6,
  MobileLender = 7,
  Manager = 8,
  SeniorAssessor = 9,
  Lawyer = 10,
  Accountant = 11,
  Advisor = 12,
  Assessor = 13,
  Valuer = 14,
  ProductManager = 15,
  PrimaryApplicant = 16,
  CoApplicant = 17,
  AllApplicants = 18,
  SalesAssessmentOfficer = 1016,
  PropertyDeveloper = 1017,
  MortgageManager = 1018,
  Settlements = 1019,
  BusinessDevelopmentManager = 1020,
  BrokerAdministration = 1021,
  ProductTeam = 1022,
  ProjectTeam = 1023,
  DistributionTeam = 1024,
  MortgageServicesTeamLeader = 1025,
  MortgageServicesTeamMember = 1026,
  QualityAssuranceTeamMember = 1027,
  MortgageInsurer = 1029,
  PackagingOfficer = 1030,
  ReadOnly = 1031,
  Translator = 1032,
  RetentionOfficer = 1033,
  BrokerSupportOfficer = 1034,
  SupportStaff = 1035,
  BrokerageManager = 1036,
  LoanSpecialist = 1037,
  SalesSupportOfficer = 1038,
  UnknownEntity = 1039,
  Solicitor = 1040,
  CreditTeamLead = 1041,
  Assistant = 1042,
  LoanWriterAdminAssistant = 1043,
  Supervisor = 1044,
  SeniorManager = 1045,
  InternalLoanWriter = 1046,
  FraudManager = 1047,
  Conveyencer = 1048,
  ServiceabilityManager = 1049,
  PASAASManager = 1050,
  SystemManager = 1051,
  UserManagement = 1052,
  Franchise = 1053,
  BusinessManager = 1054,
  AccreditedAdministrator = 1055,
  FranchiseAdministrator = 1056,
  LoanWriter = 1057,
  Partner = 1058,
  LMISetupAdmin = 1059,
}

export const InfoRequestTemplateDetailProvidedByPersonTypeLabel = new Map<number, string>([
  [InfoRequestTemplateDetailProvidedByPersonType.Unspecified, 'Unspecified'],
  [InfoRequestTemplateDetailProvidedByPersonType.Administrator, 'Administrator'],
  [InfoRequestTemplateDetailProvidedByPersonType.Broker, 'Broker'],
  [InfoRequestTemplateDetailProvidedByPersonType.TeamLead, 'Team Lead'],
  [InfoRequestTemplateDetailProvidedByPersonType.CreditAssessor, 'Credit Assessor'],
  [InfoRequestTemplateDetailProvidedByPersonType.LoanOfficer, 'Loan Officer'],
  [InfoRequestTemplateDetailProvidedByPersonType.MobileLender, 'Mobile Lender'],
  [InfoRequestTemplateDetailProvidedByPersonType.Manager, 'Manager'],
  [InfoRequestTemplateDetailProvidedByPersonType.SeniorAssessor, 'Senior Assessor'],
  [InfoRequestTemplateDetailProvidedByPersonType.Lawyer, 'Lawyer'],
  [InfoRequestTemplateDetailProvidedByPersonType.Accountant, 'Accountant'],
  [InfoRequestTemplateDetailProvidedByPersonType.Advisor, 'Advisor'],
  [InfoRequestTemplateDetailProvidedByPersonType.Assessor, 'Assessor'],
  [InfoRequestTemplateDetailProvidedByPersonType.Valuer, 'Valuer'],
  [InfoRequestTemplateDetailProvidedByPersonType.ProductManager, 'Product Manager'],
  [InfoRequestTemplateDetailProvidedByPersonType.PrimaryApplicant, 'Primary Applicant'],
  [InfoRequestTemplateDetailProvidedByPersonType.CoApplicant, 'Co-Applicant'],
  [InfoRequestTemplateDetailProvidedByPersonType.AllApplicants, 'All applicants'],
  [InfoRequestTemplateDetailProvidedByPersonType.SalesAssessmentOfficer, 'Sales Assessment Officer'],
  [InfoRequestTemplateDetailProvidedByPersonType.PropertyDeveloper, 'Property Developer'],
  [InfoRequestTemplateDetailProvidedByPersonType.MortgageManager, 'Mortgage Manager'],
  [InfoRequestTemplateDetailProvidedByPersonType.Settlements, 'Settlements'],
  [InfoRequestTemplateDetailProvidedByPersonType.BusinessDevelopmentManager, 'Business Development Manager'],
  [InfoRequestTemplateDetailProvidedByPersonType.BrokerAdministration, 'Broker Administration'],
  [InfoRequestTemplateDetailProvidedByPersonType.ProductTeam, 'Product Team'],
  [InfoRequestTemplateDetailProvidedByPersonType.ProjectTeam, 'Project Team'],
  [InfoRequestTemplateDetailProvidedByPersonType.DistributionTeam, 'Distribution Team'],
  [InfoRequestTemplateDetailProvidedByPersonType.MortgageServicesTeamLeader, 'Mortgage Services Team Leader'],
  [InfoRequestTemplateDetailProvidedByPersonType.MortgageServicesTeamMember, 'Mortgage Services Team Member'],
  [InfoRequestTemplateDetailProvidedByPersonType.QualityAssuranceTeamMember, 'Quality Assurance Team Member'],
  [InfoRequestTemplateDetailProvidedByPersonType.MortgageInsurer, 'Mortgage Insurer'],
  [InfoRequestTemplateDetailProvidedByPersonType.PackagingOfficer, 'Packaging Officer'],
  [InfoRequestTemplateDetailProvidedByPersonType.ReadOnly, 'Read Only'],
  [InfoRequestTemplateDetailProvidedByPersonType.Translator, 'Translator'],
  [InfoRequestTemplateDetailProvidedByPersonType.RetentionOfficer, 'Retention Officer'],
  [InfoRequestTemplateDetailProvidedByPersonType.BrokerSupportOfficer, 'Broker Support Officer'],
  [InfoRequestTemplateDetailProvidedByPersonType.SupportStaff, 'Support Staff'],
  [InfoRequestTemplateDetailProvidedByPersonType.BrokerageManager, 'Brokerage Manager'],
  [InfoRequestTemplateDetailProvidedByPersonType.LoanSpecialist, 'Loan Specialist'],
  [InfoRequestTemplateDetailProvidedByPersonType.SalesSupportOfficer, 'Sales Support Officer'],
  [InfoRequestTemplateDetailProvidedByPersonType.UnknownEntity, 'Unknown Entity'],
  [InfoRequestTemplateDetailProvidedByPersonType.Solicitor, 'Solicitor'],
  [InfoRequestTemplateDetailProvidedByPersonType.CreditTeamLead, 'Credit Team Lead'],
  [InfoRequestTemplateDetailProvidedByPersonType.Assistant, 'Assistant'],
  [InfoRequestTemplateDetailProvidedByPersonType.LoanWriterAdminAssistant, 'Loan Writer Admin Assistant'],
  [InfoRequestTemplateDetailProvidedByPersonType.Supervisor, 'Supervisor'],
  [InfoRequestTemplateDetailProvidedByPersonType.SeniorManager, 'Senior Manager'],
  [InfoRequestTemplateDetailProvidedByPersonType.InternalLoanWriter, 'Internal Loan Writer'],
  [InfoRequestTemplateDetailProvidedByPersonType.FraudManager, 'Fraud Manager'],
  [InfoRequestTemplateDetailProvidedByPersonType.Conveyencer, 'Conveyencer'],
  [InfoRequestTemplateDetailProvidedByPersonType.ServiceabilityManager, 'Serviceability Manager'],
  [InfoRequestTemplateDetailProvidedByPersonType.PASAASManager, 'PASAAS Manager'],
  [InfoRequestTemplateDetailProvidedByPersonType.SystemManager, 'System Manager'],
  [InfoRequestTemplateDetailProvidedByPersonType.UserManagement, 'User Management'],
  [InfoRequestTemplateDetailProvidedByPersonType.Franchise, 'Franchise'],
  [InfoRequestTemplateDetailProvidedByPersonType.BusinessManager, 'Business Manager'],
  [InfoRequestTemplateDetailProvidedByPersonType.AccreditedAdministrator, 'Accredited Administrator'],
  [InfoRequestTemplateDetailProvidedByPersonType.FranchiseAdministrator, 'Franchise Administrator'],
  [InfoRequestTemplateDetailProvidedByPersonType.LoanWriter, 'Loan Writer'],
  [InfoRequestTemplateDetailProvidedByPersonType.Partner, 'Partner'],
  [InfoRequestTemplateDetailProvidedByPersonType.LMISetupAdmin, 'LMI Setup Admin'],
]);

export enum InstructionTemplateStatus {
  Active = 1,
  Inactive = 2,
}

export const InstructionTemplateStatusLabel = new Map<number, string>([
  [InstructionTemplateStatus.Active, 'Active'],
  [InstructionTemplateStatus.Inactive, 'Inactive'],
]);

export enum InsurerMasterStatus {
  Active = 1,
  Inactive = 2,
}

export const InsurerMasterStatusLabel = new Map<number, string>([
  [InsurerMasterStatus.Active, 'Active'],
  [InsurerMasterStatus.Inactive, 'Inactive'],
]);

export enum InterestFeatureOptionStatus {
  Active = 1,
  Inactive = 2,
}

export const InterestFeatureOptionStatusLabel = new Map<number, string>([
  [InterestFeatureOptionStatus.Active, 'Active'],
  [InterestFeatureOptionStatus.Inactive, 'Inactive'],
]);

export enum InterestFeatureTypeStatus {
  Active = 1,
  Inactive = 2,
}

export const InterestFeatureTypeStatusLabel = new Map<number, string>([
  [InterestFeatureTypeStatus.Active, 'Active'],
  [InterestFeatureTypeStatus.Inactive, 'Inactive'],
]);

export enum InterestFeatureTypeMode {
  PercentageRange = 1,
  AmountRange = 2,
  Selection = 3,
}

export const InterestFeatureTypeModeLabel = new Map<number, string>([
  [InterestFeatureTypeMode.PercentageRange, 'PercentageRange'],
  [InterestFeatureTypeMode.AmountRange, 'AmountRange'],
  [InterestFeatureTypeMode.Selection, 'Selection'],
]);

export enum JourneyActionProcessSetupStatus {
  Active = 1,
}

export const JourneyActionProcessSetupStatusLabel = new Map<number, string>([
  [JourneyActionProcessSetupStatus.Active, 'Active'],
]);

export enum JourneyProductSetupStatus {
  Active = 1,
  Inactive = 2,
}

export const JourneyProductSetupStatusLabel = new Map<number, string>([
  [JourneyProductSetupStatus.Active, 'Active'],
  [JourneyProductSetupStatus.Inactive, 'Inactive'],
]);

export enum JourneyRulesetSetupStatus {
  Active = 1,
  Inactive = 2,
}

export const JourneyRulesetSetupStatusLabel = new Map<number, string>([
  [JourneyRulesetSetupStatus.Active, 'Active'],
  [JourneyRulesetSetupStatus.Inactive, 'Inactive'],
]);

export enum JourneyServiceabilitySetupStatus {
  Active = 1,
  Inactive = 2,
}

export const JourneyServiceabilitySetupStatusLabel = new Map<number, string>([
  [JourneyServiceabilitySetupStatus.Active, 'Active'],
  [JourneyServiceabilitySetupStatus.Inactive, 'Inactive'],
]);

export enum JourneyServiceabilitySetupUsageOption {
  Own = 1,
  FromProduct = 2,
}

export const JourneyServiceabilitySetupUsageOptionLabel = new Map<number, string>([
  [JourneyServiceabilitySetupUsageOption.Own, 'Own'],
  [JourneyServiceabilitySetupUsageOption.FromProduct, 'FromProduct'],
]);

export enum LenderAccreditationStatus {
  Active = 1,
  Inactive = 2,
}

export const LenderAccreditationStatusLabel = new Map<number, string>([
  [LenderAccreditationStatus.Active, 'Active'],
  [LenderAccreditationStatus.Inactive, 'Inactive'],
]);

export enum LenderMasterStatus {
  Active = 1,
  Inactive = 2,
}

export const LenderMasterStatusLabel = new Map<number, string>([
  [LenderMasterStatus.Active, 'Active'],
  [LenderMasterStatus.Inactive, 'Inactive'],
]);

export enum LevyType {
  MedicareLevySurcharge = 1,
  ACCLevy = 2,
  MedicareLevy = 3,
}

export const LevyTypeLabel = new Map<number, string>([
  [LevyType.MedicareLevySurcharge, 'Medicare levy surcharge'],
  [LevyType.ACCLevy, 'ACC levy'],
  [LevyType.MedicareLevy, 'Medicare levy'],
]);

export enum LoanApplicationSubmitToLenderStatus {
  Submitting = 1,
  Submitted = 2,
  FailedToSubmit = 3,
}

export const LoanApplicationSubmitToLenderStatusLabel = new Map<number, string>([
  [LoanApplicationSubmitToLenderStatus.Submitting, 'Submitting to lender'],
  [LoanApplicationSubmitToLenderStatus.Submitted, 'Submitted to lender'],
  [LoanApplicationSubmitToLenderStatus.FailedToSubmit, 'Failed to submit to lender'],
]);

export enum LoanApplicationStatus {
  Open = 1,
  Waiting = 2,
  Assessment = 3,
  Decision = 4,
  Solicitors = 5,
  Finalised = 6,
}

export const LoanApplicationStatusLabel = new Map<number, string>([
  [LoanApplicationStatus.Open, 'Open'],
  [LoanApplicationStatus.Waiting, 'Waiting'],
  [LoanApplicationStatus.Assessment, 'Assessment'],
  [LoanApplicationStatus.Decision, 'Decision'],
  [LoanApplicationStatus.Solicitors, 'Solicitors'],
  [LoanApplicationStatus.Finalised, 'Finalised'],
]);

export enum LoanApplicationCommunicationOption {
  Option1 = 1,
  Option2 = 2,
  Option3 = 3,
  Option4 = 4,
  Option5 = 5,
  Option6 = 6,
}

export const LoanApplicationCommunicationOptionLabel = new Map<number, string>([
  [LoanApplicationCommunicationOption.Option1, 'Direct to applicant, use broker branding, notify broker'],
  [LoanApplicationCommunicationOption.Option2, 'Notify broker only, broker branding'],
  [LoanApplicationCommunicationOption.Option3, 'Direct to applicant, use lender, notify broker'],
  [LoanApplicationCommunicationOption.Option4, 'Notify broker only, lender branding'],
  [LoanApplicationCommunicationOption.Option5, 'Direct to applicant, use channel branding'],
  [LoanApplicationCommunicationOption.Option6, 'Notify broker only, channel branding'],
]);

export enum LoanApplicationBrandingOption {
  CurrentCoordinator = 1,
  Channel = 2,
  Broker = 3,
}

export const LoanApplicationBrandingOptionLabel = new Map<number, string>([
  [LoanApplicationBrandingOption.CurrentCoordinator, 'Current co-ordinator'],
  [LoanApplicationBrandingOption.Channel, 'Channel'],
  [LoanApplicationBrandingOption.Broker, 'Broker'],
]);

export enum LoanApplicationInfoRequestTemplateStatus {
  Active = 1,
  Inactive = 2,
}

export const LoanApplicationInfoRequestTemplateStatusLabel = new Map<number, string>([
  [LoanApplicationInfoRequestTemplateStatus.Active, 'Active'],
  [LoanApplicationInfoRequestTemplateStatus.Inactive, 'Inactive'],
]);

export enum LoanInsuranceCalculationStatus {
  Open = 1,
  Closed = 2,
}

export const LoanInsuranceCalculationStatusLabel = new Map<number, string>([
  [LoanInsuranceCalculationStatus.Open, 'Open'],
  [LoanInsuranceCalculationStatus.Closed, 'Closed'],
]);

export enum LoanInsuranceCalculatorStatus {
  Active = 1,
  Inactive = 2,
}

export const LoanInsuranceCalculatorStatusLabel = new Map<number, string>([
  [LoanInsuranceCalculatorStatus.Active, 'Active'],
  [LoanInsuranceCalculatorStatus.Inactive, 'Inactive'],
]);

export enum LoanInsuranceCalculatorConfigurationStatus {
  Active = 1,
  Inactive = 2,
}

export const LoanInsuranceCalculatorConfigurationStatusLabel = new Map<number, string>([
  [LoanInsuranceCalculatorConfigurationStatus.Active, 'Active'],
  [LoanInsuranceCalculatorConfigurationStatus.Inactive, 'Inactive'],
]);

export enum LoanInsuranceCalculatorLoadingLoadingType {
  Geographic = 1,
  Investment = 2,
}

export const LoanInsuranceCalculatorLoadingLoadingTypeLabel = new Map<number, string>([
  [LoanInsuranceCalculatorLoadingLoadingType.Geographic, 'Geographic'],
  [LoanInsuranceCalculatorLoadingLoadingType.Investment, 'Investment'],
]);

export enum LoanInsuranceCalculatorVersionStatus {
  Draft = 1,
  Release = 2,
  Obsolete = 3,
}

export const LoanInsuranceCalculatorVersionStatusLabel = new Map<number, string>([
  [LoanInsuranceCalculatorVersionStatus.Draft, 'Draft'],
  [LoanInsuranceCalculatorVersionStatus.Release, 'Release'],
  [LoanInsuranceCalculatorVersionStatus.Obsolete, 'Obsolete'],
]);

export enum LoyaltyPackageStatus {
  Active = 1,
  Inactive = 2,
}

export const LoyaltyPackageStatusLabel = new Map<number, string>([
  [LoyaltyPackageStatus.Active, 'Active'],
  [LoyaltyPackageStatus.Inactive, 'Inactive'],
]);

export enum MachineLearningTestRigStatus {
  Active = 1,
  Inactive = 2,
  New = 3,
  Processed = 4,
  Confirmed = 5,
}

export const MachineLearningTestRigStatusLabel = new Map<number, string>([
  [MachineLearningTestRigStatus.Active, 'Active'],
  [MachineLearningTestRigStatus.Inactive, 'Inactive'],
  [MachineLearningTestRigStatus.New, 'New'],
  [MachineLearningTestRigStatus.Processed, 'Processed'],
  [MachineLearningTestRigStatus.Confirmed, 'Confirmed'],
]);

export enum MachineLearningTestRigFunction {
  SignatureDetection = 1,
  DriversLicense = 2,
  Passport = 3,
  Payslip = 4,
  BankStatements = 5,
  TaxReturn = 6,
  FinancialStatements = 7,
  LetterBySubject = 8,
  OneSpan = 9,
  PBankStatements = 10,
  PMOGO = 11,
  PGreenID = 12,
  PIDMatrix = 13,
  PValex = 14,
  PQBELMI = 15,
  POneSpan = 16,
  PEquifax = 17,
  PCorelogicAVM = 18,
  PDocusign = 19,
}

export const MachineLearningTestRigFunctionLabel = new Map<number, string>([
  [MachineLearningTestRigFunction.SignatureDetection, 'AI - Signature detection'],
  [MachineLearningTestRigFunction.DriversLicense, 'AI - Drivers license'],
  [MachineLearningTestRigFunction.Passport, 'AI - Passport'],
  [MachineLearningTestRigFunction.Payslip, 'AI - Payslip'],
  [MachineLearningTestRigFunction.BankStatements, 'AI - Bank statements'],
  [MachineLearningTestRigFunction.TaxReturn, 'AI - Tax return'],
  [MachineLearningTestRigFunction.FinancialStatements, 'AI - Financial statements'],
  [MachineLearningTestRigFunction.LetterBySubject, 'AI - Letter by subject'],
  [MachineLearningTestRigFunction.OneSpan, 'OneSpan'],
  [MachineLearningTestRigFunction.PBankStatements, '3P - Bankstatements'],
  [MachineLearningTestRigFunction.PMOGO, '3P - MOGO'],
  [MachineLearningTestRigFunction.PGreenID, '3P - GreenID'],
  [MachineLearningTestRigFunction.PIDMatrix, '3P - IDMatrix'],
  [MachineLearningTestRigFunction.PValex, '3P - Valex'],
  [MachineLearningTestRigFunction.PQBELMI, '3P - QBE LMI'],
  [MachineLearningTestRigFunction.POneSpan, '3P - OneSpan'],
  [MachineLearningTestRigFunction.PEquifax, '3P - Equifax'],
  [MachineLearningTestRigFunction.PCorelogicAVM, '3P - Corelogic AVM'],
  [MachineLearningTestRigFunction.PDocusign, '3P - Docusign'],
]);

export enum MachineLearningTestRigResponseStatus {
  Success = 1,
  Failed = 2,
  Pending = 3,
}

export const MachineLearningTestRigResponseStatusLabel = new Map<number, string>([
  [MachineLearningTestRigResponseStatus.Success, 'Success'],
  [MachineLearningTestRigResponseStatus.Failed, 'Failed'],
  [MachineLearningTestRigResponseStatus.Pending, 'Pending'],
]);

export enum NewsFeedPostStatus {
  Active = 1,
  Draft = 2,
}

export const NewsFeedPostStatusLabel = new Map<number, string>([
  [NewsFeedPostStatus.Active, 'Active'],
  [NewsFeedPostStatus.Draft, 'Draft'],
]);

export enum NotificationType {
  Email = 1,
  SMS = 2,
}

export const NotificationTypeLabel = new Map<number, string>([
  [NotificationType.Email, 'Email'],
  [NotificationType.SMS, 'SMS'],
]);

export enum NotificationStatus {
  Active = 1,
  Inactive = 2,
}

export const NotificationStatusLabel = new Map<number, string>([
  [NotificationStatus.Active, 'Active'],
  [NotificationStatus.Inactive, 'Inactive'],
]);

export enum NotificationCategory {
  Applications = 1,
  Security = 2,
  System = 3,
  Omni = 4,
  Organisational = 5,
  Consumer = 6,
  PaSaaS = 7,
  Loanapp = 8,
  Serviceability = 9,
  LoanProduct = 10,
  InfoRequests = 11,
}

export const NotificationCategoryLabel = new Map<number, string>([
  [NotificationCategory.Applications, 'Applications'],
  [NotificationCategory.Security, 'Security'],
  [NotificationCategory.System, 'System'],
  [NotificationCategory.Omni, 'Omni'],
  [NotificationCategory.Organisational, 'Organisational'],
  [NotificationCategory.Consumer, 'Consumer'],
  [NotificationCategory.PaSaaS, 'PaSaaS'],
  [NotificationCategory.Loanapp, 'Loanapp'],
  [NotificationCategory.Serviceability, 'Serviceability'],
  [NotificationCategory.LoanProduct, 'LoanProduct'],
  [NotificationCategory.InfoRequests, 'InfoRequests'],
]);

export enum NotificationAttachmentStatus {
  Active = 1,
}

export const NotificationAttachmentStatusLabel = new Map<number, string>([
  [NotificationAttachmentStatus.Active, 'Active'],
]);

export enum OmniBackChannelItemStatus {
  Open = 2,
  Complete = 4,
}

export const OmniBackChannelItemStatusLabel = new Map<number, string>([
  [OmniBackChannelItemStatus.Open, 'Open'],
  [OmniBackChannelItemStatus.Complete, 'Complete'],
]);

export enum OmniBackChannelItemMessageType {
  Unknown = 0,
  Status = 1,
  Event = 2,
}

export const OmniBackChannelItemMessageTypeLabel = new Map<number, string>([
  [OmniBackChannelItemMessageType.Unknown, 'Unknown'],
  [OmniBackChannelItemMessageType.Status, 'Status'],
  [OmniBackChannelItemMessageType.Event, 'Event'],
]);

export enum OmniBackChannelMessageStatus {
  Open = 2,
  Complete = 4,
}

export const OmniBackChannelMessageStatusLabel = new Map<number, string>([
  [OmniBackChannelMessageStatus.Open, 'Open'],
  [OmniBackChannelMessageStatus.Complete, 'Complete'],
]);

export enum PasswordResetStatus {
  Created = 1,
  Used = 2,
  Expired = 3,
}

export const PasswordResetStatusLabel = new Map<number, string>([
  [PasswordResetStatus.Created, 'Created'],
  [PasswordResetStatus.Used, 'Used'],
  [PasswordResetStatus.Expired, 'Expired'],
]);

export enum PermissionType {
  YesNo = 1,
  ReadWrite = 2,
}

export const PermissionTypeLabel = new Map<number, string>([
  [PermissionType.YesNo, 'Yes / No'],
  [PermissionType.ReadWrite, 'Read / Write'],
]);

export enum PermissionScope {
  Global = 1,
  Hierarchical = 2,
}

export const PermissionScopeLabel = new Map<number, string>([
  [PermissionScope.Global, 'Global'],
  [PermissionScope.Hierarchical, 'Hierarchical'],
]);

export enum PersonType {
  Administrator = 1,
  Broker = 2,
  TeamLead = 3,
  CreditAssessor = 4,
  Consumer = 5,
  LoanOfficer = 6,
  MobileLender = 7,
  Manager = 8,
  SeniorAssessor = 9,
  Lawyer = 10,
  Accountant = 11,
  Advisor = 12,
  Assessor = 13,
  Valuer = 14,
  ProductManager = 15,
  SalesAssessmentOfficer = 16,
  PropertyDeveloper = 17,
  MortgageManager = 18,
  Settlements = 19,
  BusinessDevelopmentManager = 20,
  BrokerAdministration = 21,
  ProductTeam = 22,
  ProjectTeam = 23,
  DistributionTeam = 24,
  MortgageServicesTeamLeader = 25,
  MortgageServicesTeamMember = 26,
  QualityAssuranceTeamMember = 27,
  SimpologyAdmin = 28,
  MortgageInsurer = 29,
  PackagingOfficer = 30,
  ReadOnly = 31,
  Translator = 32,
  RetentionOfficer = 33,
  BrokerSupportOfficer = 34,
  SupportStaff = 35,
  BrokerageManager = 36,
  LoanSpecialist = 37,
  SalesSupportOfficer = 38,
  UnknownEntity = 39,
  Solicitor = 40,
  CreditTeamLead = 41,
  Assistant = 42,
  LoanWriterAdminAssistant = 43,
  Supervisor = 44,
  SeniorManager = 45,
  InternalLoanWriter = 46,
  FraudManager = 47,
  Conveyencer = 48,
  ServiceabilityManager = 49,
  PASAASManager = 50,
  SystemManager = 51,
  UserManagement = 52,
  Franchise = 53,
  BusinessManager = 54,
  AccreditedAdministrator = 55,
  FranchiseAdministrator = 56,
  LoanWriter = 57,
  Partner = 58,
  LMISetupAdmin = 59,
}

export const PersonTypeLabel = new Map<number, string>([
  [PersonType.Administrator, 'Administrator'],
  [PersonType.Broker, 'Broker'],
  [PersonType.TeamLead, 'Team Lead'],
  [PersonType.CreditAssessor, 'Credit Assessor'],
  [PersonType.Consumer, 'Consumer'],
  [PersonType.LoanOfficer, 'Loan Officer'],
  [PersonType.MobileLender, 'Mobile Lender'],
  [PersonType.Manager, 'Manager'],
  [PersonType.SeniorAssessor, 'Senior Assessor'],
  [PersonType.Lawyer, 'Lawyer'],
  [PersonType.Accountant, 'Accountant'],
  [PersonType.Advisor, 'Advisor'],
  [PersonType.Assessor, 'Assessor'],
  [PersonType.Valuer, 'Valuer'],
  [PersonType.ProductManager, 'Product Manager'],
  [PersonType.SalesAssessmentOfficer, 'Sales Assessment Officer'],
  [PersonType.PropertyDeveloper, 'Property Developer'],
  [PersonType.MortgageManager, 'Mortgage Manager'],
  [PersonType.Settlements, 'Settlements'],
  [PersonType.BusinessDevelopmentManager, 'Business Development Manager'],
  [PersonType.BrokerAdministration, 'Broker Administration'],
  [PersonType.ProductTeam, 'Product Team'],
  [PersonType.ProjectTeam, 'Project Team'],
  [PersonType.DistributionTeam, 'Distribution Team'],
  [PersonType.MortgageServicesTeamLeader, 'Mortgage Services Team Leader'],
  [PersonType.MortgageServicesTeamMember, 'Mortgage Services Team Member'],
  [PersonType.QualityAssuranceTeamMember, 'Quality Assurance Team Member'],
  [PersonType.SimpologyAdmin, 'Simpology Admin'],
  [PersonType.MortgageInsurer, 'Mortgage Insurer'],
  [PersonType.PackagingOfficer, 'Packaging Officer'],
  [PersonType.ReadOnly, 'Read Only'],
  [PersonType.Translator, 'Translator'],
  [PersonType.RetentionOfficer, 'Retention Officer'],
  [PersonType.BrokerSupportOfficer, 'Broker Support Officer'],
  [PersonType.SupportStaff, 'Support Staff'],
  [PersonType.BrokerageManager, 'Brokerage Manager'],
  [PersonType.LoanSpecialist, 'Loan Specialist'],
  [PersonType.SalesSupportOfficer, 'Sales Support Officer'],
  [PersonType.UnknownEntity, 'Unknown Entity'],
  [PersonType.Solicitor, 'Solicitor'],
  [PersonType.CreditTeamLead, 'Credit Team Lead'],
  [PersonType.Assistant, 'Assistant'],
  [PersonType.LoanWriterAdminAssistant, 'Loan Writer Admin Assistant'],
  [PersonType.Supervisor, 'Supervisor'],
  [PersonType.SeniorManager, 'Senior Manager'],
  [PersonType.InternalLoanWriter, 'Internal Loan Writer'],
  [PersonType.FraudManager, 'Fraud Manager'],
  [PersonType.Conveyencer, 'Conveyencer'],
  [PersonType.ServiceabilityManager, 'Serviceability Manager'],
  [PersonType.PASAASManager, 'PASAAS Manager'],
  [PersonType.SystemManager, 'System Manager'],
  [PersonType.UserManagement, 'User Management'],
  [PersonType.Franchise, 'Franchise'],
  [PersonType.BusinessManager, 'Business Manager'],
  [PersonType.AccreditedAdministrator, 'Accredited Administrator'],
  [PersonType.FranchiseAdministrator, 'Franchise Administrator'],
  [PersonType.LoanWriter, 'Loan Writer'],
  [PersonType.Partner, 'Partner'],
  [PersonType.LMISetupAdmin, 'LMI Setup Admin'],
]);

export enum PersonStatus {
  Active = 1,
  Inactive = 2,
  PreRegistered = 3,
  New = 4,
}

export const PersonStatusLabel = new Map<number, string>([
  [PersonStatus.Active, 'Active'],
  [PersonStatus.Inactive, 'Inactive'],
  [PersonStatus.PreRegistered, 'Pre Registered'],
  [PersonStatus.New, 'New'],
]);

export enum PersonTitle {
  Mr = 1,
  Mrs = 2,
  Ms = 3,
  Dr = 4,
}

export const PersonTitleLabel = new Map<number, string>([
  [PersonTitle.Mr, 'Mr'],
  [PersonTitle.Mrs, 'Mrs'],
  [PersonTitle.Ms, 'Ms'],
  [PersonTitle.Dr, 'Dr'],
]);

export enum PersonBrandImageBrandType {
  EmailFooter = 4,
  InfoRequest = 5,
}

export const PersonBrandImageBrandTypeLabel = new Map<number, string>([
  [PersonBrandImageBrandType.EmailFooter, 'Email footer'],
  [PersonBrandImageBrandType.InfoRequest, 'Info request'],
]);

export enum PersonOptionalGender {
  Male = 1,
  Female = 2,
  Other = 3,
}

export const PersonOptionalGenderLabel = new Map<number, string>([
  [PersonOptionalGender.Male, 'Male'],
  [PersonOptionalGender.Female, 'Female'],
  [PersonOptionalGender.Other, 'Other'],
]);

export enum PersonOptionalMaritalStatus {
  Single = 1,
  Married = 2,
  Divorced = 3,
  Widowed = 4,
}

export const PersonOptionalMaritalStatusLabel = new Map<number, string>([
  [PersonOptionalMaritalStatus.Single, 'Single'],
  [PersonOptionalMaritalStatus.Married, 'Married'],
  [PersonOptionalMaritalStatus.Divorced, 'Divorced'],
  [PersonOptionalMaritalStatus.Widowed, 'Widowed'],
]);

export enum PersonSecurityCodeType {
  Email = 1,
  SMS = 2,
}

export const PersonSecurityCodeTypeLabel = new Map<number, string>([
  [PersonSecurityCodeType.Email, 'Email'],
  [PersonSecurityCodeType.SMS, 'SMS'],
]);

export enum PersonSecurityCodeRequestSource {
  InfoRequestConsent = 1,
}

export const PersonSecurityCodeRequestSourceLabel = new Map<number, string>([
  [PersonSecurityCodeRequestSource.InfoRequestConsent, 'InfoRequestConsent'],
]);

export enum PersonWithOptionalGender {
  Male = 1,
  Female = 2,
  Other = 3,
}

export const PersonWithOptionalGenderLabel = new Map<number, string>([
  [PersonWithOptionalGender.Male, 'Male'],
  [PersonWithOptionalGender.Female, 'Female'],
  [PersonWithOptionalGender.Other, 'Other'],
]);

export enum PersonWithOptionalMaritalStatus {
  Single = 1,
  Married = 2,
  Divorced = 3,
  Widowed = 4,
}

export const PersonWithOptionalMaritalStatusLabel = new Map<number, string>([
  [PersonWithOptionalMaritalStatus.Single, 'Single'],
  [PersonWithOptionalMaritalStatus.Married, 'Married'],
  [PersonWithOptionalMaritalStatus.Divorced, 'Divorced'],
  [PersonWithOptionalMaritalStatus.Widowed, 'Widowed'],
]);

export enum PolicyDecisionGroupStatus {
  Active = 1,
}

export const PolicyDecisionGroupStatusLabel = new Map<number, string>([[PolicyDecisionGroupStatus.Active, 'Active']]);

export enum PolicyDecisionRulesetStatus {
  Active = 1,
}

export const PolicyDecisionRulesetStatusLabel = new Map<number, string>([
  [PolicyDecisionRulesetStatus.Active, 'Active'],
]);

export enum PolicyDecisionRulesetVersionStatus {
  Draft = 1,
  Current = 2,
  Obsolete = 3,
}

export const PolicyDecisionRulesetVersionStatusLabel = new Map<number, string>([
  [PolicyDecisionRulesetVersionStatus.Draft, 'Draft'],
  [PolicyDecisionRulesetVersionStatus.Current, 'Current'],
  [PolicyDecisionRulesetVersionStatus.Obsolete, 'Obsolete'],
]);

export enum ProductStatus {
  Active = 1,
  Inactive = 2,
}

export const ProductStatusLabel = new Map<number, string>([
  [ProductStatus.Active, 'Active'],
  [ProductStatus.Inactive, 'Inactive'],
]);

export enum ProductLoanType {
  OwnerOccupier = 1,
  Investment = 2,
}

export const ProductLoanTypeLabel = new Map<number, string>([
  [ProductLoanType.OwnerOccupier, 'Owner occupier'],
  [ProductLoanType.Investment, 'Investment'],
]);

export enum ProductDataMaintainedBy {
  Publisher = 1,
  Simpology = 2,
}

export const ProductDataMaintainedByLabel = new Map<number, string>([
  [ProductDataMaintainedBy.Publisher, 'Publisher'],
  [ProductDataMaintainedBy.Simpology, 'Simpology'],
]);

export enum ProductDetailStatus {
  Active = 1,
  Inactive = 2,
}

export const ProductDetailStatusLabel = new Map<number, string>([
  [ProductDetailStatus.Active, 'Active'],
  [ProductDetailStatus.Inactive, 'Inactive'],
]);

export enum ProductDetailLoanType {
  OwnerOccupier = 1,
  Investment = 2,
  Basic = 3,
  Equity = 4,
  Fixed = 5,
  InterestInAdvance = 6,
  Intro = 7,
  LowDoc = 8,
  NonConforming = 9,
  StandardVariable = 10,
}

export const ProductDetailLoanTypeLabel = new Map<number, string>([
  [ProductDetailLoanType.OwnerOccupier, 'Owner occupier'],
  [ProductDetailLoanType.Investment, 'Investment'],
  [ProductDetailLoanType.Basic, 'Basic'],
  [ProductDetailLoanType.Equity, 'Equity'],
  [ProductDetailLoanType.Fixed, 'Fixed'],
  [ProductDetailLoanType.InterestInAdvance, 'Interest in Advance'],
  [ProductDetailLoanType.Intro, 'Intro'],
  [ProductDetailLoanType.LowDoc, 'Low Doc'],
  [ProductDetailLoanType.NonConforming, 'Non Conforming'],
  [ProductDetailLoanType.StandardVariable, 'Standard Variable'],
]);

export enum ProductDocumentStatus {
  Active = 1,
  Inactive = 2,
}

export const ProductDocumentStatusLabel = new Map<number, string>([
  [ProductDocumentStatus.Active, 'Active'],
  [ProductDocumentStatus.Inactive, 'Inactive'],
]);

export enum ProductGroupStatus {
  Active = 1,
  Inactive = 2,
}

export const ProductGroupStatusLabel = new Map<number, string>([
  [ProductGroupStatus.Active, 'Active'],
  [ProductGroupStatus.Inactive, 'Inactive'],
]);

export enum ProductNoteStatus {
  Active = 1,
  Inactive = 2,
}

export const ProductNoteStatusLabel = new Map<number, string>([
  [ProductNoteStatus.Active, 'Active'],
  [ProductNoteStatus.Inactive, 'Inactive'],
]);

export enum ProductRuleStatus {
  Active = 1,
  Inactive = 2,
}

export const ProductRuleStatusLabel = new Map<number, string>([
  [ProductRuleStatus.Active, 'Active'],
  [ProductRuleStatus.Inactive, 'Inactive'],
]);

export enum ProductSpecialOfferStatus {
  Active = 1,
  Inactive = 2,
}

export const ProductSpecialOfferStatusLabel = new Map<number, string>([
  [ProductSpecialOfferStatus.Active, 'Active'],
  [ProductSpecialOfferStatus.Inactive, 'Inactive'],
]);

export enum ProductSpecificationClauseStatus {
  Active = 1,
}

export const ProductSpecificationClauseStatusLabel = new Map<number, string>([
  [ProductSpecificationClauseStatus.Active, 'Active'],
]);

export enum ProductSpecificationSectionStatus {
  Active = 1,
}

export const ProductSpecificationSectionStatusLabel = new Map<number, string>([
  [ProductSpecificationSectionStatus.Active, 'Active'],
]);

export enum ProductStandardFeeStatus {
  Active = 1,
  Inactive = 2,
}

export const ProductStandardFeeStatusLabel = new Map<number, string>([
  [ProductStandardFeeStatus.Active, 'Active'],
  [ProductStandardFeeStatus.Inactive, 'Inactive'],
]);

export enum ProductStandardFeeSetStatus {
  Draft = 1,
  Current = 2,
  Obsolete = 3,
}

export const ProductStandardFeeSetStatusLabel = new Map<number, string>([
  [ProductStandardFeeSetStatus.Draft, 'Draft'],
  [ProductStandardFeeSetStatus.Current, 'Current'],
  [ProductStandardFeeSetStatus.Obsolete, 'Obsolete'],
]);

export enum ProductSubgroupStatus {
  Active = 1,
  Inactive = 2,
}

export const ProductSubgroupStatusLabel = new Map<number, string>([
  [ProductSubgroupStatus.Active, 'Active'],
  [ProductSubgroupStatus.Inactive, 'Inactive'],
]);

export enum ProductVariationBenchmarkType {
  MaxBenchmark = 1,
  IncrementBase = 2,
  UseHigher = 3,
}

export const ProductVariationBenchmarkTypeLabel = new Map<number, string>([
  [ProductVariationBenchmarkType.MaxBenchmark, 'Use maximum benchmark'],
  [ProductVariationBenchmarkType.IncrementBase, 'Add increment to base'],
  [ProductVariationBenchmarkType.UseHigher, 'Use the higher of the above two'],
]);

export enum ProductVersionStatus {
  Draft = 1,
  Release = 2,
  Obsolete = 3,
}

export const ProductVersionStatusLabel = new Map<number, string>([
  [ProductVersionStatus.Draft, 'Draft'],
  [ProductVersionStatus.Release, 'Release'],
  [ProductVersionStatus.Obsolete, 'Obsolete'],
]);

export enum ProductVersionDocumentStatus {
  Active = 1,
  Inactive = 2,
}

export const ProductVersionDocumentStatusLabel = new Map<number, string>([
  [ProductVersionDocumentStatus.Active, 'Active'],
  [ProductVersionDocumentStatus.Inactive, 'Inactive'],
]);

export enum RequestDocumentCategoryStatus {
  Active = 1,
  Inactive = 2,
}

export const RequestDocumentCategoryStatusLabel = new Map<number, string>([
  [RequestDocumentCategoryStatus.Active, 'Active'],
  [RequestDocumentCategoryStatus.Inactive, 'Inactive'],
]);

export enum RequestDocumentCategoryRelatedTo {
  Application = 1,
  Applicant = 2,
}

export const RequestDocumentCategoryRelatedToLabel = new Map<number, string>([
  [RequestDocumentCategoryRelatedTo.Application, 'Application'],
  [RequestDocumentCategoryRelatedTo.Applicant, 'Applicant'],
]);

export enum RuleCategory {
  Product = 1,
}

export const RuleCategoryLabel = new Map<number, string>([[RuleCategory.Product, 'Product']]);

export enum RuleStatus {
  Active = 1,
  Inactive = 2,
}

export const RuleStatusLabel = new Map<number, string>([
  [RuleStatus.Active, 'Active'],
  [RuleStatus.Inactive, 'Inactive'],
]);

export enum RuleCategoryStatus {
  Active = 1,
}

export const RuleCategoryStatusLabel = new Map<number, string>([[RuleCategoryStatus.Active, 'Active']]);

export enum RuleFieldOptionStatus {
  Active = 1,
  Inactive = 2,
}

export const RuleFieldOptionStatusLabel = new Map<number, string>([
  [RuleFieldOptionStatus.Active, 'Active'],
  [RuleFieldOptionStatus.Inactive, 'Inactive'],
]);

export enum RulesEngineRuleDataSource {
  AppData = 1,
}

export const RulesEngineRuleDataSourceLabel = new Map<number, string>([[RulesEngineRuleDataSource.AppData, 'AppData']]);

export enum RulesEngineRuleTriggerEvent {
  ApplicationSubmission = 1,
  ActionItemGeneration = 2,
  DocumentChecklistGeneration = 3,
  CreditReportCheck = 4,
  VerificationOfIdCheck = 5,
  BankingData = 6,
  LenderSubmission = 7,
}

export const RulesEngineRuleTriggerEventLabel = new Map<number, string>([
  [RulesEngineRuleTriggerEvent.ApplicationSubmission, 'Application Submission'],
  [RulesEngineRuleTriggerEvent.ActionItemGeneration, 'Process and action item generation'],
  [RulesEngineRuleTriggerEvent.DocumentChecklistGeneration, 'Document Checklist Generation'],
  [RulesEngineRuleTriggerEvent.CreditReportCheck, 'Credit report check'],
  [RulesEngineRuleTriggerEvent.VerificationOfIdCheck, 'Verification of ID check'],
  [RulesEngineRuleTriggerEvent.BankingData, 'Banking data'],
  [RulesEngineRuleTriggerEvent.LenderSubmission, 'Lender submission'],
]);

export enum RulesEngineRuleRuleSection {
  ApplicationManagement = 1,
  DocumentChecklist = 2,
  DecisionSupport = 3,
}

export const RulesEngineRuleRuleSectionLabel = new Map<number, string>([
  [RulesEngineRuleRuleSection.ApplicationManagement, 'Application Management'],
  [RulesEngineRuleRuleSection.DocumentChecklist, 'Document Checklist'],
  [RulesEngineRuleRuleSection.DecisionSupport, 'Document Verification'],
]);

export enum RulesEngineRuleStatus {
  Active = 1,
  Inactive = 2,
}

export const RulesEngineRuleStatusLabel = new Map<number, string>([
  [RulesEngineRuleStatus.Active, 'Active'],
  [RulesEngineRuleStatus.Inactive, 'Inactive'],
]);

export enum RulesEngineRuleSectionViewDataSource {
  AppData = 1,
}

export const RulesEngineRuleSectionViewDataSourceLabel = new Map<number, string>([
  [RulesEngineRuleSectionViewDataSource.AppData, 'AppData'],
]);

export enum ServiceabilityCalculatorStatus {
  Active = 1,
  Inactive = 2,
}

export const ServiceabilityCalculatorStatusLabel = new Map<number, string>([
  [ServiceabilityCalculatorStatus.Active, 'Active'],
  [ServiceabilityCalculatorStatus.Inactive, 'Inactive'],
]);

export enum ServiceabilityCalculatorCalculationType {
  Annual = 1,
  Monthly = 2,
}

export const ServiceabilityCalculatorCalculationTypeLabel = new Map<number, string>([
  [ServiceabilityCalculatorCalculationType.Annual, 'Annualised amounts'],
  [ServiceabilityCalculatorCalculationType.Monthly, 'Monthly amounts'],
]);

export enum ServiceabilityCalculatorHouseholdIncomeExpenseMethod {
  GrossIncome = 1,
  GrossIncomeExcludingRental = 2,
  NetIncome = 3,
  GrossIncomeExcludingInterestExpenses = 4,
}

export const ServiceabilityCalculatorHouseholdIncomeExpenseMethodLabel = new Map<number, string>([
  [ServiceabilityCalculatorHouseholdIncomeExpenseMethod.GrossIncome, 'Gross income'],
  [ServiceabilityCalculatorHouseholdIncomeExpenseMethod.GrossIncomeExcludingRental, 'Gross income excluding rental'],
  [ServiceabilityCalculatorHouseholdIncomeExpenseMethod.NetIncome, 'Net income'],
  [
    ServiceabilityCalculatorHouseholdIncomeExpenseMethod.GrossIncomeExcludingInterestExpenses,
    'Gross income excluding liabilities interest deductions and investment costs',
  ],
]);

export enum ServiceabilityCalculatorHouseholdIncomeCalculationMethod {
  ActualHouseholdIncome = 1,
  AverageHouseholdIncome = 2,
}

export const ServiceabilityCalculatorHouseholdIncomeCalculationMethodLabel = new Map<number, string>([
  [ServiceabilityCalculatorHouseholdIncomeCalculationMethod.ActualHouseholdIncome, 'Actual household income'],
  [ServiceabilityCalculatorHouseholdIncomeCalculationMethod.AverageHouseholdIncome, 'Average household income'],
]);

export enum ServiceabilityCalculatorDocumentType {
  FullDoc = 1,
  LoDoc = 2,
  NoDoc = 3,
}

export const ServiceabilityCalculatorDocumentTypeLabel = new Map<number, string>([
  [ServiceabilityCalculatorDocumentType.FullDoc, 'Full doc'],
  [ServiceabilityCalculatorDocumentType.LoDoc, 'Lo doc'],
  [ServiceabilityCalculatorDocumentType.NoDoc, 'No doc'],
]);

export enum ServiceabilityCalculatorTaxCalculationMethod {
  FromEligibleAmount = 1,
  FromActualAmount = 2,
}

export const ServiceabilityCalculatorTaxCalculationMethodLabel = new Map<number, string>([
  [ServiceabilityCalculatorTaxCalculationMethod.FromEligibleAmount, 'From eligible amount'],
  [ServiceabilityCalculatorTaxCalculationMethod.FromActualAmount, 'From actual amount'],
]);

export enum ServiceabilityCalculatorCalculationRule {
  AUBased = 1,
  NZBased = 2,
}

export const ServiceabilityCalculatorCalculationRuleLabel = new Map<number, string>([
  [ServiceabilityCalculatorCalculationRule.AUBased, 'AU based calculation'],
  [ServiceabilityCalculatorCalculationRule.NZBased, 'NZ based calculation'],
]);

export enum ServiceabilityCalculatorNetIncomeMethod {
  Standard = 1,
  TaxRate = 2,
}

export const ServiceabilityCalculatorNetIncomeMethodLabel = new Map<number, string>([
  [ServiceabilityCalculatorNetIncomeMethod.Standard, 'Standard method'],
  [ServiceabilityCalculatorNetIncomeMethod.TaxRate, 'Tax rate method'],
]);

export enum ServiceabilityCalculatorGuarantorIncomeMethod {
  ApplicationIncome = 1,
  LendingGuaranteeIncome = 2,
}

export const ServiceabilityCalculatorGuarantorIncomeMethodLabel = new Map<number, string>([
  [ServiceabilityCalculatorGuarantorIncomeMethod.ApplicationIncome, 'Application income'],
  [ServiceabilityCalculatorGuarantorIncomeMethod.LendingGuaranteeIncome, 'Lending guarantee income'],
]);

export enum ServiceabilityCalculatorInterestRateLoanAmountMethod {
  IndividualLoanAmount = 1,
  TotalLoanAmount = 2,
}

export const ServiceabilityCalculatorInterestRateLoanAmountMethodLabel = new Map<number, string>([
  [ServiceabilityCalculatorInterestRateLoanAmountMethod.IndividualLoanAmount, 'Individual loan amount'],
  [ServiceabilityCalculatorInterestRateLoanAmountMethod.TotalLoanAmount, 'Total loan amount'],
]);

export enum ServiceabilityCalculatorLiabilityLimitMethodForRepayment {
  FullLimit = 1,
  OwnerLimiit = 2,
}

export const ServiceabilityCalculatorLiabilityLimitMethodForRepaymentLabel = new Map<number, string>([
  [ServiceabilityCalculatorLiabilityLimitMethodForRepayment.FullLimit, 'Full limit'],
  [ServiceabilityCalculatorLiabilityLimitMethodForRepayment.OwnerLimiit, 'Owner limit'],
]);

export enum ServiceabilityCalculatorLVRMethod {
  LVRPerApplication = 1,
  LVRPerLoan = 2,
}

export const ServiceabilityCalculatorLVRMethodLabel = new Map<number, string>([
  [ServiceabilityCalculatorLVRMethod.LVRPerApplication, 'LVR per application'],
  [ServiceabilityCalculatorLVRMethod.LVRPerLoan, 'LVR per loan'],
]);

export enum ServiceabilityCalculatorCalculateRentBasedOn {
  Household = 1,
  PostSettlementAddress = 2,
}

export const ServiceabilityCalculatorCalculateRentBasedOnLabel = new Map<number, string>([
  [ServiceabilityCalculatorCalculateRentBasedOn.Household, 'Household'],
  [ServiceabilityCalculatorCalculateRentBasedOn.PostSettlementAddress, 'Post settlement address'],
]);

export enum ServiceabilityCalculatorYearlyIncomeCustomRuleCalculation {
  Individual = 1,
  Total = 2,
}

export const ServiceabilityCalculatorYearlyIncomeCustomRuleCalculationLabel = new Map<number, string>([
  [ServiceabilityCalculatorYearlyIncomeCustomRuleCalculation.Individual, 'NPBT and addbacks individually'],
  [ServiceabilityCalculatorYearlyIncomeCustomRuleCalculation.Total, 'NPBT plus addbacks'],
]);

export enum ServiceabilityCalculatorInterestRateUsageOption {
  CurrentRate = 1,
  RevertRate = 2,
}

export const ServiceabilityCalculatorInterestRateUsageOptionLabel = new Map<number, string>([
  [ServiceabilityCalculatorInterestRateUsageOption.CurrentRate, 'Current rate '],
  [ServiceabilityCalculatorInterestRateUsageOption.RevertRate, 'Revert rate'],
]);

export enum ServiceabilityCalculatorSelfEmployedIncomeUsageOption {
  MostRecent = 1,
  Serviceable = 2,
}

export const ServiceabilityCalculatorSelfEmployedIncomeUsageOptionLabel = new Map<number, string>([
  [ServiceabilityCalculatorSelfEmployedIncomeUsageOption.MostRecent, 'Most recent income'],
  [ServiceabilityCalculatorSelfEmployedIncomeUsageOption.Serviceable, 'Serviceability income'],
]);

export enum ServiceabilityCalculatorVersionStatus {
  Draft = 1,
  Current = 2,
  Obsolete = 3,
}

export const ServiceabilityCalculatorVersionStatusLabel = new Map<number, string>([
  [ServiceabilityCalculatorVersionStatus.Draft, 'Draft'],
  [ServiceabilityCalculatorVersionStatus.Current, 'Current'],
  [ServiceabilityCalculatorVersionStatus.Obsolete, 'Obsolete'],
]);

export enum ServiceabilityDataObjectStatus {
  Active = 1,
  Inactive = 2,
}

export const ServiceabilityDataObjectStatusLabel = new Map<number, string>([
  [ServiceabilityDataObjectStatus.Active, 'Active'],
  [ServiceabilityDataObjectStatus.Inactive, 'Inactive'],
]);

export enum ServiceabilityExpenseCalculationMethod {
  ActualCollectedAmounts = 1,
  LenderSpecific = 2,
  HEMMeasure = 3,
  HPIMeasure = 4,
}

export const ServiceabilityExpenseCalculationMethodLabel = new Map<number, string>([
  [ServiceabilityExpenseCalculationMethod.ActualCollectedAmounts, 'Actual collected amounts'],
  [ServiceabilityExpenseCalculationMethod.LenderSpecific, 'Lender specific'],
  [ServiceabilityExpenseCalculationMethod.HEMMeasure, 'HEM measure'],
  [ServiceabilityExpenseCalculationMethod.HPIMeasure, 'HPI measure'],
]);

export enum ServiceabilityExpenseHouseholdIncomeMethod {
  ActualHouseholdIncome = 1,
  AverageHouseholdIncome = 2,
}

export const ServiceabilityExpenseHouseholdIncomeMethodLabel = new Map<number, string>([
  [ServiceabilityExpenseHouseholdIncomeMethod.ActualHouseholdIncome, 'Actual household income'],
  [ServiceabilityExpenseHouseholdIncomeMethod.AverageHouseholdIncome, 'Average household income'],
]);

export enum ServiceabilityExpenseCalculateRentBasedOn {
  Household = 1,
  PostSettlementAddress = 2,
}

export const ServiceabilityExpenseCalculateRentBasedOnLabel = new Map<number, string>([
  [ServiceabilityExpenseCalculateRentBasedOn.Household, 'Household'],
  [ServiceabilityExpenseCalculateRentBasedOn.PostSettlementAddress, 'Post settlement address'],
]);

export enum ServiceabilityExpenseDetailStatus {
  Active = 1,
  Inactive = 2,
}

export const ServiceabilityExpenseDetailStatusLabel = new Map<number, string>([
  [ServiceabilityExpenseDetailStatus.Active, 'Active'],
  [ServiceabilityExpenseDetailStatus.Inactive, 'Inactive'],
]);

export enum ServiceabilityExpenseDetailExpenseType {
  Adult = 1,
  AdultFirstDependant = 2,
  AdultSecondDependant = 3,
  AdultThirdDependant = 4,
  AdultFourthDependant = 5,
  AdultFifthDependant = 6,
  AdultSixthDependant = 7,
  AdultSeventhDependant = 8,
  AdultEightDependant = 9,
  AdultNinethDependant = 10,
  AdultAdditionalDependant = 11,
  AdditionalAdult = 12,
  Couple = 13,
  CoupleFirstDependant = 14,
  CoupleSecondDependant = 15,
  CoupleThirdDependant = 16,
  CoupleFourthDependant = 17,
  CoupleFifthDependant = 18,
  CoupleSixthDependant = 19,
  CoupleSeventhDependant = 20,
  CoupleEightDependant = 21,
  CoupleNinethDependant = 22,
  CoupleAdditionalDependant = 23,
  Childcare = 24,
  ClothingAndPersonalCare = 25,
  HigherEducation = 26,
  Groceries = 27,
  HealthInsurance = 28,
  InvestmentPropertyRunningCosts = 29,
  MedicalAndHealth = 30,
  OtherLivingExpenses = 31,
  PrimaryResidenceRunningCosts = 32,
  RecreationAndEntertainment = 33,
  Transport = 34,
  Subscriptions = 35,
  Board = 36,
  Rent = 37,
  ChildAndSpousalMaintenance = 38,
  OtherCommitments = 39,
  GeneralBasicInsurance = 40,
  PetCare = 41,
  PrivateSchoolingAndTuition = 42,
  PublicPrimaryAndSecondaryEducation = 43,
  BodyCorporateFees = 44,
  SecondaryResidenceRunningCosts = 45,
  LifeInsurance = 46,
  VoluntarySuperannuationContribution = 47,
}

export const ServiceabilityExpenseDetailExpenseTypeLabel = new Map<number, string>([
  [ServiceabilityExpenseDetailExpenseType.Adult, 'Adult'],
  [ServiceabilityExpenseDetailExpenseType.AdultFirstDependant, 'Adult first dependant'],
  [ServiceabilityExpenseDetailExpenseType.AdultSecondDependant, 'Adult second dependant'],
  [ServiceabilityExpenseDetailExpenseType.AdultThirdDependant, 'Adult third dependant'],
  [ServiceabilityExpenseDetailExpenseType.AdultFourthDependant, 'Adult fourth dependant'],
  [ServiceabilityExpenseDetailExpenseType.AdultFifthDependant, 'Adult fifth dependant'],
  [ServiceabilityExpenseDetailExpenseType.AdultSixthDependant, 'Adult sixth dependant'],
  [ServiceabilityExpenseDetailExpenseType.AdultSeventhDependant, 'Adult seventh dependant'],
  [ServiceabilityExpenseDetailExpenseType.AdultEightDependant, 'Adult eight dependant'],
  [ServiceabilityExpenseDetailExpenseType.AdultNinethDependant, 'Adult nineth dependant'],
  [ServiceabilityExpenseDetailExpenseType.AdultAdditionalDependant, 'Adult additional dependant'],
  [ServiceabilityExpenseDetailExpenseType.AdditionalAdult, 'Additional adult'],
  [ServiceabilityExpenseDetailExpenseType.Couple, 'Couple'],
  [ServiceabilityExpenseDetailExpenseType.CoupleFirstDependant, 'Couple first dependant'],
  [ServiceabilityExpenseDetailExpenseType.CoupleSecondDependant, 'Couple second dependant'],
  [ServiceabilityExpenseDetailExpenseType.CoupleThirdDependant, 'Couple third dependant'],
  [ServiceabilityExpenseDetailExpenseType.CoupleFourthDependant, 'Couple fourth dependant'],
  [ServiceabilityExpenseDetailExpenseType.CoupleFifthDependant, 'Couple fifth dependant'],
  [ServiceabilityExpenseDetailExpenseType.CoupleSixthDependant, 'Couple sixth dependant'],
  [ServiceabilityExpenseDetailExpenseType.CoupleSeventhDependant, 'Couple seventh dependant'],
  [ServiceabilityExpenseDetailExpenseType.CoupleEightDependant, 'Couple eight dependant'],
  [ServiceabilityExpenseDetailExpenseType.CoupleNinethDependant, 'Couple nineth dependant'],
  [ServiceabilityExpenseDetailExpenseType.CoupleAdditionalDependant, 'Couple additional dependant'],
  [ServiceabilityExpenseDetailExpenseType.Childcare, 'Childcare'],
  [ServiceabilityExpenseDetailExpenseType.ClothingAndPersonalCare, 'Clothing and personal care'],
  [ServiceabilityExpenseDetailExpenseType.HigherEducation, 'Higher education'],
  [ServiceabilityExpenseDetailExpenseType.Groceries, 'Groceries'],
  [ServiceabilityExpenseDetailExpenseType.HealthInsurance, 'Health insurance'],
  [ServiceabilityExpenseDetailExpenseType.InvestmentPropertyRunningCosts, 'Investment property running costs'],
  [ServiceabilityExpenseDetailExpenseType.MedicalAndHealth, 'Medical and health'],
  [ServiceabilityExpenseDetailExpenseType.OtherLivingExpenses, 'Other living expenses'],
  [ServiceabilityExpenseDetailExpenseType.PrimaryResidenceRunningCosts, 'Primary residence running costs'],
  [ServiceabilityExpenseDetailExpenseType.RecreationAndEntertainment, 'Recreation and entertainment'],
  [ServiceabilityExpenseDetailExpenseType.Transport, 'Transport'],
  [ServiceabilityExpenseDetailExpenseType.Subscriptions, 'Subscriptions'],
  [ServiceabilityExpenseDetailExpenseType.Board, 'Board'],
  [ServiceabilityExpenseDetailExpenseType.Rent, 'Rent'],
  [ServiceabilityExpenseDetailExpenseType.ChildAndSpousalMaintenance, 'Child and spousal maintenance'],
  [ServiceabilityExpenseDetailExpenseType.OtherCommitments, 'Other commitments'],
  [ServiceabilityExpenseDetailExpenseType.GeneralBasicInsurance, 'General basic insurance'],
  [ServiceabilityExpenseDetailExpenseType.PetCare, 'Pet care'],
  [ServiceabilityExpenseDetailExpenseType.PrivateSchoolingAndTuition, 'Private schooling and tuition'],
  [
    ServiceabilityExpenseDetailExpenseType.PublicPrimaryAndSecondaryEducation,
    'Public or Government Primary and Secondary Education',
  ],
  [ServiceabilityExpenseDetailExpenseType.BodyCorporateFees, 'Body corporate fees'],
  [ServiceabilityExpenseDetailExpenseType.SecondaryResidenceRunningCosts, 'Secondary residence running costs'],
  [ServiceabilityExpenseDetailExpenseType.LifeInsurance, 'Sickness and personal accident insurance, life insurance'],
  [ServiceabilityExpenseDetailExpenseType.VoluntarySuperannuationContribution, 'Voluntary superannuation contribution'],
]);

export enum ServiceabilityFinancialCommitmentNegativeGearingDeduction {
  ActualRate = 1,
  ActualRateNewLoan = 2,
  BenchmarkRateNewLoan = 3,
  LenderSpecific = 4,
  LenderSpecificOrActualRate = 5,
}

export const ServiceabilityFinancialCommitmentNegativeGearingDeductionLabel = new Map<number, string>([
  [ServiceabilityFinancialCommitmentNegativeGearingDeduction.ActualRate, 'Actual rate'],
  [ServiceabilityFinancialCommitmentNegativeGearingDeduction.ActualRateNewLoan, 'Actual rate new loan'],
  [ServiceabilityFinancialCommitmentNegativeGearingDeduction.BenchmarkRateNewLoan, 'Benchmark rate new loan'],
  [ServiceabilityFinancialCommitmentNegativeGearingDeduction.LenderSpecific, 'Lender specific'],
  [
    ServiceabilityFinancialCommitmentNegativeGearingDeduction.LenderSpecificOrActualRate,
    'Lender specific or actual rate',
  ],
]);

export enum ServiceabilityFinancialCommitmentDetailStatus {
  Active = 1,
  Inactive = 2,
}

export const ServiceabilityFinancialCommitmentDetailStatusLabel = new Map<number, string>([
  [ServiceabilityFinancialCommitmentDetailStatus.Active, 'Active'],
  [ServiceabilityFinancialCommitmentDetailStatus.Inactive, 'Inactive'],
]);

export enum ServiceabilityFinancialCommitmentDetailLiabilityType {
  MortgageLiabilityTermLoanPI = 1,
  MortgageLiabilityTermLoanIO = 2,
  MortgageLiabilityLineOfCreditPI = 3,
  MortgageLiabilityLineOfCreditIO = 4,
  CarLoan = 5,
  HirePurchase = 6,
  Lease = 7,
  MarginLoan = 8,
  PersonalLoan = 9,
  TermLoan = 10,
  OtherLoan = 11,
  CommercialBill = 12,
  ContingentLiability = 13,
  HECSHELP = 14,
  LoanAsGuarantor = 15,
  OutstandingTaxation = 16,
  BridgingFinance = 17,
  Other = 18,
  CreditCard = 19,
  LineOfCredit = 20,
  StoreCard = 21,
  Overdraft = 22,
  MortgageLoan = 23,
  TradeFinanceLoan = 24,
  InvoiceFinancingLoan = 25,
}

export const ServiceabilityFinancialCommitmentDetailLiabilityTypeLabel = new Map<number, string>([
  [
    ServiceabilityFinancialCommitmentDetailLiabilityType.MortgageLiabilityTermLoanPI,
    'Mortgage liability - term loan PI',
  ],
  [
    ServiceabilityFinancialCommitmentDetailLiabilityType.MortgageLiabilityTermLoanIO,
    'Mortgage liability - term loan IO',
  ],
  [
    ServiceabilityFinancialCommitmentDetailLiabilityType.MortgageLiabilityLineOfCreditPI,
    'Mortgage liability - line of credit PI',
  ],
  [
    ServiceabilityFinancialCommitmentDetailLiabilityType.MortgageLiabilityLineOfCreditIO,
    'Mortgage liability - line of credit IO',
  ],
  [ServiceabilityFinancialCommitmentDetailLiabilityType.CarLoan, 'Car loan'],
  [ServiceabilityFinancialCommitmentDetailLiabilityType.HirePurchase, 'Hire purchase'],
  [ServiceabilityFinancialCommitmentDetailLiabilityType.Lease, 'Lease'],
  [ServiceabilityFinancialCommitmentDetailLiabilityType.MarginLoan, 'Margin loan'],
  [ServiceabilityFinancialCommitmentDetailLiabilityType.PersonalLoan, 'Personal loan'],
  [ServiceabilityFinancialCommitmentDetailLiabilityType.TermLoan, 'Term loan'],
  [ServiceabilityFinancialCommitmentDetailLiabilityType.OtherLoan, 'Other loan'],
  [ServiceabilityFinancialCommitmentDetailLiabilityType.CommercialBill, 'Commercial bill'],
  [ServiceabilityFinancialCommitmentDetailLiabilityType.ContingentLiability, 'Contingent liability'],
  [ServiceabilityFinancialCommitmentDetailLiabilityType.HECSHELP, 'HECS-HELP'],
  [ServiceabilityFinancialCommitmentDetailLiabilityType.LoanAsGuarantor, 'Loan as guarantor'],
  [ServiceabilityFinancialCommitmentDetailLiabilityType.OutstandingTaxation, 'Outstanding taxation'],
  [ServiceabilityFinancialCommitmentDetailLiabilityType.BridgingFinance, 'Bridging finance'],
  [ServiceabilityFinancialCommitmentDetailLiabilityType.Other, 'Other'],
  [ServiceabilityFinancialCommitmentDetailLiabilityType.CreditCard, 'Credit card'],
  [ServiceabilityFinancialCommitmentDetailLiabilityType.LineOfCredit, 'Line of credit'],
  [ServiceabilityFinancialCommitmentDetailLiabilityType.StoreCard, 'Store card'],
  [ServiceabilityFinancialCommitmentDetailLiabilityType.Overdraft, 'Overdraft'],
  [ServiceabilityFinancialCommitmentDetailLiabilityType.MortgageLoan, 'Mortgage loan'],
  [ServiceabilityFinancialCommitmentDetailLiabilityType.TradeFinanceLoan, 'Trade finance loan'],
  [ServiceabilityFinancialCommitmentDetailLiabilityType.InvoiceFinancingLoan, 'Invoice financing loan'],
]);

export enum ServiceabilityFinancialCommitmentDetailRule {
  ActualRepayment = 1,
  ActualRepaymentPlusLoading = 2,
  CustomRule = 3,
  CreditLimit = 4,
}

export const ServiceabilityFinancialCommitmentDetailRuleLabel = new Map<number, string>([
  [ServiceabilityFinancialCommitmentDetailRule.ActualRepayment, 'Actual repayment'],
  [ServiceabilityFinancialCommitmentDetailRule.ActualRepaymentPlusLoading, 'Actual repayment plus loading'],
  [ServiceabilityFinancialCommitmentDetailRule.CustomRule, 'Custom rule'],
  [ServiceabilityFinancialCommitmentDetailRule.CreditLimit, 'Credit limit'],
]);

export enum ServiceabilityFinancialCommitmentDetailTermRule {
  RemainingTerm = 1,
  FixedTerm = 2,
  NewLoanTerm = 3,
  MaxRemainingTermAndFixedTerm = 4,
}

export const ServiceabilityFinancialCommitmentDetailTermRuleLabel = new Map<number, string>([
  [ServiceabilityFinancialCommitmentDetailTermRule.RemainingTerm, 'Remaining term'],
  [ServiceabilityFinancialCommitmentDetailTermRule.FixedTerm, 'Fixed term'],
  [ServiceabilityFinancialCommitmentDetailTermRule.NewLoanTerm, 'New loan term'],
  [ServiceabilityFinancialCommitmentDetailTermRule.MaxRemainingTermAndFixedTerm, 'Max remaining term and fixed term'],
]);

export enum ServiceabilityFinancialCommitmentDetailRateRule {
  ApplicationRate = 1,
  FixedRate = 2,
  NewLoanActualRate = 3,
  NewLoanBenchmarkRate = 4,
  ApplicationRatePlusBuffer = 5,
  MaxApplicationRateAndFixedRate = 6,
  MaxApplicationRatePlusBufferAndFixedRate = 7,
}

export const ServiceabilityFinancialCommitmentDetailRateRuleLabel = new Map<number, string>([
  [ServiceabilityFinancialCommitmentDetailRateRule.ApplicationRate, 'Application rate'],
  [ServiceabilityFinancialCommitmentDetailRateRule.FixedRate, 'Fixed rate'],
  [ServiceabilityFinancialCommitmentDetailRateRule.NewLoanActualRate, 'New loan actual rate'],
  [ServiceabilityFinancialCommitmentDetailRateRule.NewLoanBenchmarkRate, 'New loan benchmark rate'],
  [ServiceabilityFinancialCommitmentDetailRateRule.ApplicationRatePlusBuffer, 'Application rate plus buffer'],
  [
    ServiceabilityFinancialCommitmentDetailRateRule.MaxApplicationRateAndFixedRate,
    'Max application rate and fixed rate',
  ],
  [
    ServiceabilityFinancialCommitmentDetailRateRule.MaxApplicationRatePlusBufferAndFixedRate,
    'Max application rate plus buffer and fixed rate',
  ],
]);

export enum ServiceabilityFinancialCommitmentDetailApplicableTo {
  OutstandingBalance = 1,
  CreditLimit = 2,
  OutstandingBalancePlusAmountAvailableForRedraw = 3,
  HigherOfCreditLimitAndOutstandingBalance = 4,
}

export const ServiceabilityFinancialCommitmentDetailApplicableToLabel = new Map<number, string>([
  [ServiceabilityFinancialCommitmentDetailApplicableTo.OutstandingBalance, 'Outstanding balance'],
  [ServiceabilityFinancialCommitmentDetailApplicableTo.CreditLimit, 'Credit limit'],
  [
    ServiceabilityFinancialCommitmentDetailApplicableTo.OutstandingBalancePlusAmountAvailableForRedraw,
    'Outstanding balance plus amount available for redraw',
  ],
  [
    ServiceabilityFinancialCommitmentDetailApplicableTo.HigherOfCreditLimitAndOutstandingBalance,
    'Higher of credit limit and outstanding balance',
  ],
]);

export enum ServiceabilityFinancialCommitmentDetailOtherLiabilityRule {
  CreditLimit = 1,
  RepaymentAmount = 2,
  Benchmark = 3,
  RepaymentAmountPlusLoading = 4,
  RemainingTerm = 5,
  RemainingTermRatePlusBuffer = 6,
  NewLoanBenchmark = 7,
}

export const ServiceabilityFinancialCommitmentDetailOtherLiabilityRuleLabel = new Map<number, string>([
  [ServiceabilityFinancialCommitmentDetailOtherLiabilityRule.CreditLimit, 'Credit limit'],
  [ServiceabilityFinancialCommitmentDetailOtherLiabilityRule.RepaymentAmount, 'Repayment amount'],
  [ServiceabilityFinancialCommitmentDetailOtherLiabilityRule.Benchmark, 'Benchmark'],
  [
    ServiceabilityFinancialCommitmentDetailOtherLiabilityRule.RepaymentAmountPlusLoading,
    'Repayment amount plus loading',
  ],
  [ServiceabilityFinancialCommitmentDetailOtherLiabilityRule.RemainingTerm, 'Remaining term'],
  [
    ServiceabilityFinancialCommitmentDetailOtherLiabilityRule.RemainingTermRatePlusBuffer,
    'Remaining term (rate plus buffer)',
  ],
  [ServiceabilityFinancialCommitmentDetailOtherLiabilityRule.NewLoanBenchmark, 'New loan benchmark'],
]);

export enum ServiceabilityFunctionStatus {
  Active = 1,
  Inactive = 2,
}

export const ServiceabilityFunctionStatusLabel = new Map<number, string>([
  [ServiceabilityFunctionStatus.Active, 'Active'],
  [ServiceabilityFunctionStatus.Inactive, 'Inactive'],
]);

export enum ServiceabilityGroupStatus {
  Active = 1,
  Inactive = 2,
}

export const ServiceabilityGroupStatusLabel = new Map<number, string>([
  [ServiceabilityGroupStatus.Active, 'Active'],
  [ServiceabilityGroupStatus.Inactive, 'Inactive'],
]);

export enum ServiceabilityIncomeDetailStatus {
  Active = 1,
  Inactive = 2,
}

export const ServiceabilityIncomeDetailStatusLabel = new Map<number, string>([
  [ServiceabilityIncomeDetailStatus.Active, 'Active'],
  [ServiceabilityIncomeDetailStatus.Inactive, 'Inactive'],
]);

export enum ServiceabilityIndicatorStatus {
  Active = 1,
  Inactive = 2,
}

export const ServiceabilityIndicatorStatusLabel = new Map<number, string>([
  [ServiceabilityIndicatorStatus.Active, 'Active'],
  [ServiceabilityIndicatorStatus.Inactive, 'Inactive'],
]);

export enum ServiceabilityIndicatorSeverityLevel {
  Error = 1,
  Warning = 2,
}

export const ServiceabilityIndicatorSeverityLevelLabel = new Map<number, string>([
  [ServiceabilityIndicatorSeverityLevel.Error, 'Error'],
  [ServiceabilityIndicatorSeverityLevel.Warning, 'Warning'],
]);

export enum ServiceabilityIndicatorCalculationMethod {
  AverageRate = 1,
  MaximumRate = 2,
  FixedRate = 3,
  MinimumRate = 4,
}

export const ServiceabilityIndicatorCalculationMethodLabel = new Map<number, string>([
  [ServiceabilityIndicatorCalculationMethod.AverageRate, 'Average rate'],
  [ServiceabilityIndicatorCalculationMethod.MaximumRate, 'Maximum rate'],
  [ServiceabilityIndicatorCalculationMethod.FixedRate, 'Fixed rate'],
  [ServiceabilityIndicatorCalculationMethod.MinimumRate, 'Minimum rate'],
]);

export enum ServiceabilityIndicatorLoanTermOption {
  FixedTerm = 1,
  MinimumTerm = 2,
  MaximumTerm = 3,
}

export const ServiceabilityIndicatorLoanTermOptionLabel = new Map<number, string>([
  [ServiceabilityIndicatorLoanTermOption.FixedTerm, 'Fixed term'],
  [ServiceabilityIndicatorLoanTermOption.MinimumTerm, 'Minimum term'],
  [ServiceabilityIndicatorLoanTermOption.MaximumTerm, 'Maximum term'],
]);

export enum ServiceabilityMetricStatus {
  Active = 1,
  Inactive = 2,
}

export const ServiceabilityMetricStatusLabel = new Map<number, string>([
  [ServiceabilityMetricStatus.Active, 'Active'],
  [ServiceabilityMetricStatus.Inactive, 'Inactive'],
]);

export enum ServiceabilityMetricType {
  Range = 1,
  Severity = 2,
  Selection = 3,
}

export const ServiceabilityMetricTypeLabel = new Map<number, string>([
  [ServiceabilityMetricType.Range, 'Indicator with pass/fail ranges'],
  [ServiceabilityMetricType.Severity, 'Severity level'],
  [ServiceabilityMetricType.Selection, 'Interest rate,loan term'],
]);

export enum ServiceabilityMetricRangeDirection {
  LowToHigh = 1,
  HighToLow = 2,
}

export const ServiceabilityMetricRangeDirectionLabel = new Map<number, string>([
  [ServiceabilityMetricRangeDirection.LowToHigh, 'Low to high'],
  [ServiceabilityMetricRangeDirection.HighToLow, 'High to low'],
]);

export enum ServiceabilityNewFinancialCommitmentNegativeGearingDeduction {
  ActualRate = 1,
  BenchmarkRate = 2,
  LenderSpecific = 3,
  LenderSpecificOrActualRate = 4,
}

export const ServiceabilityNewFinancialCommitmentNegativeGearingDeductionLabel = new Map<number, string>([
  [ServiceabilityNewFinancialCommitmentNegativeGearingDeduction.ActualRate, 'Actual rate'],
  [ServiceabilityNewFinancialCommitmentNegativeGearingDeduction.BenchmarkRate, 'Benchmark rate'],
  [ServiceabilityNewFinancialCommitmentNegativeGearingDeduction.LenderSpecific, 'Lender specific'],
  [
    ServiceabilityNewFinancialCommitmentNegativeGearingDeduction.LenderSpecificOrActualRate,
    'Lender specific or actual rate',
  ],
]);

export enum ServiceabilityNewFinancialCommitmentDetailStatus {
  Active = 1,
  Inactive = 2,
}

export const ServiceabilityNewFinancialCommitmentDetailStatusLabel = new Map<number, string>([
  [ServiceabilityNewFinancialCommitmentDetailStatus.Active, 'Active'],
  [ServiceabilityNewFinancialCommitmentDetailStatus.Inactive, 'Inactive'],
]);

export enum ServiceabilityNewFinancialCommitmentDetailLoanType {
  ChattelMortgage = 1,
  CommercialBill = 2,
  CommercialHirePurchase = 3,
  CreditCard = 4,
  FinanceLease = 5,
  Lease = 6,
  LineOfCredit = 7,
  MarginLoan = 8,
  MortgageLoan = 9,
  OperatingLease = 10,
  Overdraft = 11,
  PersonalLoan = 12,
  ReverseMortgage = 13,
  TermLoan = 14,
  OtherLoan = 15,
  BridgingFinance = 16,
  InvoiceFinancingLoan = 17,
  TradeFinanceLoan = 18,
}

export const ServiceabilityNewFinancialCommitmentDetailLoanTypeLabel = new Map<number, string>([
  [ServiceabilityNewFinancialCommitmentDetailLoanType.ChattelMortgage, 'Chattel mortgage'],
  [ServiceabilityNewFinancialCommitmentDetailLoanType.CommercialBill, 'Commercial bill'],
  [ServiceabilityNewFinancialCommitmentDetailLoanType.CommercialHirePurchase, 'Commercial hire purchase'],
  [ServiceabilityNewFinancialCommitmentDetailLoanType.CreditCard, 'Credit card'],
  [ServiceabilityNewFinancialCommitmentDetailLoanType.FinanceLease, 'Finance lease'],
  [ServiceabilityNewFinancialCommitmentDetailLoanType.Lease, 'Lease'],
  [ServiceabilityNewFinancialCommitmentDetailLoanType.LineOfCredit, 'Line of credit'],
  [ServiceabilityNewFinancialCommitmentDetailLoanType.MarginLoan, 'Margin loan'],
  [ServiceabilityNewFinancialCommitmentDetailLoanType.MortgageLoan, 'Mortgage loan'],
  [ServiceabilityNewFinancialCommitmentDetailLoanType.OperatingLease, 'Operating lease'],
  [ServiceabilityNewFinancialCommitmentDetailLoanType.Overdraft, 'Overdraft'],
  [ServiceabilityNewFinancialCommitmentDetailLoanType.PersonalLoan, 'Personal loan'],
  [ServiceabilityNewFinancialCommitmentDetailLoanType.ReverseMortgage, 'Reverse mortgage'],
  [ServiceabilityNewFinancialCommitmentDetailLoanType.TermLoan, 'Term loan'],
  [ServiceabilityNewFinancialCommitmentDetailLoanType.OtherLoan, 'Other loan'],
  [ServiceabilityNewFinancialCommitmentDetailLoanType.BridgingFinance, 'Bridging finance'],
  [ServiceabilityNewFinancialCommitmentDetailLoanType.InvoiceFinancingLoan, 'Invoice financing loan'],
  [ServiceabilityNewFinancialCommitmentDetailLoanType.TradeFinanceLoan, 'Trade finance loan'],
]);

export enum ServiceabilityResultStatus {
  Approved = 0,
  Declined = 1,
}

export const ServiceabilityResultStatusLabel = new Map<number, string>([
  [ServiceabilityResultStatus.Approved, 'Approved'],
  [ServiceabilityResultStatus.Declined, 'Declined'],
]);

export enum ServiceabilitySetStatus {
  Active = 1,
  Inactive = 2,
}

export const ServiceabilitySetStatusLabel = new Map<number, string>([
  [ServiceabilitySetStatus.Active, 'Active'],
  [ServiceabilitySetStatus.Inactive, 'Inactive'],
]);

export enum ServiceabilitySetupStatus {
  Draft = 1,
  Current = 2,
  Obsolete = 3,
}

export const ServiceabilitySetupStatusLabel = new Map<number, string>([
  [ServiceabilitySetupStatus.Draft, 'Draft'],
  [ServiceabilitySetupStatus.Current, 'Current'],
  [ServiceabilitySetupStatus.Obsolete, 'Obsolete'],
]);

export enum ServiceabilitySetupExpenseDetailType {
  HEM = 1,
  HPI = 2,
  LenderHem = 3,
}

export const ServiceabilitySetupExpenseDetailTypeLabel = new Map<number, string>([
  [ServiceabilitySetupExpenseDetailType.HEM, 'HEM'],
  [ServiceabilitySetupExpenseDetailType.HPI, 'HPI'],
  [ServiceabilitySetupExpenseDetailType.LenderHem, 'Lender HEM'],
]);

export enum SettlementReferenceDataListType {
  LenderInfo = 1,
  ProgramManagerInfo = 2,
  ProductType = 3,
  PayableTo = 4,
  WhenPayable = 5,
}

export const SettlementReferenceDataListTypeLabel = new Map<number, string>([
  [SettlementReferenceDataListType.LenderInfo, 'Lender info'],
  [SettlementReferenceDataListType.ProgramManagerInfo, 'Program manager info'],
  [SettlementReferenceDataListType.ProductType, 'Product type'],
  [SettlementReferenceDataListType.PayableTo, 'Payable to'],
  [SettlementReferenceDataListType.WhenPayable, 'When payable'],
]);

export enum SettlementReferenceDataStatus {
  Active = 1,
  Inactive = 2,
}

export const SettlementReferenceDataStatusLabel = new Map<number, string>([
  [SettlementReferenceDataStatus.Active, 'Active'],
  [SettlementReferenceDataStatus.Inactive, 'Inactive'],
]);

export enum SolutionGroupStatus {
  Active = 1,
  Inactive = 2,
}

export const SolutionGroupStatusLabel = new Map<number, string>([
  [SolutionGroupStatus.Active, 'Active'],
  [SolutionGroupStatus.Inactive, 'Inactive'],
]);

export enum SolutionPriceTemplateType {
  Subscription = 1,
  Usage = 2,
}

export const SolutionPriceTemplateTypeLabel = new Map<number, string>([
  [SolutionPriceTemplateType.Subscription, 'Subscription'],
  [SolutionPriceTemplateType.Usage, 'Usage'],
]);

export enum SolutionPriceTemplateStatus {
  Active = 1,
  Inactive = 2,
}

export const SolutionPriceTemplateStatusLabel = new Map<number, string>([
  [SolutionPriceTemplateStatus.Active, 'Active'],
  [SolutionPriceTemplateStatus.Inactive, 'Inactive'],
]);

export enum SsiBackChannelMessageMessageType {
  Status = 1,
  Event = 2,
}

export const SsiBackChannelMessageMessageTypeLabel = new Map<number, string>([
  [SsiBackChannelMessageMessageType.Status, 'Status'],
  [SsiBackChannelMessageMessageType.Event, 'Event'],
]);

export enum SsiBankingDataDecisionMetricCategory {
  Income = 1,
  Expense = 2,
  Flag = 3,
}

export const SsiBankingDataDecisionMetricCategoryLabel = new Map<number, string>([
  [SsiBankingDataDecisionMetricCategory.Income, 'Income'],
  [SsiBankingDataDecisionMetricCategory.Expense, 'Expense'],
  [SsiBankingDataDecisionMetricCategory.Flag, 'Flag '],
]);

export enum SsiBankingDataFileType {
  Transformed = 1,
  Result = 2,
  StatementPdf = 3,
  ReportHtml = 4,
}

export const SsiBankingDataFileTypeLabel = new Map<number, string>([
  [SsiBankingDataFileType.Transformed, 'Transformed'],
  [SsiBankingDataFileType.Result, 'Result'],
  [SsiBankingDataFileType.StatementPdf, 'StatementPdf'],
  [SsiBankingDataFileType.ReportHtml, 'ReportHtml'],
]);

export enum StandardClauseStatus {
  Active = 1,
}

export const StandardClauseStatusLabel = new Map<number, string>([[StandardClauseStatus.Active, 'Active']]);

export enum TaxType {
  PersonalTax = 1,
  CompanyTax = 2,
  TrustTax = 3,
  StudentLoanReduction = 4,
}

export const TaxTypeLabel = new Map<number, string>([
  [TaxType.PersonalTax, 'Personal tax (PAYG, Self-employed)'],
  [TaxType.CompanyTax, 'Company tax'],
  [TaxType.TrustTax, 'Trust tax'],
  [TaxType.StudentLoanReduction, 'Student loan reduction'],
]);

export enum TaxOffsetType {
  LITO = 1,
  LMITO = 2,
}

export const TaxOffsetTypeLabel = new Map<number, string>([
  [TaxOffsetType.LITO, 'Low income tax offset'],
  [TaxOffsetType.LMITO, 'Low and middle income tax offset'],
]);

export enum TeamStatus {
  Active = 1,
}

export const TeamStatusLabel = new Map<number, string>([[TeamStatus.Active, 'Active']]);

export enum TeamMemberStatus {
  Active = 1,
}

export const TeamMemberStatusLabel = new Map<number, string>([[TeamMemberStatus.Active, 'Active']]);

export enum TemplateDocumentStatus {
  Active = 1,
}

export const TemplateDocumentStatusLabel = new Map<number, string>([[TemplateDocumentStatus.Active, 'Active']]);

export enum TemplateDocumentStandardUse {
  ApplicationForm = 1,
  ServiceabilityOutcome = 2,
  LoanSummary = 3,
  StatementOfPosition = 4,
  Product = 5,
  Consent = 6,
  TermAndConditions = 7,
  Standard = 8,
  BrokerForm = 9,
  BrokerList = 10,
  LoanOffer = 11,
  BorrowingPower = 12,
  BrokerInterview = 13,
  SmartStatements = 14,
  LoanInsuranceCalculationQuote = 15,
  Schedule4LoanInstruction = 16,
  Compliance = 17,
  ServiceabilityCalculatorResult = 18,
  CreditAssessment = 19,
}

export const TemplateDocumentStandardUseLabel = new Map<number, string>([
  [TemplateDocumentStandardUse.ApplicationForm, 'Application form'],
  [TemplateDocumentStandardUse.ServiceabilityOutcome, 'Serviceability outcome'],
  [TemplateDocumentStandardUse.LoanSummary, 'Loan summary'],
  [TemplateDocumentStandardUse.StatementOfPosition, 'Statement of position'],
  [TemplateDocumentStandardUse.Product, 'Product'],
  [TemplateDocumentStandardUse.Consent, 'Consent'],
  [TemplateDocumentStandardUse.TermAndConditions, 'Term and conditions'],
  [TemplateDocumentStandardUse.Standard, 'Standard'],
  [TemplateDocumentStandardUse.BrokerForm, 'Broker form'],
  [TemplateDocumentStandardUse.BrokerList, 'Broker list'],
  [TemplateDocumentStandardUse.LoanOffer, 'Loan offer'],
  [TemplateDocumentStandardUse.BorrowingPower, 'Borrowing power'],
  [TemplateDocumentStandardUse.BrokerInterview, 'Broker interview'],
  [TemplateDocumentStandardUse.SmartStatements, 'Smart statements'],
  [TemplateDocumentStandardUse.LoanInsuranceCalculationQuote, 'Loan insurance calculation quote'],
  [TemplateDocumentStandardUse.Schedule4LoanInstruction, 'Loan Instruction - Schedule 4'],
  [TemplateDocumentStandardUse.Compliance, 'Compliance'],
  [TemplateDocumentStandardUse.ServiceabilityCalculatorResult, 'Serviceability Calculator Result'],
  [TemplateDocumentStandardUse.CreditAssessment, 'Credit Assessment'],
]);

export enum TemplateDocumentGroupStatus {
  Active = 1,
}

export const TemplateDocumentGroupStatusLabel = new Map<number, string>([
  [TemplateDocumentGroupStatus.Active, 'Active'],
]);

export enum TemplateDocumentSubgroupStatus {
  Active = 1,
}

export const TemplateDocumentSubgroupStatusLabel = new Map<number, string>([
  [TemplateDocumentSubgroupStatus.Active, 'Active'],
]);

export enum TemplateDocumentVersionStatus {
  Draft = 1,
  Release = 2,
  Obsolete = 3,
}

export const TemplateDocumentVersionStatusLabel = new Map<number, string>([
  [TemplateDocumentVersionStatus.Draft, 'Draft'],
  [TemplateDocumentVersionStatus.Release, 'Release'],
  [TemplateDocumentVersionStatus.Obsolete, 'Obsolete'],
]);

export enum UserStatus {
  Active = 1,
  Inactive = 2,
}

export const UserStatusLabel = new Map<number, string>([
  [UserStatus.Active, 'Active'],
  [UserStatus.Inactive, 'Inactive'],
]);

export enum UserInfoRequestStatus {
  New = 1,
  Open = 2,
}

export const UserInfoRequestStatusLabel = new Map<number, string>([
  [UserInfoRequestStatus.New, 'New'],
  [UserInfoRequestStatus.Open, 'Open'],
]);

export enum WebHookStatus {
  Active = 1,
  Inactive = 2,
}

export const WebHookStatusLabel = new Map<number, string>([
  [WebHookStatus.Active, 'Active'],
  [WebHookStatus.Inactive, 'Inactive'],
]);

export const EnumLabelAuto: EnumMapper[] = [
  { id: 'ActionItemLogEventType', enumType: ActionItemLogEventType, mapping: ActionItemLogEventTypeLabel },
  { id: 'ActionItemTemplateType', enumType: ActionItemTemplateType, mapping: ActionItemTemplateTypeLabel },
  {
    id: 'ActionItemTemplateTargetPersonSelection',
    enumType: ActionItemTemplateTargetPersonSelection,
    mapping: ActionItemTemplateTargetPersonSelectionLabel,
  },
  { id: 'ActionItemTemplatePriority', enumType: ActionItemTemplatePriority, mapping: ActionItemTemplatePriorityLabel },
  {
    id: 'ActionItemTemplateActionPage',
    enumType: ActionItemTemplateActionPage,
    mapping: ActionItemTemplateActionPageLabel,
  },
  {
    id: 'ActionItemTemplateRuleOption',
    enumType: ActionItemTemplateRuleOption,
    mapping: ActionItemTemplateRuleOptionLabel,
  },
  {
    id: 'ActionProcessTemplatePassRequirement',
    enumType: ActionProcessTemplatePassRequirement,
    mapping: ActionProcessTemplatePassRequirementLabel,
  },
  {
    id: 'ActionProcessTemplateFailRequirement',
    enumType: ActionProcessTemplateFailRequirement,
    mapping: ActionProcessTemplateFailRequirementLabel,
  },
  {
    id: 'ActionProcessTemplateRuleOption',
    enumType: ActionProcessTemplateRuleOption,
    mapping: ActionProcessTemplateRuleOptionLabel,
  },
  {
    id: 'ApplicationActionItemStatus',
    enumType: ApplicationActionItemStatus,
    mapping: ApplicationActionItemStatusLabel,
  },
  {
    id: 'ApplicationActionItemTargetPersonOption',
    enumType: ApplicationActionItemTargetPersonOption,
    mapping: ApplicationActionItemTargetPersonOptionLabel,
  },
  {
    id: 'ApplicationActionItemAttachmentStatus',
    enumType: ApplicationActionItemAttachmentStatus,
    mapping: ApplicationActionItemAttachmentStatusLabel,
  },
  {
    id: 'ApplicationActionItemChecklistStatus',
    enumType: ApplicationActionItemChecklistStatus,
    mapping: ApplicationActionItemChecklistStatusLabel,
  },
  {
    id: 'ApplicationActionProcessStatus',
    enumType: ApplicationActionProcessStatus,
    mapping: ApplicationActionProcessStatusLabel,
  },
  {
    id: 'ApplicationActivityStatusStatus',
    enumType: ApplicationActivityStatusStatus,
    mapping: ApplicationActivityStatusStatusLabel,
  },
  {
    id: 'ApplicationAllocationPropertyType',
    enumType: ApplicationAllocationPropertyType,
    mapping: ApplicationAllocationPropertyTypeLabel,
  },
  { id: 'ApplicationApplicantStatus', enumType: ApplicationApplicantStatus, mapping: ApplicationApplicantStatusLabel },
  { id: 'ApplicationApplicantType', enumType: ApplicationApplicantType, mapping: ApplicationApplicantTypeLabel },
  {
    id: 'ApplicationChecklistCategoryStatus',
    enumType: ApplicationChecklistCategoryStatus,
    mapping: ApplicationChecklistCategoryStatusLabel,
  },
  {
    id: 'ApplicationChecklistItemStatus',
    enumType: ApplicationChecklistItemStatus,
    mapping: ApplicationChecklistItemStatusLabel,
  },
  {
    id: 'ApplicationChecklistItemVerificationStatus',
    enumType: ApplicationChecklistItemVerificationStatus,
    mapping: ApplicationChecklistItemVerificationStatusLabel,
  },
  {
    id: 'ApplicationChecklistItemVerificationTaskStatus',
    enumType: ApplicationChecklistItemVerificationTaskStatus,
    mapping: ApplicationChecklistItemVerificationTaskStatusLabel,
  },
  { id: 'ApplicationDocumentStatus', enumType: ApplicationDocumentStatus, mapping: ApplicationDocumentStatusLabel },
  {
    id: 'ApplicationInfoRequestStatus',
    enumType: ApplicationInfoRequestStatus,
    mapping: ApplicationInfoRequestStatusLabel,
  },
  { id: 'ApplicationNoteStatus', enumType: ApplicationNoteStatus, mapping: ApplicationNoteStatusLabel },
  { id: 'ApplicationNoteType', enumType: ApplicationNoteType, mapping: ApplicationNoteTypeLabel },
  {
    id: 'ApplicationParticipantStatus',
    enumType: ApplicationParticipantStatus,
    mapping: ApplicationParticipantStatusLabel,
  },
  { id: 'ApplicationParticipantType', enumType: ApplicationParticipantType, mapping: ApplicationParticipantTypeLabel },
  { id: 'ApplicationRoleStatus', enumType: ApplicationRoleStatus, mapping: ApplicationRoleStatusLabel },
  {
    id: 'ApplicationSummarySettlementStatus',
    enumType: ApplicationSummarySettlementStatus,
    mapping: ApplicationSummarySettlementStatusLabel,
  },
  { id: 'ApplicationTemplateStatus', enumType: ApplicationTemplateStatus, mapping: ApplicationTemplateStatusLabel },
  {
    id: 'ApplicationVerificationPrimaryDataStatus',
    enumType: ApplicationVerificationPrimaryDataStatus,
    mapping: ApplicationVerificationPrimaryDataStatusLabel,
  },
  {
    id: 'ApplicationVerificationPrimaryDataSourceType',
    enumType: ApplicationVerificationPrimaryDataSourceType,
    mapping: ApplicationVerificationPrimaryDataSourceTypeLabel,
  },
  {
    id: 'AssessAuthorisationLevelStatus',
    enumType: AssessAuthorisationLevelStatus,
    mapping: AssessAuthorisationLevelStatusLabel,
  },
  { id: 'AssessmentStatus', enumType: AssessmentStatus, mapping: AssessmentStatusLabel },
  { id: 'AssessmentType', enumType: AssessmentType, mapping: AssessmentTypeLabel },
  {
    id: 'AssessmentConditionOfApprovalDecision',
    enumType: AssessmentConditionOfApprovalDecision,
    mapping: AssessmentConditionOfApprovalDecisionLabel,
  },
  { id: 'AssessmentDocumentStatus', enumType: AssessmentDocumentStatus, mapping: AssessmentDocumentStatusLabel },
  {
    id: 'AssessmentItemRuleRuleResult',
    enumType: AssessmentItemRuleRuleResult,
    mapping: AssessmentItemRuleRuleResultLabel,
  },
  { id: 'AssessmentItemRuleDecision', enumType: AssessmentItemRuleDecision, mapping: AssessmentItemRuleDecisionLabel },
  {
    id: 'AssessmentSummaryNoteStatus',
    enumType: AssessmentSummaryNoteStatus,
    mapping: AssessmentSummaryNoteStatusLabel,
  },
  { id: 'AuditLogCategory', enumType: AuditLogCategory, mapping: AuditLogCategoryLabel },
  { id: 'AuthorisationLevelStatus', enumType: AuthorisationLevelStatus, mapping: AuthorisationLevelStatusLabel },
  {
    id: 'BackChannelMessageMessageType',
    enumType: BackChannelMessageMessageType,
    mapping: BackChannelMessageMessageTypeLabel,
  },
  { id: 'BrokerStatus', enumType: BrokerStatus, mapping: BrokerStatusLabel },
  { id: 'BrokerBrokerRanking', enumType: BrokerBrokerRanking, mapping: BrokerBrokerRankingLabel },
  { id: 'BrokerAppointmentStatus', enumType: BrokerAppointmentStatus, mapping: BrokerAppointmentStatusLabel },
  { id: 'BrokerAwardStatus', enumType: BrokerAwardStatus, mapping: BrokerAwardStatusLabel },
  { id: 'BrokerEventStatus', enumType: BrokerEventStatus, mapping: BrokerEventStatusLabel },
  { id: 'BrokerEventType', enumType: BrokerEventType, mapping: BrokerEventTypeLabel },
  { id: 'BrokerInsuranceStatus', enumType: BrokerInsuranceStatus, mapping: BrokerInsuranceStatusLabel },
  { id: 'BrokerInsuranceType', enumType: BrokerInsuranceType, mapping: BrokerInsuranceTypeLabel },
  { id: 'BrokerInsuranceProvider', enumType: BrokerInsuranceProvider, mapping: BrokerInsuranceProviderLabel },
  { id: 'BrokerMembershipStatus', enumType: BrokerMembershipStatus, mapping: BrokerMembershipStatusLabel },
  { id: 'CalculatorStatus', enumType: CalculatorStatus, mapping: CalculatorStatusLabel },
  { id: 'CalculatorCalculatorType', enumType: CalculatorCalculatorType, mapping: CalculatorCalculatorTypeLabel },
  {
    id: 'CalculatorConfigurationConfigurationOption',
    enumType: CalculatorConfigurationConfigurationOption,
    mapping: CalculatorConfigurationConfigurationOptionLabel,
  },
  { id: 'CalculatorVersionStatus', enumType: CalculatorVersionStatus, mapping: CalculatorVersionStatusLabel },
  { id: 'ChannelStatus', enumType: ChannelStatus, mapping: ChannelStatusLabel },
  { id: 'ChannelCompanyNumberType', enumType: ChannelCompanyNumberType, mapping: ChannelCompanyNumberTypeLabel },
  { id: 'ChannelBrandImageBrandType', enumType: ChannelBrandImageBrandType, mapping: ChannelBrandImageBrandTypeLabel },
  { id: 'ChannelDataStatus', enumType: ChannelDataStatus, mapping: ChannelDataStatusLabel },
  { id: 'ChannelHierarchyStatus', enumType: ChannelHierarchyStatus, mapping: ChannelHierarchyStatusLabel },
  {
    id: 'ChannelHierarchyWithEntityEntityType',
    enumType: ChannelHierarchyWithEntityEntityType,
    mapping: ChannelHierarchyWithEntityEntityTypeLabel,
  },
  {
    id: 'ChannelHierarchyWithEntityStatus',
    enumType: ChannelHierarchyWithEntityStatus,
    mapping: ChannelHierarchyWithEntityStatusLabel,
  },
  { id: 'ChannelSolicitorStatus', enumType: ChannelSolicitorStatus, mapping: ChannelSolicitorStatusLabel },
  { id: 'ChannelSubscriptionStatus', enumType: ChannelSubscriptionStatus, mapping: ChannelSubscriptionStatusLabel },
  {
    id: 'ChannelSubscriptionWithTemplateType',
    enumType: ChannelSubscriptionWithTemplateType,
    mapping: ChannelSubscriptionWithTemplateTypeLabel,
  },
  {
    id: 'ChannelSubscriptionWithTemplateStatus',
    enumType: ChannelSubscriptionWithTemplateStatus,
    mapping: ChannelSubscriptionWithTemplateStatusLabel,
  },
  {
    id: 'ChecklistItemVerificationPrimaryDataStatus',
    enumType: ChecklistItemVerificationPrimaryDataStatus,
    mapping: ChecklistItemVerificationPrimaryDataStatusLabel,
  },
  {
    id: 'ComparisonRateCalculatorStatus',
    enumType: ComparisonRateCalculatorStatus,
    mapping: ComparisonRateCalculatorStatusLabel,
  },
  { id: 'ConsentPageTemplateType', enumType: ConsentPageTemplateType, mapping: ConsentPageTemplateTypeLabel },
  {
    id: 'ConsentPageTemplateSecurityCodeType',
    enumType: ConsentPageTemplateSecurityCodeType,
    mapping: ConsentPageTemplateSecurityCodeTypeLabel,
  },
  { id: 'DataCollectionStatus', enumType: DataCollectionStatus, mapping: DataCollectionStatusLabel },
  { id: 'DataSubscriptionStatus', enumType: DataSubscriptionStatus, mapping: DataSubscriptionStatusLabel },
  {
    id: 'DigitalServiceDataFileDataType',
    enumType: DigitalServiceDataFileDataType,
    mapping: DigitalServiceDataFileDataTypeLabel,
  },
  { id: 'DiscountPackageStatus', enumType: DiscountPackageStatus, mapping: DiscountPackageStatusLabel },
  {
    id: 'DiscountPackageBenefitStatus',
    enumType: DiscountPackageBenefitStatus,
    mapping: DiscountPackageBenefitStatusLabel,
  },
  { id: 'DiscountPackageFeeStatus', enumType: DiscountPackageFeeStatus, mapping: DiscountPackageFeeStatusLabel },
  { id: 'DiscountPackageRateStatus', enumType: DiscountPackageRateStatus, mapping: DiscountPackageRateStatusLabel },
  { id: 'DocumentStatus', enumType: DocumentStatus, mapping: DocumentStatusLabel },
  { id: 'DocumentScope', enumType: DocumentScope, mapping: DocumentScopeLabel },
  { id: 'DocumentType', enumType: DocumentType, mapping: DocumentTypeLabel },
  { id: 'DocumentStandardUse', enumType: DocumentStandardUse, mapping: DocumentStandardUseLabel },
  {
    id: 'DocumentChecklistTemplateStatus',
    enumType: DocumentChecklistTemplateStatus,
    mapping: DocumentChecklistTemplateStatusLabel,
  },
  {
    id: 'DocumentChecklistTemplateCategoryStatus',
    enumType: DocumentChecklistTemplateCategoryStatus,
    mapping: DocumentChecklistTemplateCategoryStatusLabel,
  },
  {
    id: 'DocumentChecklistTemplateItemStatus',
    enumType: DocumentChecklistTemplateItemStatus,
    mapping: DocumentChecklistTemplateItemStatusLabel,
  },
  { id: 'DocumentStandardUseStatus', enumType: DocumentStandardUseStatus, mapping: DocumentStandardUseStatusLabel },
  {
    id: 'DocumentVerificationResultConcernStatus',
    enumType: DocumentVerificationResultConcernStatus,
    mapping: DocumentVerificationResultConcernStatusLabel,
  },
  { id: 'DocumentVersionStatus', enumType: DocumentVersionStatus, mapping: DocumentVersionStatusLabel },
  { id: 'DynamicQueryStatus', enumType: DynamicQueryStatus, mapping: DynamicQueryStatusLabel },
  { id: 'DynamicQueryFieldCategory', enumType: DynamicQueryFieldCategory, mapping: DynamicQueryFieldCategoryLabel },
  { id: 'DynamicQueryFieldStatus', enumType: DynamicQueryFieldStatus, mapping: DynamicQueryFieldStatusLabel },
  { id: 'DynamicQueryFieldType', enumType: DynamicQueryFieldType, mapping: DynamicQueryFieldTypeLabel },
  { id: 'DynamicRuleCategory', enumType: DynamicRuleCategory, mapping: DynamicRuleCategoryLabel },
  { id: 'DynamicRuleStatus', enumType: DynamicRuleStatus, mapping: DynamicRuleStatusLabel },
  { id: 'DynamicRuleFieldCategory', enumType: DynamicRuleFieldCategory, mapping: DynamicRuleFieldCategoryLabel },
  { id: 'DynamicRuleFieldStatus', enumType: DynamicRuleFieldStatus, mapping: DynamicRuleFieldStatusLabel },
  { id: 'DynamicRuleFieldType', enumType: DynamicRuleFieldType, mapping: DynamicRuleFieldTypeLabel },
  { id: 'EntityType', enumType: EntityType, mapping: EntityTypeLabel },
  { id: 'EntityStatus', enumType: EntityStatus, mapping: EntityStatusLabel },
  { id: 'EntityCompanyNumberType', enumType: EntityCompanyNumberType, mapping: EntityCompanyNumberTypeLabel },
  { id: 'EntityBrandImageBrandType', enumType: EntityBrandImageBrandType, mapping: EntityBrandImageBrandTypeLabel },
  { id: 'FeatureOptionStatus', enumType: FeatureOptionStatus, mapping: FeatureOptionStatusLabel },
  {
    id: 'FeatureOptionFeatureOptionCode',
    enumType: FeatureOptionFeatureOptionCode,
    mapping: FeatureOptionFeatureOptionCodeLabel,
  },
  { id: 'FeatureTypeStatus', enumType: FeatureTypeStatus, mapping: FeatureTypeStatusLabel },
  { id: 'FeatureTypeMode', enumType: FeatureTypeMode, mapping: FeatureTypeModeLabel },
  { id: 'FeatureTypeFeatureCode', enumType: FeatureTypeFeatureCode, mapping: FeatureTypeFeatureCodeLabel },
  { id: 'FeeStatus', enumType: FeeStatus, mapping: FeeStatusLabel },
  { id: 'FeeCalculationType', enumType: FeeCalculationType, mapping: FeeCalculationTypeLabel },
  { id: 'FeeTypeStatus', enumType: FeeTypeStatus, mapping: FeeTypeStatusLabel },
  { id: 'FeeTypeWhenPaid', enumType: FeeTypeWhenPaid, mapping: FeeTypeWhenPaidLabel },
  { id: 'FeeTypeFeeCode', enumType: FeeTypeFeeCode, mapping: FeeTypeFeeCodeLabel },
  { id: 'FeeViewStatus', enumType: FeeViewStatus, mapping: FeeViewStatusLabel },
  { id: 'FullPermissionType', enumType: FullPermissionType, mapping: FullPermissionTypeLabel },
  { id: 'FullPermissionScope', enumType: FullPermissionScope, mapping: FullPermissionScopeLabel },
  { id: 'GroupStatus', enumType: GroupStatus, mapping: GroupStatusLabel },
  { id: 'InfoRequestStatus', enumType: InfoRequestStatus, mapping: InfoRequestStatusLabel },
  { id: 'InfoRequestType', enumType: InfoRequestType, mapping: InfoRequestTypeLabel },
  {
    id: 'InfoRequestLogRequestAction',
    enumType: InfoRequestLogRequestAction,
    mapping: InfoRequestLogRequestActionLabel,
  },
  { id: 'InfoRequestResponseStatus', enumType: InfoRequestResponseStatus, mapping: InfoRequestResponseStatusLabel },
  {
    id: 'InfoRequestResponseDocumentStatus',
    enumType: InfoRequestResponseDocumentStatus,
    mapping: InfoRequestResponseDocumentStatusLabel,
  },
  {
    id: 'InfoRequestTemplateDetailStatus',
    enumType: InfoRequestTemplateDetailStatus,
    mapping: InfoRequestTemplateDetailStatusLabel,
  },
  {
    id: 'InfoRequestTemplateDetailProvidedByPersonType',
    enumType: InfoRequestTemplateDetailProvidedByPersonType,
    mapping: InfoRequestTemplateDetailProvidedByPersonTypeLabel,
  },
  { id: 'InstructionTemplateStatus', enumType: InstructionTemplateStatus, mapping: InstructionTemplateStatusLabel },
  { id: 'InsurerMasterStatus', enumType: InsurerMasterStatus, mapping: InsurerMasterStatusLabel },
  {
    id: 'InterestFeatureOptionStatus',
    enumType: InterestFeatureOptionStatus,
    mapping: InterestFeatureOptionStatusLabel,
  },
  { id: 'InterestFeatureTypeStatus', enumType: InterestFeatureTypeStatus, mapping: InterestFeatureTypeStatusLabel },
  { id: 'InterestFeatureTypeMode', enumType: InterestFeatureTypeMode, mapping: InterestFeatureTypeModeLabel },
  {
    id: 'JourneyActionProcessSetupStatus',
    enumType: JourneyActionProcessSetupStatus,
    mapping: JourneyActionProcessSetupStatusLabel,
  },
  { id: 'JourneyProductSetupStatus', enumType: JourneyProductSetupStatus, mapping: JourneyProductSetupStatusLabel },
  { id: 'JourneyRulesetSetupStatus', enumType: JourneyRulesetSetupStatus, mapping: JourneyRulesetSetupStatusLabel },
  {
    id: 'JourneyServiceabilitySetupStatus',
    enumType: JourneyServiceabilitySetupStatus,
    mapping: JourneyServiceabilitySetupStatusLabel,
  },
  {
    id: 'JourneyServiceabilitySetupUsageOption',
    enumType: JourneyServiceabilitySetupUsageOption,
    mapping: JourneyServiceabilitySetupUsageOptionLabel,
  },
  { id: 'LenderAccreditationStatus', enumType: LenderAccreditationStatus, mapping: LenderAccreditationStatusLabel },
  { id: 'LenderMasterStatus', enumType: LenderMasterStatus, mapping: LenderMasterStatusLabel },
  { id: 'LevyType', enumType: LevyType, mapping: LevyTypeLabel },
  {
    id: 'LoanApplicationSubmitToLenderStatus',
    enumType: LoanApplicationSubmitToLenderStatus,
    mapping: LoanApplicationSubmitToLenderStatusLabel,
  },
  { id: 'LoanApplicationStatus', enumType: LoanApplicationStatus, mapping: LoanApplicationStatusLabel },
  {
    id: 'LoanApplicationCommunicationOption',
    enumType: LoanApplicationCommunicationOption,
    mapping: LoanApplicationCommunicationOptionLabel,
  },
  {
    id: 'LoanApplicationBrandingOption',
    enumType: LoanApplicationBrandingOption,
    mapping: LoanApplicationBrandingOptionLabel,
  },
  {
    id: 'LoanApplicationInfoRequestTemplateStatus',
    enumType: LoanApplicationInfoRequestTemplateStatus,
    mapping: LoanApplicationInfoRequestTemplateStatusLabel,
  },
  {
    id: 'LoanInsuranceCalculationStatus',
    enumType: LoanInsuranceCalculationStatus,
    mapping: LoanInsuranceCalculationStatusLabel,
  },
  {
    id: 'LoanInsuranceCalculatorStatus',
    enumType: LoanInsuranceCalculatorStatus,
    mapping: LoanInsuranceCalculatorStatusLabel,
  },
  {
    id: 'LoanInsuranceCalculatorConfigurationStatus',
    enumType: LoanInsuranceCalculatorConfigurationStatus,
    mapping: LoanInsuranceCalculatorConfigurationStatusLabel,
  },
  {
    id: 'LoanInsuranceCalculatorLoadingLoadingType',
    enumType: LoanInsuranceCalculatorLoadingLoadingType,
    mapping: LoanInsuranceCalculatorLoadingLoadingTypeLabel,
  },
  {
    id: 'LoanInsuranceCalculatorVersionStatus',
    enumType: LoanInsuranceCalculatorVersionStatus,
    mapping: LoanInsuranceCalculatorVersionStatusLabel,
  },
  { id: 'LoyaltyPackageStatus', enumType: LoyaltyPackageStatus, mapping: LoyaltyPackageStatusLabel },
  {
    id: 'MachineLearningTestRigStatus',
    enumType: MachineLearningTestRigStatus,
    mapping: MachineLearningTestRigStatusLabel,
  },
  {
    id: 'MachineLearningTestRigFunction',
    enumType: MachineLearningTestRigFunction,
    mapping: MachineLearningTestRigFunctionLabel,
  },
  {
    id: 'MachineLearningTestRigResponseStatus',
    enumType: MachineLearningTestRigResponseStatus,
    mapping: MachineLearningTestRigResponseStatusLabel,
  },
  { id: 'NewsFeedPostStatus', enumType: NewsFeedPostStatus, mapping: NewsFeedPostStatusLabel },
  { id: 'NotificationType', enumType: NotificationType, mapping: NotificationTypeLabel },
  { id: 'NotificationStatus', enumType: NotificationStatus, mapping: NotificationStatusLabel },
  { id: 'NotificationCategory', enumType: NotificationCategory, mapping: NotificationCategoryLabel },
  {
    id: 'NotificationAttachmentStatus',
    enumType: NotificationAttachmentStatus,
    mapping: NotificationAttachmentStatusLabel,
  },
  { id: 'OmniBackChannelItemStatus', enumType: OmniBackChannelItemStatus, mapping: OmniBackChannelItemStatusLabel },
  {
    id: 'OmniBackChannelItemMessageType',
    enumType: OmniBackChannelItemMessageType,
    mapping: OmniBackChannelItemMessageTypeLabel,
  },
  {
    id: 'OmniBackChannelMessageStatus',
    enumType: OmniBackChannelMessageStatus,
    mapping: OmniBackChannelMessageStatusLabel,
  },
  { id: 'PasswordResetStatus', enumType: PasswordResetStatus, mapping: PasswordResetStatusLabel },
  { id: 'PermissionType', enumType: PermissionType, mapping: PermissionTypeLabel },
  { id: 'PermissionScope', enumType: PermissionScope, mapping: PermissionScopeLabel },
  { id: 'PersonType', enumType: PersonType, mapping: PersonTypeLabel },
  { id: 'PersonStatus', enumType: PersonStatus, mapping: PersonStatusLabel },
  { id: 'PersonTitle', enumType: PersonTitle, mapping: PersonTitleLabel },
  { id: 'PersonBrandImageBrandType', enumType: PersonBrandImageBrandType, mapping: PersonBrandImageBrandTypeLabel },
  { id: 'PersonOptionalGender', enumType: PersonOptionalGender, mapping: PersonOptionalGenderLabel },
  {
    id: 'PersonOptionalMaritalStatus',
    enumType: PersonOptionalMaritalStatus,
    mapping: PersonOptionalMaritalStatusLabel,
  },
  { id: 'PersonSecurityCodeType', enumType: PersonSecurityCodeType, mapping: PersonSecurityCodeTypeLabel },
  {
    id: 'PersonSecurityCodeRequestSource',
    enumType: PersonSecurityCodeRequestSource,
    mapping: PersonSecurityCodeRequestSourceLabel,
  },
  { id: 'PersonWithOptionalGender', enumType: PersonWithOptionalGender, mapping: PersonWithOptionalGenderLabel },
  {
    id: 'PersonWithOptionalMaritalStatus',
    enumType: PersonWithOptionalMaritalStatus,
    mapping: PersonWithOptionalMaritalStatusLabel,
  },
  { id: 'PolicyDecisionGroupStatus', enumType: PolicyDecisionGroupStatus, mapping: PolicyDecisionGroupStatusLabel },
  {
    id: 'PolicyDecisionRulesetStatus',
    enumType: PolicyDecisionRulesetStatus,
    mapping: PolicyDecisionRulesetStatusLabel,
  },
  {
    id: 'PolicyDecisionRulesetVersionStatus',
    enumType: PolicyDecisionRulesetVersionStatus,
    mapping: PolicyDecisionRulesetVersionStatusLabel,
  },
  { id: 'ProductStatus', enumType: ProductStatus, mapping: ProductStatusLabel },
  { id: 'ProductLoanType', enumType: ProductLoanType, mapping: ProductLoanTypeLabel },
  { id: 'ProductDataMaintainedBy', enumType: ProductDataMaintainedBy, mapping: ProductDataMaintainedByLabel },
  { id: 'ProductDetailStatus', enumType: ProductDetailStatus, mapping: ProductDetailStatusLabel },
  { id: 'ProductDetailLoanType', enumType: ProductDetailLoanType, mapping: ProductDetailLoanTypeLabel },
  { id: 'ProductDocumentStatus', enumType: ProductDocumentStatus, mapping: ProductDocumentStatusLabel },
  { id: 'ProductGroupStatus', enumType: ProductGroupStatus, mapping: ProductGroupStatusLabel },
  { id: 'ProductNoteStatus', enumType: ProductNoteStatus, mapping: ProductNoteStatusLabel },
  { id: 'ProductRuleStatus', enumType: ProductRuleStatus, mapping: ProductRuleStatusLabel },
  { id: 'ProductSpecialOfferStatus', enumType: ProductSpecialOfferStatus, mapping: ProductSpecialOfferStatusLabel },
  {
    id: 'ProductSpecificationClauseStatus',
    enumType: ProductSpecificationClauseStatus,
    mapping: ProductSpecificationClauseStatusLabel,
  },
  {
    id: 'ProductSpecificationSectionStatus',
    enumType: ProductSpecificationSectionStatus,
    mapping: ProductSpecificationSectionStatusLabel,
  },
  { id: 'ProductStandardFeeStatus', enumType: ProductStandardFeeStatus, mapping: ProductStandardFeeStatusLabel },
  {
    id: 'ProductStandardFeeSetStatus',
    enumType: ProductStandardFeeSetStatus,
    mapping: ProductStandardFeeSetStatusLabel,
  },
  { id: 'ProductSubgroupStatus', enumType: ProductSubgroupStatus, mapping: ProductSubgroupStatusLabel },
  {
    id: 'ProductVariationBenchmarkType',
    enumType: ProductVariationBenchmarkType,
    mapping: ProductVariationBenchmarkTypeLabel,
  },
  { id: 'ProductVersionStatus', enumType: ProductVersionStatus, mapping: ProductVersionStatusLabel },
  {
    id: 'ProductVersionDocumentStatus',
    enumType: ProductVersionDocumentStatus,
    mapping: ProductVersionDocumentStatusLabel,
  },
  {
    id: 'RequestDocumentCategoryStatus',
    enumType: RequestDocumentCategoryStatus,
    mapping: RequestDocumentCategoryStatusLabel,
  },
  {
    id: 'RequestDocumentCategoryRelatedTo',
    enumType: RequestDocumentCategoryRelatedTo,
    mapping: RequestDocumentCategoryRelatedToLabel,
  },
  { id: 'RuleCategory', enumType: RuleCategory, mapping: RuleCategoryLabel },
  { id: 'RuleStatus', enumType: RuleStatus, mapping: RuleStatusLabel },
  { id: 'RuleCategoryStatus', enumType: RuleCategoryStatus, mapping: RuleCategoryStatusLabel },
  { id: 'RuleFieldOptionStatus', enumType: RuleFieldOptionStatus, mapping: RuleFieldOptionStatusLabel },
  { id: 'RulesEngineRuleDataSource', enumType: RulesEngineRuleDataSource, mapping: RulesEngineRuleDataSourceLabel },
  {
    id: 'RulesEngineRuleTriggerEvent',
    enumType: RulesEngineRuleTriggerEvent,
    mapping: RulesEngineRuleTriggerEventLabel,
  },
  { id: 'RulesEngineRuleRuleSection', enumType: RulesEngineRuleRuleSection, mapping: RulesEngineRuleRuleSectionLabel },
  { id: 'RulesEngineRuleStatus', enumType: RulesEngineRuleStatus, mapping: RulesEngineRuleStatusLabel },
  {
    id: 'RulesEngineRuleSectionViewDataSource',
    enumType: RulesEngineRuleSectionViewDataSource,
    mapping: RulesEngineRuleSectionViewDataSourceLabel,
  },
  {
    id: 'ServiceabilityCalculatorStatus',
    enumType: ServiceabilityCalculatorStatus,
    mapping: ServiceabilityCalculatorStatusLabel,
  },
  {
    id: 'ServiceabilityCalculatorCalculationType',
    enumType: ServiceabilityCalculatorCalculationType,
    mapping: ServiceabilityCalculatorCalculationTypeLabel,
  },
  {
    id: 'ServiceabilityCalculatorHouseholdIncomeExpenseMethod',
    enumType: ServiceabilityCalculatorHouseholdIncomeExpenseMethod,
    mapping: ServiceabilityCalculatorHouseholdIncomeExpenseMethodLabel,
  },
  {
    id: 'ServiceabilityCalculatorHouseholdIncomeCalculationMethod',
    enumType: ServiceabilityCalculatorHouseholdIncomeCalculationMethod,
    mapping: ServiceabilityCalculatorHouseholdIncomeCalculationMethodLabel,
  },
  {
    id: 'ServiceabilityCalculatorDocumentType',
    enumType: ServiceabilityCalculatorDocumentType,
    mapping: ServiceabilityCalculatorDocumentTypeLabel,
  },
  {
    id: 'ServiceabilityCalculatorTaxCalculationMethod',
    enumType: ServiceabilityCalculatorTaxCalculationMethod,
    mapping: ServiceabilityCalculatorTaxCalculationMethodLabel,
  },
  {
    id: 'ServiceabilityCalculatorCalculationRule',
    enumType: ServiceabilityCalculatorCalculationRule,
    mapping: ServiceabilityCalculatorCalculationRuleLabel,
  },
  {
    id: 'ServiceabilityCalculatorNetIncomeMethod',
    enumType: ServiceabilityCalculatorNetIncomeMethod,
    mapping: ServiceabilityCalculatorNetIncomeMethodLabel,
  },
  {
    id: 'ServiceabilityCalculatorGuarantorIncomeMethod',
    enumType: ServiceabilityCalculatorGuarantorIncomeMethod,
    mapping: ServiceabilityCalculatorGuarantorIncomeMethodLabel,
  },
  {
    id: 'ServiceabilityCalculatorInterestRateLoanAmountMethod',
    enumType: ServiceabilityCalculatorInterestRateLoanAmountMethod,
    mapping: ServiceabilityCalculatorInterestRateLoanAmountMethodLabel,
  },
  {
    id: 'ServiceabilityCalculatorLiabilityLimitMethodForRepayment',
    enumType: ServiceabilityCalculatorLiabilityLimitMethodForRepayment,
    mapping: ServiceabilityCalculatorLiabilityLimitMethodForRepaymentLabel,
  },
  {
    id: 'ServiceabilityCalculatorLVRMethod',
    enumType: ServiceabilityCalculatorLVRMethod,
    mapping: ServiceabilityCalculatorLVRMethodLabel,
  },
  {
    id: 'ServiceabilityCalculatorCalculateRentBasedOn',
    enumType: ServiceabilityCalculatorCalculateRentBasedOn,
    mapping: ServiceabilityCalculatorCalculateRentBasedOnLabel,
  },
  {
    id: 'ServiceabilityCalculatorYearlyIncomeCustomRuleCalculation',
    enumType: ServiceabilityCalculatorYearlyIncomeCustomRuleCalculation,
    mapping: ServiceabilityCalculatorYearlyIncomeCustomRuleCalculationLabel,
  },
  {
    id: 'ServiceabilityCalculatorInterestRateUsageOption',
    enumType: ServiceabilityCalculatorInterestRateUsageOption,
    mapping: ServiceabilityCalculatorInterestRateUsageOptionLabel,
  },
  {
    id: 'ServiceabilityCalculatorSelfEmployedIncomeUsageOption',
    enumType: ServiceabilityCalculatorSelfEmployedIncomeUsageOption,
    mapping: ServiceabilityCalculatorSelfEmployedIncomeUsageOptionLabel,
  },
  {
    id: 'ServiceabilityCalculatorVersionStatus',
    enumType: ServiceabilityCalculatorVersionStatus,
    mapping: ServiceabilityCalculatorVersionStatusLabel,
  },
  {
    id: 'ServiceabilityDataObjectStatus',
    enumType: ServiceabilityDataObjectStatus,
    mapping: ServiceabilityDataObjectStatusLabel,
  },
  {
    id: 'ServiceabilityExpenseCalculationMethod',
    enumType: ServiceabilityExpenseCalculationMethod,
    mapping: ServiceabilityExpenseCalculationMethodLabel,
  },
  {
    id: 'ServiceabilityExpenseHouseholdIncomeMethod',
    enumType: ServiceabilityExpenseHouseholdIncomeMethod,
    mapping: ServiceabilityExpenseHouseholdIncomeMethodLabel,
  },
  {
    id: 'ServiceabilityExpenseCalculateRentBasedOn',
    enumType: ServiceabilityExpenseCalculateRentBasedOn,
    mapping: ServiceabilityExpenseCalculateRentBasedOnLabel,
  },
  {
    id: 'ServiceabilityExpenseDetailStatus',
    enumType: ServiceabilityExpenseDetailStatus,
    mapping: ServiceabilityExpenseDetailStatusLabel,
  },
  {
    id: 'ServiceabilityExpenseDetailExpenseType',
    enumType: ServiceabilityExpenseDetailExpenseType,
    mapping: ServiceabilityExpenseDetailExpenseTypeLabel,
  },
  {
    id: 'ServiceabilityFinancialCommitmentNegativeGearingDeduction',
    enumType: ServiceabilityFinancialCommitmentNegativeGearingDeduction,
    mapping: ServiceabilityFinancialCommitmentNegativeGearingDeductionLabel,
  },
  {
    id: 'ServiceabilityFinancialCommitmentDetailStatus',
    enumType: ServiceabilityFinancialCommitmentDetailStatus,
    mapping: ServiceabilityFinancialCommitmentDetailStatusLabel,
  },
  {
    id: 'ServiceabilityFinancialCommitmentDetailLiabilityType',
    enumType: ServiceabilityFinancialCommitmentDetailLiabilityType,
    mapping: ServiceabilityFinancialCommitmentDetailLiabilityTypeLabel,
  },
  {
    id: 'ServiceabilityFinancialCommitmentDetailRule',
    enumType: ServiceabilityFinancialCommitmentDetailRule,
    mapping: ServiceabilityFinancialCommitmentDetailRuleLabel,
  },
  {
    id: 'ServiceabilityFinancialCommitmentDetailTermRule',
    enumType: ServiceabilityFinancialCommitmentDetailTermRule,
    mapping: ServiceabilityFinancialCommitmentDetailTermRuleLabel,
  },
  {
    id: 'ServiceabilityFinancialCommitmentDetailRateRule',
    enumType: ServiceabilityFinancialCommitmentDetailRateRule,
    mapping: ServiceabilityFinancialCommitmentDetailRateRuleLabel,
  },
  {
    id: 'ServiceabilityFinancialCommitmentDetailApplicableTo',
    enumType: ServiceabilityFinancialCommitmentDetailApplicableTo,
    mapping: ServiceabilityFinancialCommitmentDetailApplicableToLabel,
  },
  {
    id: 'ServiceabilityFinancialCommitmentDetailOtherLiabilityRule',
    enumType: ServiceabilityFinancialCommitmentDetailOtherLiabilityRule,
    mapping: ServiceabilityFinancialCommitmentDetailOtherLiabilityRuleLabel,
  },
  {
    id: 'ServiceabilityFunctionStatus',
    enumType: ServiceabilityFunctionStatus,
    mapping: ServiceabilityFunctionStatusLabel,
  },
  { id: 'ServiceabilityGroupStatus', enumType: ServiceabilityGroupStatus, mapping: ServiceabilityGroupStatusLabel },
  {
    id: 'ServiceabilityIncomeDetailStatus',
    enumType: ServiceabilityIncomeDetailStatus,
    mapping: ServiceabilityIncomeDetailStatusLabel,
  },
  {
    id: 'ServiceabilityIndicatorStatus',
    enumType: ServiceabilityIndicatorStatus,
    mapping: ServiceabilityIndicatorStatusLabel,
  },
  {
    id: 'ServiceabilityIndicatorSeverityLevel',
    enumType: ServiceabilityIndicatorSeverityLevel,
    mapping: ServiceabilityIndicatorSeverityLevelLabel,
  },
  {
    id: 'ServiceabilityIndicatorCalculationMethod',
    enumType: ServiceabilityIndicatorCalculationMethod,
    mapping: ServiceabilityIndicatorCalculationMethodLabel,
  },
  {
    id: 'ServiceabilityIndicatorLoanTermOption',
    enumType: ServiceabilityIndicatorLoanTermOption,
    mapping: ServiceabilityIndicatorLoanTermOptionLabel,
  },
  { id: 'ServiceabilityMetricStatus', enumType: ServiceabilityMetricStatus, mapping: ServiceabilityMetricStatusLabel },
  { id: 'ServiceabilityMetricType', enumType: ServiceabilityMetricType, mapping: ServiceabilityMetricTypeLabel },
  {
    id: 'ServiceabilityMetricRangeDirection',
    enumType: ServiceabilityMetricRangeDirection,
    mapping: ServiceabilityMetricRangeDirectionLabel,
  },
  {
    id: 'ServiceabilityNewFinancialCommitmentNegativeGearingDeduction',
    enumType: ServiceabilityNewFinancialCommitmentNegativeGearingDeduction,
    mapping: ServiceabilityNewFinancialCommitmentNegativeGearingDeductionLabel,
  },
  {
    id: 'ServiceabilityNewFinancialCommitmentDetailStatus',
    enumType: ServiceabilityNewFinancialCommitmentDetailStatus,
    mapping: ServiceabilityNewFinancialCommitmentDetailStatusLabel,
  },
  {
    id: 'ServiceabilityNewFinancialCommitmentDetailLoanType',
    enumType: ServiceabilityNewFinancialCommitmentDetailLoanType,
    mapping: ServiceabilityNewFinancialCommitmentDetailLoanTypeLabel,
  },
  { id: 'ServiceabilityResultStatus', enumType: ServiceabilityResultStatus, mapping: ServiceabilityResultStatusLabel },
  { id: 'ServiceabilitySetStatus', enumType: ServiceabilitySetStatus, mapping: ServiceabilitySetStatusLabel },
  { id: 'ServiceabilitySetupStatus', enumType: ServiceabilitySetupStatus, mapping: ServiceabilitySetupStatusLabel },
  {
    id: 'ServiceabilitySetupExpenseDetailType',
    enumType: ServiceabilitySetupExpenseDetailType,
    mapping: ServiceabilitySetupExpenseDetailTypeLabel,
  },
  {
    id: 'SettlementReferenceDataListType',
    enumType: SettlementReferenceDataListType,
    mapping: SettlementReferenceDataListTypeLabel,
  },
  {
    id: 'SettlementReferenceDataStatus',
    enumType: SettlementReferenceDataStatus,
    mapping: SettlementReferenceDataStatusLabel,
  },
  { id: 'SolutionGroupStatus', enumType: SolutionGroupStatus, mapping: SolutionGroupStatusLabel },
  { id: 'SolutionPriceTemplateType', enumType: SolutionPriceTemplateType, mapping: SolutionPriceTemplateTypeLabel },
  {
    id: 'SolutionPriceTemplateStatus',
    enumType: SolutionPriceTemplateStatus,
    mapping: SolutionPriceTemplateStatusLabel,
  },
  {
    id: 'SsiBackChannelMessageMessageType',
    enumType: SsiBackChannelMessageMessageType,
    mapping: SsiBackChannelMessageMessageTypeLabel,
  },
  {
    id: 'SsiBankingDataDecisionMetricCategory',
    enumType: SsiBankingDataDecisionMetricCategory,
    mapping: SsiBankingDataDecisionMetricCategoryLabel,
  },
  { id: 'SsiBankingDataFileType', enumType: SsiBankingDataFileType, mapping: SsiBankingDataFileTypeLabel },
  { id: 'StandardClauseStatus', enumType: StandardClauseStatus, mapping: StandardClauseStatusLabel },
  { id: 'TaxType', enumType: TaxType, mapping: TaxTypeLabel },
  { id: 'TaxOffsetType', enumType: TaxOffsetType, mapping: TaxOffsetTypeLabel },
  { id: 'TeamStatus', enumType: TeamStatus, mapping: TeamStatusLabel },
  { id: 'TeamMemberStatus', enumType: TeamMemberStatus, mapping: TeamMemberStatusLabel },
  { id: 'TemplateDocumentStatus', enumType: TemplateDocumentStatus, mapping: TemplateDocumentStatusLabel },
  {
    id: 'TemplateDocumentStandardUse',
    enumType: TemplateDocumentStandardUse,
    mapping: TemplateDocumentStandardUseLabel,
  },
  {
    id: 'TemplateDocumentGroupStatus',
    enumType: TemplateDocumentGroupStatus,
    mapping: TemplateDocumentGroupStatusLabel,
  },
  {
    id: 'TemplateDocumentSubgroupStatus',
    enumType: TemplateDocumentSubgroupStatus,
    mapping: TemplateDocumentSubgroupStatusLabel,
  },
  {
    id: 'TemplateDocumentVersionStatus',
    enumType: TemplateDocumentVersionStatus,
    mapping: TemplateDocumentVersionStatusLabel,
  },
  { id: 'UserStatus', enumType: UserStatus, mapping: UserStatusLabel },
  { id: 'UserInfoRequestStatus', enumType: UserInfoRequestStatus, mapping: UserInfoRequestStatusLabel },
  { id: 'WebHookStatus', enumType: WebHookStatus, mapping: WebHookStatusLabel },
];

export const EnumLabel: EnumMapper[] = EnumLabelAuto.concat(EnumLabelClient);

export enum JourneyType {
  ApprovalInPrinciple = 1,
  FactFind = 2,
  StatementOfPosition = 3,
  FullApplication = 4,
  Refi = 11,
  Serviceability = 31,
  EasyRefi = 32,
  Purchase = 51,
}

export enum DigitalWidgetStatusType {
  Assigned = 'assigned',
  Pending = 'pending',
  Requested = 'requested',
  Completed = 'completed',
  Failed = 'failed',
}

export enum ApplicationPreCheck {
  Eligible = 1,
  Ineligible = 2,
  Unknown = 3,
}

export enum SecureJourneyType {
  ApprovalInPrinciple = 1001,
  FactFind = 1009,
  StatementOfPosition = 1010,
  FullApplication = 1002,
  Refi = 1003,
  Serviceability = 1004,
  EasyRefi = 1008,
  Purchase = 1007,
}

export enum TargetType {
  PersonApplicant = 1,
  RelatedPerson = 2,
  CompanyApplicant = 3,
  RelatedCompany = 4,
  TrustApplicant = 5,
}

export enum ForeignTaxAssociationStatus {
  Provided = 1000,
  None = 1001,
  NotProvided = 1002,
}

export enum TinNotProvidedReason {
  CountryOfTaxDoesNotIssueTIN = 1000,
  CountryOfTaxDoesNotRequireTINToBeDisclosed = 1001,
  UnableToObtainTIN = 1002,
}

export enum AssetTransaction {
  Owns = 1000,
  OwnsExistingMortgage = 1001,
  Purchasing = 1002,
  Sold = 1003,
}
