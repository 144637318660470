<div class="sticky-top" *ngIf="currentUser">
  <simp-header #simpHeader [config]="headerConfig"></simp-header>
</div>
<div class="omni-header-spacing"></div>

<ng-template #labelTemplate let-item="item">
  <div class="row">
    <div class="col-9">
      <div class="row simp-row">
        <div class="col simp-padding-horizontal-zero">
          <span class="select-label-container__label">{{ item.title }}</span>
        </div>
      </div>
      <div class="row simp-row">
        <div class="col simp-padding-horizontal-zero">
          <span *ngIf="item.amount" class="simp-h6 additional-info-container__item">{{ item.amount | currency }}</span>
          <span *ngIf="item.lvr" class="simp-h6 additional-info-container__item"
            >LVR: {{ item.lvr | number: '2.0-2' }}%</span
          >
          <span *ngIf="item.applicationDate" class="simp-h6 additional-info-container__item"
            >App date: {{ item.applicationDate | date: 'dd/MM/yy' }}</span
          >
        </div>
      </div>
    </div>
    <div class="col-3 d-flex align-items-center simp-padding-horizontal-zero">
      <span
        class="status-badge simp-badge simp-badge-pill ms-auto"
        [ngClass]="getStatusBadge(item.statusEnum)"
        *ngIf="item.id > 0"
      >
        {{ item.statusEnum | enumDesc: LoanApplicationStatus }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template #optionTemplate let-item="item">
  <div class="select-label-container">
    <span class="select-label-container__label">{{ item.title }}</span>
  </div>
</ng-template>
