import { Injectable } from '@angular/core';
import { EnumObject } from '@shared/helper/enum-helper';
import { ApplicationModeOption } from '@shared/model/application-mode.model';
import { MessageStatus } from '@shared/model/client-enum.model';
import { LoanApplicationStatus } from '@shared/model/enum.model';

@Injectable({ providedIn: 'root' })
export class ApplicationHelperService {
  constructor() {}

  public get DefaultAppModeId(): ApplicationModeOption {
    return ApplicationModeOption.Default;
  }

  public getStatusBadge(status: LoanApplicationStatus): string {
    switch (status) {
      case LoanApplicationStatus.Open:
        return 'simp-badge-blue';
      case LoanApplicationStatus.Waiting:
        return 'simp-badge-orange';
      case LoanApplicationStatus.Assessment:
        return 'simp-badge-blue';
      case LoanApplicationStatus.Solicitors:
        return 'simp-badge-blue';
      case LoanApplicationStatus.Decision:
        return 'simp-badge-orange';
      case LoanApplicationStatus.Finalised:
        return 'simp-badge-green-alt';
      default:
        return 'simp-badge-blue';
    }
  }

  public getOmniMessageStatusBadge(status: MessageStatus): string {
    switch (status) {
      case MessageStatus.Open:
        return 'simp-badge-blue';
      case MessageStatus.Inprogress:
        return 'omni-bg-purple';
      case MessageStatus.Complete:
        return 'simp-badge-green-alt';
      case MessageStatus.Fail:
        return 'omni-bg-red';
      case MessageStatus.Waiting:
        return 'omni-bg-orange';
      default:
        return 'simp-badge-grey';
    }
  }

  public getAssessmentItemStatusBadge(status: MessageStatus): string {
    switch (status) {
      case MessageStatus.Complete:
        return 'assessment-item-list__item-status--pass';
      case MessageStatus.Fail:
        return 'assessment-item-list__item-status--fail';
      default:
        return '';
    }
  }

  public getApplicationModes(): EnumObject[] {
    return [
      {
        id: ApplicationModeOption.Default,
        label: `View my apps`,
      },
      {
        id: ApplicationModeOption.ViewByChannel,
        label: 'View by channel',
      },
    ];
  }
}
