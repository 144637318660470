import { Injectable } from '@angular/core';
import { Constant } from '@shared/helper/constant';
import { ApplicationModeOption } from '@shared/model/application-mode.model';
import { ApplicationChannel } from '@solution/model/application-channel.model';
import { LoanApplication, LoanApplicationDetail } from '@solution/model/loan-application.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApplicationNotificationService {
  public applicationSelected$: Observable<LoanApplication>;
  public applicationChanged$: Observable<LoanApplication>;
  public applicationModeChanged$: Observable<{ mode: ApplicationModeOption; channelId?: number }>;
  public applicationStatusUpdated$: Observable<LoanApplicationDetail>;
  public applicationChannelsLoaded$: Observable<ApplicationChannel[]>;
  public channelChanged$: Observable<{ channelId?: number; initialApp?: LoanApplication }>;

  private emptyApplication: LoanApplication = {
    id: Constant.newId,
  } as LoanApplication;
  private applicationSubject$: BehaviorSubject<LoanApplication> = new BehaviorSubject<LoanApplication>(
    this.emptyApplication
  );

  private applicationStatusSubject$: BehaviorSubject<LoanApplicationDetail> =
    new BehaviorSubject<LoanApplicationDetail>(this.emptyApplication as LoanApplicationDetail);

  private channelsLoadedSubject$: BehaviorSubject<ApplicationChannel[]> = new BehaviorSubject<ApplicationChannel[]>([]);
  private channelChangedSubject$: BehaviorSubject<{ channelId?: number; initialApp?: LoanApplication }> =
    new BehaviorSubject<{
      channelId?: number;
      initialApp?: LoanApplication;
    }>({
      channelId: undefined,
      initialApp: undefined,
    });
  private applicationSelectedSubject$: BehaviorSubject<LoanApplication> = new BehaviorSubject<LoanApplication>(
    this.emptyApplication
  );

  private applicationModeSubject$: BehaviorSubject<{ mode: ApplicationModeOption; channelId?: number }> =
    new BehaviorSubject<{ mode: ApplicationModeOption; channelId?: number }>({
      mode: ApplicationModeOption.Default,
      channelId: undefined,
    });

  constructor() {
    this.applicationChanged$ = this.applicationSubject$.asObservable();
    this.applicationStatusUpdated$ = this.applicationStatusSubject$.asObservable();
    this.applicationChannelsLoaded$ = this.channelsLoadedSubject$.asObservable();
    this.channelChanged$ = this.channelChangedSubject$.asObservable();
    this.applicationSelected$ = this.applicationSelectedSubject$.asObservable();
    this.applicationModeChanged$ = this.applicationModeSubject$.asObservable();
  }

  public notifyChange(application: LoanApplication): void {
    this.applicationSubject$.next(application);
  }

  public notifyStatusChange(application: LoanApplicationDetail): void {
    this.applicationStatusSubject$.next(application);
  }

  public notifyChannelsLoaded(data: ApplicationChannel[]): void {
    this.channelsLoadedSubject$.next(data);
  }

  public notifyChannelChanged(channelId?: number, initialApp?: LoanApplication): void {
    this.channelChangedSubject$.next({ channelId, initialApp });
  }

  public notifyApplicationSelected(application: LoanApplication): void {
    this.applicationSelectedSubject$.next(application);
  }

  public notifyApplicationModeChanged(mode: ApplicationModeOption, channelId: number): void {
    this.applicationModeSubject$.next({ mode, channelId });
  }
}
