import { NgModule } from '@angular/core';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigationComponent } from './navigation.component';
import { NavigationService } from './navigation.service';
import { SimpologyComponentModule } from '@simpology/client-components';
import { SharedModule } from '@shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [NavigationComponent],
  imports: [
    BrowserAnimationsModule,
    NoopAnimationsModule,
    CommonModule,
    RouterModule,
    NgbModule,
    SimpologyComponentModule,
    SharedModule,
    NgSelectModule,
  ],
  exports: [NavigationComponent],
  providers: [NavigationService],
})
export class NavigationModule {}
