export class Constant {
  public static readonly addActionQueryParam = 'add';
  public static readonly editActionQueryParam = 'edit';
  public static readonly returnUrlKey = 'simp:returnUrl';
  public static readonly currentUser = 'simp:currentUser';

  public static readonly defaultMessageTypeFilter = 'simp:setting:omni:defaultMessageTypeFilter';
  public static readonly defaultMessageStatusFilter = 'simp:setting:omni:defaultMessageStatusFilter';

  public static readonly simpologyManagerSolutionId = 1001;
  public static readonly newId = -1;
  public static readonly selectAllOptionId = 0;
  public static readonly undefinedChannelId = 0;

  public static readonly conflictErrorCode = 409;
  public static readonly notFoundErrorCode = 404;

  public static readonly unicodeSpace = '\u00A0';

  public static readonly PDF = 'pdf';
  public static readonly JPG = 'jpg';
  public static readonly JPEG = 'jpeg';
  public static readonly PNG = 'png';
  public static readonly BMP = 'bmp';
  public static readonly DOC = 'doc';
  public static readonly DOCX = 'docx';
  public static readonly XLS = 'xls';
  public static readonly XLSX = 'xlsx';
  public static readonly XML = 'xml';

  public static readonly commonFileExtensions = [
    Constant.PDF,
    Constant.JPG,
    Constant.JPEG,
    Constant.PNG,
    Constant.BMP,
    Constant.DOC,
    Constant.DOCX,
    Constant.XLS,
    Constant.XLSX,
    Constant.XML,
  ];
  public static readonly pdfDocumentImagePath = './assets/images/icons/pdf.png';
  public static readonly wordImagePath = './assets/images/icons/word.png';
  public static readonly excelImagePath = './assets/images/icons/excel.png';
  public static readonly noImagePath = './assets/images/logo/application_noimage.png';
}
