import { Pipe, PipeTransform } from '@angular/core';
import { EnumHelper } from '@shared/helper/enum-helper';

@Pipe({ name: 'enumDesc' })
export class EnumDesc implements PipeTransform {
  public transform(value: number, enumType: any): any {
    if (!value) {
      return value;
    }

    return EnumHelper.getEnumKeyByValue(enumType, value);
  }
}
