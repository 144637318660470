import { Injectable } from '@angular/core';
import { Constant } from '@helper/constant';
import { ChannelBrandImageBrandType } from '@shared/model/enum.model';
import { ChannelSettingService } from '@shared/store/channel-setting/channel-setting.service';
import { AuthService } from '@simpology/authentication';
import { OmniApplicationApiService } from '@solution/api/omni-application-api.service';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { CurrentUser } from '../model/current-user.model';
import { BrandImageApiService } from './api/brand-image.api.service';

@Injectable({ providedIn: 'root' })
export class PostLoginService {
  public loginCompleted$: Observable<CurrentUser | null>;
  private loginCompletedSource = new BehaviorSubject<CurrentUser | null>(null);

  constructor(
    private authService: AuthService,
    private omniApplicationApiService: OmniApplicationApiService,
    private brandImageApiService: BrandImageApiService,
    private channelSettingService: ChannelSettingService
  ) {
    this.loginCompleted$ = this.loginCompletedSource.asObservable();
    this.authService.authNavStatus$.subscribe((value: boolean) => {
      if (value) {
        forkJoin([
          this.omniApplicationApiService.getCurrentUser(),
          this.brandImageApiService.getAll(),
          this.channelSettingService.fetchChannelSetting(),
        ]).subscribe(([currentUser, brandImage, settings]) => {
          const profileLogoBrand = brandImage.find((b) => b.brandTypeEnum === ChannelBrandImageBrandType.Application);
          currentUser.channelLogoUrl = profileLogoBrand?.imagePath ?? '';
          localStorage.setItem(Constant.currentUser, JSON.stringify(currentUser));
          this.loginCompletedSource.next(currentUser);
        });
      }
    });
  }

  public getCurrentUser(): CurrentUser {
    const value = localStorage.getItem(Constant.currentUser);
    if (value && value.trim().length > 0) {
      return JSON.parse(value);
    }
    return {
      displayName: this.authService.name,
      photoUrl: '',
      peopleId: 0,
      entityId: 0,
      channelName: '',
      channelLogoUrl: '',
    };
  }
}
