import { EnumDescription } from '@shared/model/enum-description';

export interface EnumObject {
  id: number;
  label: string;
}

export class EnumHelper {
  public static getEnumArray(enumeration: { [index: string]: any }): EnumObject[] {
    return Object.keys(enumeration)
      .filter((value) => isNaN(Number(value)))
      .map((key) => {
        const description = EnumDescription.getDescription(enumeration, enumeration[key]);
        return {
          id: enumeration[key],
          label: description ? description : key,
        };
      });
  }

  public static getFilteredEnumArray(enumeration: { [index: string]: any }, filterFunction: Function): EnumObject[] {
    const filtered = Object.keys(enumeration)
      .filter((value) => isNaN(Number(value)) && filterFunction(value))
      .reduce((ac, key) => {
        const enumObject: { [index: string]: any } = {};
        enumObject[key] = enumeration[key];
        ac = {
          ...ac,
          ...enumObject,
        };
        return ac;
      }, {});

    return this.getEnumArray(filtered);
  }

  public static getEnumKeyByValue(enumeration: { [index: string]: any }, enumValue: any): string {
    const keys = Object.keys(enumeration).filter((x) => enumeration[x] === enumValue);
    if (keys.length > 0) {
      const description = EnumDescription.getDescription(enumeration, enumValue);
      return description ? description : keys[0];
    }
    return '';
  }

  public static getEnumArrayFromFlaggedValue(enumValue: any): number[] {
    const values: number[] = [];
    while (enumValue) {
      const bit = enumValue & (~enumValue + 1);
      values.push(bit);
      enumValue ^= bit;
    }
    return values;
  }
}
