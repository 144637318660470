import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseApiService } from '@simpology/authentication';
import { ToastrService } from 'ngx-toastr';
import { ENV_CONFIG } from 'src/env-config';
import { BrandImage } from '../../model/brand-image.model';

@Injectable({ providedIn: 'root' })
export class BrandImageApiService extends BaseApiService<BrandImage> {
  constructor(http: HttpClient, toastr: ToastrService, router: Router) {
    super(http, toastr, router, ENV_CONFIG);
    this.setRoute('ChannelBrandImage');
  }
}
