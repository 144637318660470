import { Injectable } from '@angular/core';
import { FormMetaData, MetaArea, MetaLabels } from '@shared/model/metadata.model';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';
import formMetaData from '../../../assets/form/metadata/solicitorinstruction-metadata.json';
import { MetadataApiService } from './api/metadata-api.service';

@Injectable({ providedIn: 'root' })
export class MetadataService {
  metadataLoaded$: Observable<boolean>;

  private cachedMetaData: { [key: string]: FormMetaData[] } = {};
  private currentAreaMetaData: FormMetaData[] = [];
  private metadataLoadedSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private metadataApiService: MetadataApiService) {
    this.metadataLoaded$ = this.metadataLoadedSubject$.asObservable();
  }

  setCurrentArea(area: MetaArea): void {
    this.currentAreaMetaData = this.cachedMetaData[area];
  }

  loadInitialSetup(): void {
    this.fetchMetaData(MetaArea.SolicitorInstruction).subscribe(() => {
      this.metadataLoadedSubject$.next(true);
    });
  }

  getLabels(fieldPath: string): MetaLabels {
    const fieldKeyPrefix = `${fieldPath}#`;
    const result: MetaLabels = {};
    const fields = this.currentAreaMetaData.filter((x) => x.fieldKey.startsWith(fieldKeyPrefix));
    fields.forEach((f) => {
      const fieldShortKey = f.fieldKey.substring(fieldKeyPrefix.length);
      let title = f.title;
      if (f.hide && f.hide.toLowerCase() === 'true') {
        title = undefined;
      }
      result[fieldShortKey] = title;
    });

    return result;
  }

  getElementFromMetaData(path: string): FormMetaData | undefined {
    const getPath = path.replace('.', '#');
    const field = this.currentAreaMetaData.find((x) => x.fieldKey === getPath);
    return field;
  }

  private fetchMetaData(area: MetaArea): Observable<FormMetaData[]> {
    return this.metadataApiService.fetchMetaData(area).pipe(
      map((res: FormMetaData[]) => {
        if (res.length) {
          this.cachedMetaData[area] = res;
          return res;
        } else {
          this.cachedMetaData[area] = this.getFormSchema(area);
          return this.cachedMetaData[area];
        }
      }),
      catchError(() => {
        this.cachedMetaData[area] = this.getFormSchema(area);
        return of(this.cachedMetaData[area]);
      })
    );
  }

  private getFormSchema(area: MetaArea): FormMetaData[] {
    switch (area) {
      case MetaArea.SolicitorInstruction:
        return formMetaData as unknown as FormMetaData[];
      default:
        throw new Error('Invalid meta area');
    }
  }
}
