import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';

import { getEntity, upsertEntities, withEntities } from '@ngneat/elf-entities';
import { StoreName } from '../store.constant';
import { ApplicationStoreModel } from './typings/application';

@Injectable({ providedIn: 'root' })
export class ApplicationStore {
  store = createStore({ name: StoreName.application }, withEntities<ApplicationStoreModel>());

  upsertStore(newEntity: ApplicationStoreModel) {
    this.store.update(upsertEntities({ ...newEntity }));
  }

  getById(id: number): ApplicationStoreModel | undefined {
    return this.store.query(getEntity(id));
  }
}
