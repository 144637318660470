import { Pipe, PipeTransform } from '@angular/core';
import { formatDecimals } from '@shared/utils';

@Pipe({ name: 'numberFormat' })
export class NumberFormat implements PipeTransform {
  public transform(value: number | undefined, decimals: number, suffix?: string): string {
    if (!value) {
      return 'N/A';
    }

    return `${formatDecimals(value, decimals).toFixed(decimals)}${suffix ?? ''}`;
  }
}
