<div class="content-wrapper">
  <div class="row ml-5">
    <div class="col-lg-12 col-xl-9 ">
      <div class="mb-5 mt-5">
        Your session has timed out, please click the button below to remain
        logged in
      </div>

      <a
        [routerLink]="['/access/login']"
        [queryParams]="{ returnUrl: returnUrl }"
        class="btn btn-sm btn-primary"
        ><i class="fas fa-lock-open"></i> Login</a
      >
    </div>
  </div>
</div>
