import { Component, OnInit } from '@angular/core';
import { AuthService } from '@simpology/authentication';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  constructor(private authService: AuthService) {}

  public serverVersion: string;
  public libVersion: string;

  public ngOnInit(): void {}

  public authenticated(): boolean {
    return this.authService.isAuthenticated();
  }
}
